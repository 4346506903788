<mat-tab-group dynamicHeight>
  <mat-tab label="Booking">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">

      <div fxFlex="20%" class="ramme1R " fxLayout="row wrap">
        <div *ngFor="let capacity of dayCapacity">
          <a (click)="showCheckinTimeslot(capacity.startTime)">
            <div class="small-box mat-elevation-z4">{{capacity.startTime| date:'HH:mm'}} - {{capacity.end() | date:'HH:mm'}}</div>
          </a>

        </div>
      </div>
      <div fxFlex="80%" fxFlexFill fxLayout="row wrap" class="ramme2B left-box" fxLayoutGap="10px">
        <div *ngIf="expectedBookings?.length <= 0" >Ingen booking i denne halvtimen</div>
        <div *ngFor="let reservation of expectedBookings" fxLayout="column " class="mat-elevation-z4  boxramme3">
          <div fxFlex="80%" class=" tiles_infoBox" [ngClass]="reservation.cssClass">
            <div>
              <strong>mobil:</strong>{{reservation.mobile}} </div>
            <div>
              <strong>Pris:</strong> {{reservation.unitPrice}} </div>
            <div>
              <strong>Varighet:</strong>{{reservation.durationHours}} t.</div>
            <div>
              <strong>Ref:</strong> {{reservation.referenceNumber}}</div>
            <div class="badgeHeight">
              <span matBadge="{{reservation.count}}" matBadgeOverlap="false">
              </span>
            </div>

            <div>{{getActivityName(reservation)}}</div>
            <div>
              <a [href]="'/bursdag/'+ reservation.reservationId"><mat-icon type="button" *ngIf="!reservation.isRegular()">edit</mat-icon></a>
            </div>
          </div>
          <div fxFlex="20%" class=" btn-chekinn tiles_infoBox">
            <div>
              <button type="button" mat-raised-button color="warn"(click)="register(reservation)">Sjekk inn</button>
            </div>
            <p mat-line ><strong class="payment-info {{isPaid(reservation.reservationId)}}"></strong></p>
            <p mat-line ><strong class="waver-info {{isWaiverSigned(reservation.mobile)}}"></strong></p>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
