<div fxLayout="row" class="header">
    <div fxFlex class="left-controls">
    <h1>Bursdags oversikt</h1>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="end center" class="right-controls">
      <mat-form-field style="padding: 16px">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" type="text" autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <input matInput [matDatepicker]="dp" [formControl]="selectedDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="year"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        panelClass="month-picker">
        </mat-datepicker>
      </mat-form-field>
      <button mat-button (click)="export()">Export</button>
      <button mat-icon-button [matMenuTriggerFor]="weeklyExportMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #weeklyExportMenu="matMenu">
        <ng-template matMenuContent let-element="element">
          <button *ngFor="let item of weeks" mat-menu-item (click)="exportFor(item.start, item.end)">{{item.start.format('D MMM')}} - {{item.end.format('D MMM')}}</button>
        </ng-template>
      </mat-menu>
    </div>
</div>

<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="table-container">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let colDef of columnDefs" matColumnDef="{{colDef.field}}" >
      <th mat-header-cell *matHeaderCellDef>{{colDef.title}}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="colDef.field !== 'actions'">{{colDef.getValue(element)}}</div>
        <div *ngIf="colDef.field === 'actions'">
          <button [routerLink]="['/resource/'+resource.prittyUrl,'bursdag',element?._original?.reservationId]" routerLinkActive="router-link-active">Endre</button>
          <!-- <button mat-icon-button [matMenuTriggerFor]="bookingMenu" [matMenuTriggerData]="{element: element}">
            <mat-icon>more_vert</mat-icon>
          </button> -->
        </div>
      </td>
    </ng-container>

    <ng-container>
      <th mat-header-cell *matHeaderCellDef>Actions</th>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #bookingMenu="matMenu">
  <ng-template matMenuContent let-element="element">
    <!-- <button mat-menu-item (click)="onEditBookingClick(element)">Endre</button> -->
    <button [routerLink]="['/resource/'+resource.prittyUrl,'bursdag',element._original.reservationId]" routerLinkActive="router-link-active">Endre<mat-icon type="button">edit</mat-icon></button>
    <!-- <button mat-menu-item (click)="onChangeBookingTimeClick(element)">Endre tidspunkt</button>
    <button mat-menu-item (click)="onCancelBookingClick(element)">Kansellere</button> -->
  </ng-template>
</mat-menu>


