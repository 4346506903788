import * as moment from "moment";
import { Component, OnInit, Inject } from "@angular/core";
import { TimeScheduleService } from "../time-schedule.service";
import { Reservation, ReservationsAgregate } from "../timeslot.model";
import { Observable } from "rxjs/Rx";
import { FormGroup, FormBuilder } from "@angular/forms";
import "rxjs/add/observable/of";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { interval, Subscriber } from "rxjs";
import { BookableResource } from "../bookable-resource";
import { BookableResourceService } from "../bookable-resource.service";
import { AdalService } from "adal-angular4";
import { ActivityService, initActivityService } from "../../activities-react/activityService";
import { Activity, ActivityType } from "../../activities-react/model";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";

@Component({
  selector: "app-reservations",
  templateUrl: "./reservations.component.html",
  styleUrls: ["./reservations.component.scss"],
})
export class ReservationsComponent implements OnInit {
  reservations$: Observable<Array<ReservationsAgregate>>;
  reservationsPaymentInfo: Map<string, Observable<Boolean>> = new Map();
  // searchControl: FormControl;
  searchForm: FormGroup;
  message: string;
  deleteElement = true;
  bookableResources$: Observable<Array<BookableResource>>;
  bookableResources: Map<string, BookableResource> = new Map<
    string,
    BookableResource
  >();
  sitePrityUrl: string;
  private activityService: ActivityService;
  groupActivityIds: Set<string> = new Set();
  public resource$: Observable<BookableResource>;
  activities: Activity[] = [];

  constructor(
    private timeScheduleService: TimeScheduleService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private http: HttpClient,
    private bookingResourceService: BookableResourceService,
    private adalService: AdalService,
  ) {
    this.searchForm = this.fb.group({
      search: "",
      history: true,
    });
    // this.searchControl = this.searchForm.controls.searchControl;
    this.getReservations();
  }
  // private subscriber: Subscriber<any>;
  // private inRequest = false;
  private getReservations() {
    var today = moment().startOf("day");
    // let o = new Observable((obs) => (this.subscriber = obs)).debounceTime(2000);
    this.reservations$ = this.searchForm.valueChanges
      .debounceTime(1000)
      .filter((x) => {
        return x.search != "";
      })
      .switchMap((x) => {
        
        var search$ = this.timeScheduleService.searchReservations(x.search);

        if (!x.history)
          search$ = search$.map((x: ReservationsAgregate[]) =>
            x.filter((q) => q.startTime.isAfter(today))
          );
        search$ = search$
          .map((res: ReservationsAgregate[]) => {
            res.forEach((reservation: ReservationsAgregate) => {
              this.reservationsPaymentInfo.set(
                reservation.reservation?.id,
                this.checkPayment(reservation.reservation?.id)
              );
            });
            return res;
          })
          .catch((err, d) => {
            return Observable.throw(err);
          })
          .catch((err, d) => {
            if (err.status === 404) {
              this.message = "Søke ga ingen resultat.";
            } else {
              this.message = "Rusk i maskineriet. Prøv igjen!";
            }
            return Observable.of(new Array<ReservationsAgregate>());
          })
          .do((x) => {
            this.message = "";
          });
        return search$;
      });

  }
  

  ngOnInit() {
    this.searchForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
    this.searchForm.getRawValue();
    this.bookableResources$ = this.bookingResourceService.getResources();
    this.activityService = initActivityService(this.adalService);
    this.bookableResources$.subscribe((x) => {
      this.bookableResources = x.reduce(
        (acc, curr) => acc.set(curr.id.toLowerCase(), curr),
        new Map<string, BookableResource>()
      );
    });
    
    this.activityService.getActivities().then(activities => {
      this.activities = activities;
      this.groupActivityIds = new Set(activities.filter(a => a.activityType == ActivityType.Group).map(x=>x.id));
    });

  }
  isGroupActivity(reservation:ReservationsAgregate){
    return this.groupActivityIds.has(reservation.referenceId);
  }
  getParkName(reservation:ReservationsAgregate): string{
    var activity = this.activities.find(x=>x.id == reservation.referenceId);
    if(!activity) return "Ukjent park";
    return this.bookableResources.get(activity.siteId)?.name;
  }
  getPrityUrl(reservation:ReservationsAgregate): string{
    var activity = this.activities.find(x=>x.id == reservation.referenceId);
    if(!activity) return "Ukjent park";
    return this.bookableResources.get(activity.siteId)?.prittyUrl;
  }
  checkPayment(reservationId: string): Observable<boolean> {
    var apiUrl = environment.dependencies.time_scheduling_api.url;
    var url = `${apiUrl}/api/payments/isPaid/${reservationId}`;

    return this.http.get(url).map((obj: any) => {
      return obj.isPaid === true;
    });
  }

  cancel(reservation: ReservationsAgregate) {
    this.timeScheduleService.cancelMany(reservation.reservations).subscribe((cancelation) => {
      this.searchForm.controls.search.setValue(
        this.searchForm.controls.search.value
      );
    });
  }
  openDialog(reservation: ReservationsAgregate) {
    const dialogRef = this.dialog.open(DialogDeleteBookingComponent, {
      // data: this.groupReservation
      data: reservation,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancel(reservation);
      }
    });
  }
}
@Component({
  selector: "app-dialog-delete-booking",
  templateUrl: "dialog-delete-booking.html",
})
export class DialogDeleteBookingComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteBookingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
