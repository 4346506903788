import React, { FC } from "react"
import { EditProductForm } from "./components/EditProductForm"

type Props = {

}

export const EditProductPage: FC<Props> = () => {
  return (
    <>
      <EditProductForm />
    </>
  )
}