import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingComponent, GetValuesPipe } from './booking/booking.component';

import { SystemModule } from '../system/system.module';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SummaryComponent } from './summary/summary.component';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
import { GbookingService } from './gbooking.service';
import { TimeSchedulingModule } from '../time-scheduling/time-scheduling.module';
import { CatalogService } from './catalog.service';
import { SignatureComponent } from './signature/signature.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    TimeSchedulingModule,
    RouterModule
  ],
  declarations: [BookingComponent, SummaryComponent,
    BookingConfirmationComponent, SignatureComponent,GetValuesPipe],
  providers: [GbookingService,CatalogService]
})
export class GroupBookingModule { }
