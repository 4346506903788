<div fxLayout="row" class="header">
    <div fxFlex class="left-controls">
    <h1>Booking oversikt</h1>

    <p>Antall bookinger betalt med vips <span *ngIf="!calculating">{{payments.length}}</span>
      <span *ngIf="calculating">vent mens vi kalkulerer...</span></p>

    <p>Totalt vips denne måneden <span *ngIf="!calculating">{{sumPaidMonth}}</span>
      <span *ngIf="calculating">vent mens vi kalkulerer...</span></p>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="end center" class="right-controls">
      <!-- <mat-chip-list aria-label="Fish selection" multiple="true">
        <mat-chip *ngFor="let s of allScheduleTypeFilter" [selected]="isSelectedSchedule(s)" (click)="togleSchedule(s)">{{getTypeText(s)}}</mat-chip>
      </mat-chip-list> -->
      <mat-chip-list aria-label="Fish selection" multiple="true">
        <mat-chip *ngFor="let a of allActivitiesFilter" [selected]="isSelectedActivity(a)" (click)="toggleActivity(a)">{{a.name}}</mat-chip>
      </mat-chip-list>
      <mat-form-field style="padding: 16px">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" type="text" autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <input matInput [matDatepicker]="dp" [formControl]="selectedDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="year"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        panelClass="month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>
</div>

<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults || calculating"></mat-spinner>
</div>
<div class="table-container">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let colDef of columnDefs" matColumnDef="{{colDef.field}}" >
      <th mat-header-cell *matHeaderCellDef>{{colDef.title}}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="colDef.field !== 'actions'">{{colDef.getValue(element)}}</div>
        <div *ngIf="colDef.field === 'actions'">
          <button mat-icon-button [matMenuTriggerFor]="bookingMenu" [matMenuTriggerData]="{element: element}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container>
      <th mat-header-cell *matHeaderCellDef>Actions</th>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'paid':isPaid(row.reservationId)}"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #bookingMenu="matMenu">
  <ng-template matMenuContent let-element="element">
    <!-- <button mat-menu-item (click)="onEditBookingClick(element)">Endre</button> -->
    <button mat-menu-item (click)="onCancelBookingClick(element)">Kansellere</button>
  </ng-template>
</mat-menu>


