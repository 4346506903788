import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { User } from './user.model';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class LoginService {

  private user: User = null;
  return: string = '';
  
  isLoggedIn(): boolean {
    var token = localStorage.getItem('access_token');
    return !!token && !this.helper.isTokenExpired(token);
  }
  constructor(private http: HttpClient,private router: Router,
    private route: ActivatedRoute,private helper: JwtHelperService ) { 
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/waver');
  }
  login(user: User,): any {
    this.http.post(`${environment.dependencies.waiver_api.url}/api/token`,user).subscribe(data=>{
      localStorage.setItem('access_token',data["access_token"]);
      this.router.navigateByUrl(this.return);
      
    });
  }
}
