export class BUser{
    constructor(public readonly adalUser:adal.User, public readonly token:any ){}
    public hasSiteAccess(id:string){
        var sites = (this.token && this.token.sites)?this.token.sites.toLocaleLowerCase() : "";
        return sites.indexOf(id)>-1;
    }
    public isAdmin():boolean{
        return this.token
        && this.token.roles
        && this.token.roles.indexOf("Bitmesh.Skyland.Admin") >= 0;
    }

}
