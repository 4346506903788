import { createContext, Dispatch, useReducer } from "react";
import { PriceInfo } from "../day-schedule.model";
import { Schedule } from "./schedule.model.new";
import { Reservation, TimeSlot } from "./timeslot.model.new";
import { Activity } from "./activity.model";
import { createChainOfHandlersFor } from "../../system/chain-of-handlers";

export type ReservationState = {
  date: Date;
  count: number;
  priceInfo: PriceInfo;
  timeSlot: TimeSlot;
  isValid: boolean;
  schedule: Schedule;
  totalPrice: number;
  activity: Activity;
  reservations: Reservation[]
}

const initialState: ReservationState = {

} as any;

type Action = {
  type: string;
  data: any;
}

const validityHandler = (state: ReservationState): ReservationState => {
  const { date, count, priceInfo, timeSlot } = state;
  return {
    ...state,
    isValid: date != null && count != null && priceInfo != null && timeSlot != null
  }
}

const priceHandler = (state: ReservationState): ReservationState => {
  const { count, priceInfo } = state;
  let minGroupSize = state?.activity?.minGroupSize;
  let bookedCount = minGroupSize && minGroupSize > 0
    && count < minGroupSize? minGroupSize: count;
  return {
    ...state,
    totalPrice: (bookedCount ?? 0) * (priceInfo?.price ?? 0)
  }
}

const chain = createChainOfHandlersFor<ReservationState>([validityHandler, priceHandler]);

const reducer = (state: any, action: Action): ReservationState => {
  switch (action.type) {
    case "SET_DATE":
      return chain({
        ...state,
        date: action.data,
      });
    case "SET_COUNT":
      return chain({
        ...state,
        count: action.data,
      });
    case "SET_PRICE_INFO":
      return chain({
        ...state,
        priceInfo: action.data,
      });
    case "SET_TIME_SLOT":
      return chain({
        ...state,
        timeSlot: action.data,
      });
    case "SET_SCHEDULE":
      return chain({
        ...state,
        schedule: action.data,
      });
    case "SET_ACTIVITY":
        return chain({
          ...state,
          activity: action.data,
        });
    case "SET_RESERVATIONS":
      return chain({
        ...state,
        reservations: action.data,
      });
    default:
      return state;
  }
}

export const useReservationReducer = () => useReducer(reducer, initialState);

type ReservationContextType = {
  state: ReservationState;
  dispatch: Dispatch<Action>;
};

export const ReservationContext = createContext<ReservationContextType>({ state: initialState, dispatch: () => {} });
