import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { WaiverForm } from '../waiverform.model';
import { Person } from '../Person.model';
import { NgForm } from '@angular/forms';
import { WaverFromService } from '../waver-from.service';
import { Guid } from '../../system/guid';
import { UplaodService } from '../../uplaod.service';

import { environment } from '../../../environments/environment';


@Component({
  selector: '[app-waver-photo]',
  templateUrl: './waver-photo.component.html',
  styleUrls: ['./waver-photo.component.scss']
})
export class WaverPhotoComponent implements OnInit {

  private uploading: boolean = false;
  public newWaiver: WaiverForm;
  public fileErrorMessage: string = null;
  public fileInfo:string = null;
  private readonly waiverApi = environment.dependencies.waiver_api.url + "/api/wavercontracts";
  public imageUrl:string = null;
  public message:string = null;
  
  @ViewChild("fileInput", { static: false }) fileInput;

  constructor(private service: WaverFromService, private uploadService: UplaodService,private route: ActivatedRoute) {
    
  }

  private initWaiver() {
  
    this.newWaiver = {
      id: Guid.newGuid().toString(),
      contactPerson: { firstname: "", lastname: "", birth: moment() },
      email: "",
      mobile: "",
      signingdate: moment().startOf('day'),
      children: [],
      advertising: false,
      tenantId:environment.tenantId,
      referenceId:Guid.empty().toString()
    };
    this.fileInfo = null;
    this.fileErrorMessage = null;
    this.imageUrl = null;
  }

  ngOnInit() {
    this.initWaiver();
  }
  
  SaveWaiver(empForm: NgForm): void {
    if(!this.fileInfo){ 
      this.fileErrorMessage ="Du må laste inn en fil før du kan lagre";
      return;
    };
    this.newWaiver.signingdate = this.newWaiver.signingdate
                                  .minute(this.newWaiver.signingdate.utcOffset())
    this.service.save(this.newWaiver)
      .catch((err) => {
        let details = err.json();
        console.error(details);
        return err;
      }).subscribe((result) => {
        this.initWaiver();
        this.message = "Waiver bilde lagret";
        setTimeout(()=>{
          this.message = null;
        },3000);
      });
     this.initWaiver();
  }

  addFile(): void {
    
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      this.fileErrorMessage = null;
      this.fileInfo = null;
      this.uploading = true;
      let fileName = this.newWaiver.id;
      let fileToUpload = fi.files[0];
      this.uploadService
        .upload(fileName, fileToUpload)
        .catch((err)=>{
          this.fileErrorMessage = "Det oppstod en feil under filopplasting. Prøv igjen!"
          return err;
        })
        .finally(()=>{
          this.uploading = false;
        })
        .subscribe(res => {
          this.fileInfo = `Fil med id ${this.newWaiver.id} er lastet opp`;
          this.imageUrl = this.getImageUrl();
        });
    }
  }

  getImageUrl(){
    return `${this.waiverApi}/upload/${this.newWaiver.id}`;
  }
  isUploading() {
    return this.uploading;
  }
}
