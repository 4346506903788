import moment from "moment";
import 'moment/locale/nb';

export class CalendarTool{
  	static formatDateTime(date: moment.Moment): string {
    return date.format('MM.DD.YYYY HH:mm');
  	}
    public static formatDate(date: moment.Moment): string {
        return date.format('DD.MM.YYYY');
    }
    public static justDate(date:moment.Moment){
        var d =  date.clone().set({hour:0,minute:0,second:0,millisecond:0});
        return d.minute(d.utcOffset());
    }
    public static justDateOrNull(date:any){
      return date? CalendarTool.justDate(moment(date)): null;
  }
}
