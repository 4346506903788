<div *ngIf="groupReservation">

</div>
<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<div class="containerX" fxLayout="column" fxLayout.xs="column" *ngIf="groupReservation">
  <div fxFlex="1 1 50%" fxFlexOrder fxFlexOrder.xs="1">
    <h2>Booking for mobil nummer : {{ groupReservation.details?.mobile }}
      e-post : {{ groupReservation.details?.email }}
    </h2>
  </div>
  <div class="item-style" *ngIf="groupReservation.reservation">
    <p>
      <span>Referanse nummer : </span>
      <span>{{groupReservation.reservation.referenceNumber}}</span>
    </p>
    <p>
      <span>Dato: </span>
      {{ groupReservation.reservation.start().local() | date:'dd.MM.yyyy'}}

    </p>
    <p>
      <span>Tidspunkt: </span>

      <span>{{groupReservation.reservation.start().local() | date:'HH:mm' }} - {{ groupReservation.reservation.end().local() |
        date:'HH:mm' }}</span>
    </p>

    <p>
      <span>Antall : </span>
      <span>{{groupReservation.reservation.count}}</span>
    </p>
    <p>
      <span>Booking Price: </span>
      <span>{{sumPrice}}</span>
    </p>
    <div class="btn-endretidspunkt">
      <button mat-raised-button (click)="openDialog()" color="primary">Endre tidspunkt</button>
      <button mat-raised-button (click)="cancel()" color="primary">Kanseler</button>
    </div>
    <p class="occured_time">
      <span>Registrert den: </span>
      <span>{{groupReservation.reservation.occuredTime.local() | date:'dd.MM.yyyy' }}</span>
    </p>
  </div>
  <h2>Activities</h2>
  <span *ngFor="let reservation of getActivityReservations(groupReservation)">
    <b>{{getActivity(reservation.referenceId)?.name}}</b>
    {{reservation.start().local() | date:'HH:mm' }} - {{ reservation.end().local() | date:'HH:mm' }} - {{reservation.unitPrice}} kr.
  </span>
  <h2> Bestilt Mat,drikke & Tillegg</h2>
  <div *ngFor="let extras of groupReservation.extras" class="item-style">
    {{extras.description}} {{extras.price}}
  </div>
  <div>
    Sum Ekstra: {{sumPriceExtras}}
  </div>
  <div class="item-style">
    <h2> Allergi /annet</h2>
    {{groupReservation.message}}
  </div>

  <div class="item-style">
    <h2> Bestillerens detaljer</h2>
    <span>
      <strong>Navn:</strong>
    </span> {{groupReservation.details?.contactPerson?.firstname}} {{groupReservation.details?.contactPerson?.lastname}}

    <fieldset>
      <legend>Bursdagsbarn:</legend>
      <div *ngFor="let child of groupReservation.details?.children">
        <p>
          <span>
            <strong>Navn:</strong>
          </span>{{child.firstname}} {{child.lastname}}
          <span *ngIf="child.age"><strong> År gammel:</strong> {{child.age}}</span>
          <span *ngIf="!isDefaultDate(child.birth)"><strong> Fødselsdato :</strong> {{child.birth | date: 'dd.MM.yyyy' }}</span></p>
      </div>
    </fieldset>

  </div>
  <div class="btn-endretidspunkt">
    <button mat-raised-button (click)="editGroupBooking()" color="primary">Endre</button>
  </div>


</div>
