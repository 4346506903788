<div>
    <form class="box" #waiverForm="ngForm" *ngIf="!confirmationLog">
      <h1>Create a waiver</h1>
      <p>{{message}}</p>
      <mat-form-field class="example-full-width" >
        <input matInput placeholder="Mobil" name="mobile" [focus]="focusPhone"
          [(ngModel)]="newWaiver.mobile">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Navn" name="parrentname" [(ngModel)]="newWaiver.contactPerson.firstname">
        <!-- <br> -->
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Etternavn" name="parrentlastname" [(ngModel)]="newWaiver.contactPerson.lastname">

      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput placeholder="E-mail" name="email" [(ngModel)]="newWaiver.email">

      </mat-form-field>
      <!-- <button (click)="showaddChild()" mat-button type="button">{{btaddChild}}</button> -->
        <div>
          <ul>
            <li *ngFor="let child of newWaiver.children">
              <span>{{child.firstname}} {{child.lastname}}</span>
              <button (click)="deletefromlist(child)" mat-button type="button"> slett</button>
            </li>
          </ul>

        </div>

      <div class="childre"> 
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Barnes Fornavn" name="bfn" [focus]="focusChildrenEventEmiter" [(ngModel)]="newChild.firstname">
        </mat-form-field>
        <br>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Barnes Etternavn" name="ben" [(ngModel)]="newChild.lastname">
        </mat-form-field>
        <br>
        <button (click)="addChildToList()" mat-button type="button">legg til Barn</button>
      </div>
      <button  mat-button type="button" (click)="requestConfirmation(waiverForm)"> Signer </button>
  </form>
  <form class="box" #smsKodeForm="ngForm" *ngIf="confirmationLog">
    <div *ngIf="!isConfirmationOk;else confirmationOk">
      <h1>Bekreft med sms kode!</h1>
      <p>{{message}}</p>
      <mat-form-field class="example-full-width" >
        <input matInput placeholder="Sms kode" name="code" [focus]="focusCode"
          [(ngModel)]="confirmationLog.logEntry.code">
      </mat-form-field>
      <button  mat-button type="button" (click)="checkCode(smsKodeForm)">Bekreft</button>
    </div>
    <ng-template #confirmationOk>
      <h2>Du har signert waiveren!</h2>  
    </ng-template>
  </form>
</div>
