import {
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { environment } from "../../environments/environment";
import { Guid } from "../system/guid";
import { AttachmentFile } from "./attachment";
import FileUpload, { extractExtension, SupportedMediaFormatsType } from "./FileUpload";

export const FILEUPLOAD_URL =
  environment.dependencies.file_api.url + "/api/fileupload";

export const useImageUpload = async (
  data: any,
  id: string,
  extension: SupportedMediaFormatsType,
  blob: string = 'skyland-booking'
) => {
  await axios
    .put(
      `${FILEUPLOAD_URL}/${blob}/${id}${extension}`,
      data,
      {
        headers: {
          "Content-type": "application/json; charset=utf-8",
        },
      }
    )
    .then((result) => {
      return result;
    });
};

type Props = {
  open: boolean;
  onSubmit: (mediaId: string, extension: SupportedMediaFormatsType) => void;
  onClose: () => void;
};

const AttachMediaDialog = ({ open, onClose, onSubmit }: Props) => {

  const [attachments, setAttachments] = useState<AttachmentFile>();

  const handleSubmit = () => {
    const mediaId = Guid.newGuid().toString();
    const extension = extractExtension(attachments.files[0]?.name);
    useImageUpload(attachments.data(), mediaId, extension)
    .then(() => {
      onSubmit(mediaId, extension);
      onClose();
    })
    .catch(() => {
      // TODO: Alert the user about the unsuccessful file upload
      onClose();
    });
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <FileUpload
          attachments={AttachmentFile(attachments)}
          onAttachmentsChange={(value) => setAttachments(value)}
        />
        <Button style={{marginTop: '1em'}} onClick={handleSubmit} variant="contained" color="primary">{`${"Submit"}`}</Button>
      </DialogContent>
    </Dialog>
  );
};

export default AttachMediaDialog;
