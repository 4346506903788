import React, { useContext, useEffect, useState } from 'react';
import { Product } from '../model';
import { ProductsContext } from '../ProductsReactWrapper';
import { initProductService } from '../productsService';
import { ProductCard } from './ProductCard';
import { SearchableList } from '../../react-shared/SearchableList';

export const ProductsList = () => {
  const { adalService } = useContext(ProductsContext);

  const [productService] = useState(initProductService(adalService));

  const [products, setProducts] = useState<Product[]>([]);

  const fetchProducts = () => productService.getProducts().then(products => setProducts(products));
  const deleteProduct = (id: string) => {
    productService.deleteProduct(id).then(() => fetchProducts());
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <SearchableList
        list={products}
        renderElement={(u: Product) => <ProductCard key={u.id} product={u} onDelete={deleteProduct} />}
      />
    </>
  );
}