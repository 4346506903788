/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { DateInput } from "../../react-shared/DateInput";
import { SelectInput } from "../../react-shared/SelectInput";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { ReservationContext } from "./reservation.context";
import { Reservation } from "./timeslot.model.new";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { PriceInfo } from "../day-schedule.model";
import { useTimeScheduleService } from "./time-schedule.service.new";
import { Activity } from "./activity.model";
import {
  getAvailableTimeSlotsFor,
  TimeSlotReservations,
} from "./timeSlotUtils";

type Props = {
  activities?: Activity[];
  cancelations?: Reservation[];
};

const minAtendees = 5;
const maxAtendees = 100;

export const DateTimeAtendees: FC<Props> = ({ cancelations, activities }) => {
  const { state, dispatch } = useContext(ReservationContext);

  const query = new URLSearchParams(window.location.search);
  const packageDuration = query.get("packageDuration") || "";
  // const parsedPackageDuration = parseInt(packageDuration);

  const allActivities = activities || (state.activity ? [state.activity] : []);
  const [allReservations, setAllReservations] = useState<Reservation[]>();
  // const [duration, setDuration] = useState<number | null>(
  //   isNaN(parsedPackageDuration) ? null : parsedPackageDuration
  // );
  const [selectedPriceInfo, setSelectedPriceInfo] = useState<PriceInfo | null>();
  const [durations, setDurations] = useState<PriceInfo[]>([]);
  const [availableTimeSlotReservations, setAvailableTimeSlotReservations] =
    useState<TimeSlotReservations[]>([]);

  const { timeScheduleService } = useTimeScheduleService();
  useEffect(() => {
    if (state.date === null) return;
    const allResourceIds = allActivities.reduce<string[]>(
      (acc, curr) => [...acc, curr.id,...curr.getRequiredResourceIds()],
      []
    );
    timeScheduleService
      .getReservationsFor(allResourceIds, moment(state.date))
      .subscribe((reservations) => {
        setAllReservations([...reservations, ...(cancelations ?? [])]);
      });
  }, [state.activity, activities, state.date]);

  useEffect(() => {
    if (
      !allReservations ||
      !state.count ||
      selectedPriceInfo == null ||
      state.date === null
    ){
      return;
    }
    const price = selectedPriceInfo?.price ?? 0;
    const availableSlots = getAvailableTimeSlotsFor(
      state.activity?.id,
      allActivities,
      allReservations,
      state.count,
      selectedPriceInfo?.duration,
      state.date,
      price
    );
    console.log(availableSlots);
    setAvailableTimeSlotReservations(
      availableSlots
    );
  }, [allReservations, state.date, state.count, selectedPriceInfo]);

  useEffect(() => {
    if (!state.activity) return;
    setDurations(state.activity?.getAllDurations());
  }, [state.activity]);

  useEffect(() => {
    if (selectedPriceInfo) {
      const priceInfo: PriceInfo | undefined = selectedPriceInfo;
      if (priceInfo) dispatch({ type: "SET_PRICE_INFO", data: priceInfo });
    }
  }, [selectedPriceInfo, durations]);
  const getPriceinfoId = (info:PriceInfo)=>{
    return `${info?.price}+${info?.duration}`;
  }
  return (
    <>
      <DateInput
        label={
          <>
            Velg dato <InsertInvitationIcon />
          </>
        }
        onChange={(date) => dispatch({ type: "SET_DATE", data: date })}
        value={new Date()}
      />
      <SelectInput
        label={
          <>
            Velg deltakere <PeopleRoundedIcon />
          </>
        }
        value={state.count ?? ""}
        onChange={(c) => dispatch({ type: "SET_COUNT", data: c })}
        options={Array.from(
          { length: maxAtendees - minAtendees + 1 },
          (_, i) => ({
            label: i + minAtendees,
            value: i + minAtendees,
          })
        )}
      />
      <SelectInput
        label={"Velg varighet"}
        value={getPriceinfoId(selectedPriceInfo)}
        onChange={(e: any) =>{
          // setDuration(e);
          const selected = durations.find(x=>getPriceinfoId(x) ==e);
          console.log("test",selected);
          setSelectedPriceInfo(selected);
        }}
        options={
          !durations
            ? []
            : durations.map((p) => ({
                label: `${p.viewValue} ${p.price},- kr`,
                value: getPriceinfoId(p),
              }))
        }
      />
      <SelectInput
        label={
          <>
            {"Velg tid"} <AccessTimeIcon />
          </>
        }
        value={state?.timeSlot?.id ?? ""}
        onChange={(e: any) => {
          const timeSlotReservations = availableTimeSlotReservations.find(
            (ts) => ts.timeSlot.id === e
          );
          dispatch({
            type: "SET_TIME_SLOT",
            data: timeSlotReservations?.timeSlot,
          });
          dispatch({
            type: "SET_RESERVATIONS",
            data: timeSlotReservations?.reservations,
          });
        }}
        options={availableTimeSlotReservations.map((t) => ({
          label: t.timeSlot.start().format("HH:mm"),
          value: t.timeSlot.id,
        }))}
      />
    </>
  );
};
