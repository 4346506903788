import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { WaiverForm } from '../waiverform.model';
import { Person } from '../Person.model';
import { NgForm, FormBuilder } from '@angular/forms';
import { WaverFromService } from '../waver-from.service';
import { Guid } from '../../system/guid';
import { UplaodService } from '../../uplaod.service';

import { environment } from '../../../environments/environment';
import { SignatureLog } from '../../signature-log/signature-log';
import { SignatureLogService } from '../../signature-log/signature-log.service';
import { Observable } from 'rxjs/Observable';


@Component({
  selector: '[app-waver-signature-form]',
  templateUrl: './waiver-signature-form.component.html',
  styleUrls: ['./waiver-signature-form.component.scss']
})
export class WaiverSignatureFormComponent implements OnInit {

  private uploading: boolean = false;
  isShow: boolean = false;
  btaddChild: string = "pluss Barn";
  newChild: Person;
  @Input("waiver")
  public newWaiver: WaiverForm;
  public confirmationLog: SignatureLog;
  public fileErrorMessage: string = null;
  public showForm: boolean = true;
  public fileInfo: string = null;
  public message: string = "";
  public isMobileOk = false;
  private id: string = null;
  private sub: any;
  public imageUrl: string = null;
  public isConfirmationOk: boolean = false;

  private readonly waiverApi = environment.dependencies.waiver_api.url + "/api/wavercontracts";
  public focusChildrenEventEmiter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public focusPhone: EventEmitter<boolean> = new EventEmitter<boolean>();
  public focusCode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("fileInput", { static: false }) fileInput;

  constructor(private service: WaverFromService, 
    private uploadService: UplaodService, 
    private router: Router, private route: ActivatedRoute,
    private signatureService: SignatureLogService) {

  }

  private initChild() {
    this.newChild = new Person("", "", moment());
  }

  private initWaiver() {

    this.newWaiver = {
      id: Guid.newGuid().toString(),
      contactPerson: { firstname: "", lastname: "", birth: moment() },
      referenceId: Guid.empty().toString(), 
      email: "",
      mobile: "",
      signingdate: null,
      children: [],
      advertising:false,
      tenantId: environment.tenantId,
    };
    this.initChild();
    this.fileInfo = null;
    this.showForm = false;
    this.fileErrorMessage = null;
    this.isShow = false;
    this.isMobileOk = false
    this.message = "";
    this.imageUrl = null;

  }

  checkMobile(event) {
    if (event.keyCode == 13) {
      this.service.search(this.newWaiver.mobile).subscribe(data => {
        this.isMobileOk = data['length'] == 0;
        if (!this.isMobileOk) {
          this.message = "Telefon nummer eksisterer";
        }
        else {
          this.message = "";
        }
      });
    }
  }

  ngOnInit() {
    this.isShow = false;
    this.btaddChild = "pluss Barn";
    this.initWaiver();
    setTimeout(() => {
      this.focusPhone.emit(true);
    });

  }


  deletefromlist(child) {
    this.newWaiver.children.splice(this.newWaiver.children.indexOf(child), 1);
  }

  showaddChild() {
    this.isShow = !this.isShow;
    this.btaddChild = this.isShow ? "Hide barn" : "pluss Barn";
  }

  



  addChildToList() {
    this.newWaiver.children.push(this.newChild);
    this.initChild();
    this.focusChildrenEventEmiter.emit(true);
  }

  requestConfirmation(empForm: NgForm){
    // if (!this.isMobileOk) return;
    var confirmationLog: SignatureLog = {
      id: Guid.empty().toString(),
      documentId: this.newWaiver.id,
      logEntry: { mobile:this.newWaiver.mobile },
      occuredTime: moment().utc(),
      tenantId: environment.tenantId
    };
    this.signatureService.requestConfirmation(confirmationLog)
    .catch((err) => {
      if(err.status == 400){
        this.message = "Feil på mobil nummer";
      }
      else{
        this.message = "Noe gikk galt. Prøvi igjen!";
      }
      return Observable.throw(err);
    }).subscribe((result) => {
      this.message = "";
      this.waitForConfirmationCode();
    });
  }
  waitForConfirmationCode() {
    this.confirmationLog = {
      id: Guid.empty().toString(),
      documentId: this.newWaiver.id,
      logEntry: { mobile:this.newWaiver.mobile, code:"" },
      occuredTime: null,
      tenantId: environment.tenantId
    };
    setTimeout(()=>{
      this.focusCode.emit();
    });
  }
  checkCode(empForm: NgForm){
    this.confirmationLog.occuredTime = moment().utc();
    this.submitCode(this.confirmationLog);
  }
  submitCode(confirmationLog:SignatureLog){
    this.signatureService.requestConfirmation(confirmationLog)
      .catch((err) => {
        if(err.status == 400){
          this.message = "Feil kode prøv igjen";
        }
        else{
          this.message = "Noe gikk galt. Prøvi igjen!";
        }
        setTimeout(()=>{
          this.focusCode.emit();
        });
        return Observable.throw(err);
      }).subscribe((result) => {
        this.isConfirmationOk = true;
        this.message = "";
        this.saveWaiver();
      });
  }
  
  saveWaiver(): void {
    this.newWaiver.signingdate = moment().utc();
    // this.newWaiver.signingdate = this.newWaiver.signingdate.minute(this.newWaiver.signingdate.utcOffset())
    this.service.post(this.newWaiver)
      .catch((err) => {
        console.error(err);
        this.message = "Noe gikk galt. Prøv igjen!"
        return Observable.throw(err);
      }).subscribe((result) => {
        this.isConfirmationOk = true;
        this.newWaiver = result;
        this.message ="";
      });
  }
  
}
