import moment from "moment";
import { Observable } from "rxjs";
import { Schedule } from "./schedule.model.new";
import { Reservation, TimeSlot } from "./timeslot.model.new";
import { TimeScheduleService } from "./time-schedule.service.new";
import { ObservableAxios, observableAxios } from "../../system/rxjs-axios";
import { Guid } from "../../system/guid";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

export class BookingService {
  private timeScheduleService: TimeScheduleService;

  constructor(private http: ObservableAxios = observableAxios) {
    this.timeScheduleService = new TimeScheduleService(this.http as any);
  }

  public startVippsPayment(reservationId: string, setMessage: (msg: string) => any) {
    setMessage("");

    if (!reservationId) {
      setMessage("Det oppstod en feil ved betaling med Vipps, vennligst betal i parken.");
      return;
    }

    var apiUrl = environment.dependencies.time_scheduling_api.url;
    var url = `${apiUrl}/api/vipps/initiate/${reservationId}`;

    this.http.post(url, null).subscribe(
      (res: any) => {
        var url = res.redirectUrl;

        if (!url) {
          setMessage("En feil oppstod ved kontakt mot betalingstjenesten. Vennligst betal i parken.");
          return;
        }
        window.location.href = url;
      },
      (err) => {
        if(!err.response){
          setMessage("Ingen kontakt med server ");
        } else if (err.response.status === 409) {
          setMessage("Reservasjonen er allerede betalt.");
        } else {
          setMessage("En feil oppstod ved kontakt mot betalingstjenesten. Vennligst prøv på nytt eller betal i parken.");
        }
      }
    );
  }

  public makeReservation(monthSchedule: Schedule, timeSlot: TimeSlot, price: number, count: number, mobile: string, reservationId: Guid= Guid.empty()): Observable<Reservation> {
    var reservation: Reservation|null = monthSchedule.getReservation(timeSlot, price, count);
    if(reservation == null) throw new Error();

    reservation.mobile = mobile;
    if(reservationId != Guid.empty()){
      reservation.reservationId = reservationId.toString();
    }

    return this.timeScheduleService
      .makeReservation(reservation, monthSchedule)
      .pipe(map((reservation: Reservation) => {
        return reservation;
      }));
  }
  public makeReservations(reservations:Array<Reservation>): Observable<Array<Reservation>>{
    return this.timeScheduleService.makeReservations(reservations);
    // .pipe(map((reservation: Array<Reservation>) => {
    //   return reservation;
    // }));;
  }

  public createReservation(monthSchedule: Schedule, timeSlot: TimeSlot, price: number, count: number, mobile: string, reservationId: string = ""): Reservation {
    var reservation: Reservation|null = monthSchedule.getReservation(timeSlot, price, count);
    if(reservation == null) throw new Error();

    reservation.mobile = mobile;
    if(!reservationId){
      reservation.reservationId = reservationId;
    }
    return reservation;
  }

  public filterDurations(date: moment.Moment, schedule: Schedule, campaign: string) {
    var durations = schedule.getPriceInfos(date) ?? [];
    durations = campaign !== ""
        ? durations.filter((x) => x.campaign === campaign)
        : durations.filter((x) => x.campaign === null || x.campaign === "");
    return durations;
  }
}
