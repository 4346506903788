import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TimeScheduleService } from '../../time-scheduling/time-schedule.service';
import { GroupReservation } from '../../time-scheduling/group/GroupReservation';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm, FormArray } from '@angular/forms';
import { TimeSlot, Reservation } from '../../time-scheduling/timeslot.model';
import { DateSchedule } from '../../time-scheduling/date-schedule.model';
import { BookingFormService } from '../../time-scheduling/booking-form.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingFormComponent } from '../../time-scheduling/booking-form/booking-form.component';
import { DayScheduleTypes } from '../../time-scheduling/day-schedule.model';
import { CalendarTool } from '../../system/calendartool';
import { ServiceBusService } from '../../system/service-bus.service';
import { Chanels } from '../../chanels';
import { environment } from '../../../environments/environment';
import { Subject, Observable, forkJoin } from 'rxjs';
import { TimeScheduleService as TimeScheduleServiceNew } from '../../time-scheduling/booking-form-react/time-schedule.service.new'
import { observableAxios } from '../../system/rxjs-axios';

import { Reservation as NewReservation } from '../../time-scheduling/booking-form-react/timeslot.model.new';

@Component({
  selector: 'app-booking-details-dialog',
  templateUrl: 'dialog-overview-dialog.html',
})
export class BookingDetailsDialogComponent implements OnInit {
  reservation: Reservation;
  resourceUrl: string = "krsvag";

  newReservations: NewReservation[];

  public activityIds: string[] = [];
  public mainActivityId: string;
  public mobile: string ="";

  public loading: boolean = false;
  cancelations: Reservation[]=[];

  @ViewChild('booking', { static: true }) booking: BookingFormComponent;
  constructor(
    public dialogRef: MatDialogRef<BookingDetailsDialogComponent>,
    public timeScheduleService: TimeScheduleService,
    public bookingFormService: BookingFormService,
    private bus: ServiceBusService,
    @Inject(MAT_DIALOG_DATA) public data: GroupReservation) {

  }
  ngOnInit() {
    this.bus.chanelObservable(Chanels.RESOURCE).subscribe(r => {
      if (!r) { return; }
      this.resourceUrl = r.prittyUrl;
      this.selectValue();
    });
    const svc = new TimeScheduleServiceNew(observableAxios);
    
    this.mainActivityId = this.data.reservation.referenceId;
    this.mobile = this.data.reservation.mobile || this.data.details.mobile;
    const oldReservations = [this.data.reservation,...this.data.reservations.filter(x=>x.id !== this.data.reservation.id)];
    this.cancelations = this.timeScheduleService.mapToCancelations(oldReservations);
    this.activityIds = [...new Set(oldReservations.filter(x => x.type === "ActivityReserved").map(x => x.referenceId))];
  }

  setNewReservations(reservations: NewReservation[]) {
    if(!reservations) return;
    reservations.forEach(r=>{
      r.mobile = this.mobile;
    });
    this.newReservations = reservations;
  }

  receiveBookignForm(form: FormGroup) {
    const reservationForm = form;
    const reservation = this.data.reservation;
    reservationForm.patchValue({
      day: CalendarTool.justDate(reservation.startTime),
      count: reservation.count
    });
    this.booking.selectionChanged(null);
  }

  selectValue() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ok() {
    this.reschedule().subscribe((res) => {
      this.dialogRef.close(res);
    });

  }

  reschedule(): Observable<any> {
    this.loading = true;
    const result  = new Subject();
    const newGroupReservation = {...this.data};
    newGroupReservation.reservation = this.newReservations.find(x => x.referenceId === this.mainActivityId) as any;
    newGroupReservation.reservations = this.newReservations.filter(x => x.referenceId !== this.mainActivityId) as any;
    return this.timeScheduleService.rescheduleGroupReservation(this.data,newGroupReservation)
    .do({
      complete:()=>{
        this.loading = false;
      }
    });
  }


  timeSlotSelected(selected: TimeSlot) {
    this.reservation = this.bookingFormService.createReservation();
    this.reservation.tenantId = environment.tenantId;
    this.reservation.dayScheduleType = DayScheduleTypes.Birthday;
    this.reservation.mobile = this.data.details.mobile;
    // debugger;
  }

  setMonthSchedule(monthSchedule: Map<string, DateSchedule>) {
    // this.monthSchedule = monthSchedule;
  }

}
