import { DaySchedule } from "../day-schedule.model";
import moment, { Moment } from "moment";
import { Reservation, CapacityTimeSlot } from "./timeslot.model.new";
import {
  Observable,
  forkJoin,
} from "rxjs";
import { GroupReservation } from "./group-reservation.model";
import { Schedule } from "./schedule.model.new";
import { TimeSlotBookings } from "./time-slot-bookings.model.new";
import { flatMap, map } from 'rxjs/operators'
import { observableAxios, ObservableAxios } from "../../system/rxjs-axios";
import { useState } from "react";
import { environment } from "../../../environments/environment";

export function useTimeScheduleService() {
  const [timeScheduleService] = useState(new TimeScheduleService(observableAxios));

  return { timeScheduleService };
}

export class TimeScheduleService {
  private readonly base_schedule_url = environment.dependencies.time_scheduling_api.url;
  private readonly reservations_url =
    this.base_schedule_url + "/api/reservations";
  private readonly dayschedule_url =
    this.base_schedule_url + "/api/dayschedules";

  constructor(private http: ObservableAxios) {}

  public getSchedule(
    type: string = "",
    referenceId: string
  ): Observable<Schedule> {
    return forkJoin([
      this.getDaySchedules(type, referenceId),
      this.getAllReservations(referenceId),
    ]).pipe(
      map((data) => {
        return new Schedule(moment(), data[0], referenceId, data[1]);
      })
    );
  }

  public getDaySchedules(
    type: string  = "Regular",
    referenceId: string
  ): Observable<Array<DaySchedule>> {
    var params = {
      params: {
        referenceId: referenceId,
        scheduleType: type,
      },
    };
    // return this.http.get(`${this.dayschedule_url}?referenceId=${referenceId}&scheduleType=${type}`).map((data: Array<any>)=>{
    return this.http.get(this.dayschedule_url, params).pipe(
      map((data: Array<any>) => {
        return data.map((day) => {
          return DaySchedule.deSerialize(day);
        });
      })
    );
  }
  public getDaySchedule(id: string): Observable<DaySchedule> {
    return this.http.get(`${this.dayschedule_url}/${id}`).pipe(
      map((day: any) => {
        return DaySchedule.deSerialize(day);
      })
    );
  }
  putDaySchedule(daySchedule: DaySchedule) {
    return this.http.put(
      `${this.dayschedule_url}/${daySchedule.id}`,
      daySchedule
    );
  }
  deleteDaySchedule(daySchedule: DaySchedule) {
    return this.http.delete(`${this.dayschedule_url}/${daySchedule.id}`);
  }
  // TODO add logick to Schedule object
  public mapToWeekSchedule(
    ob: Observable<Array<DaySchedule>>
  ): Observable<Map<string, DaySchedule>> {
    return ob.pipe(
      map((daySchedules: Array<DaySchedule>) => {
        return daySchedules.reduce(
          (map: Map<string, DaySchedule>, daySchedule: DaySchedule) => {
            return daySchedule.weekDays.reduce(
              (map: Map<string, DaySchedule>, daynr: number) => {
                map.set(daynr + '', daySchedule);
                return map;
              },
              map
            );
          },
          new Map<string, DaySchedule>()
        );
      })
    );
  }

  public getMonthCapacity(
    date: moment.Moment,
    type: string = "",
    referenceId: string
  ): Observable<Map<string, Array<CapacityTimeSlot>>> {
    return this.getSchedule(type, referenceId).pipe(map((schedule: Schedule) => {
      return schedule.countCapacity();
    }));
  }
  private mapReservation(x: any): Reservation {
    return Reservation.mapReservation(x);
  }
  private mapReservations(m: Array<any>): Array<Reservation> {
    var reservationMap = m
      .map((x) => Reservation.mapReservation(x))
      .reduce((acc: Map<string, Reservation>, r: Reservation) => {
        var reservation = acc.get(r.reservationId);
        if (!reservation) {
          acc.set(r.reservationId, r);
        } else if (r.count < 0) {
          acc.delete(r.reservationId);
        } else if (r.count === 0) {
          reservation.checkedIn = true;
        }
        return acc;
      }, new Map<string, Reservation>());
    return Array.from(reservationMap.values());
  }

  private getAllReservations(
    referenceId: string
  ): Observable<Array<Reservation>> {
    return this.http
      .get(this.reservations_url + `/${referenceId}`)
      .pipe(map((a: Array<any>) => this.mapReservations(a)));
  }

  public getReservationStreamByRefNumber(
    referenceNumber: string
  ): Observable<Array<Reservation>> {
    return this.http
      .get(this.reservations_url + `/ref-number/${referenceNumber}`)
      .pipe(map((a: Array<any>) => a.map(x => Reservation.mapReservation(x))));
  }

  public getTimeslotBookings(
    starOfTimeslot: moment.Moment,
    referenceId: string
  ): Observable<TimeSlotBookings> {
    return this.http
      .get(
        `${
          this.reservations_url
        }/timeslot/${starOfTimeslot.toISOString()}/${referenceId}`
      )
      .pipe(map((a: Array<any>) => this.mapReservations(a)))
      .pipe(map((reservations) => new TimeSlotBookings(reservations)));
  }
  public makeReservation(
    reservation: Reservation,
    monthSchedule: Schedule
  ): Observable<any> {
    return this.getReservations(reservation).pipe(flatMap((reservations) => {
      monthSchedule.setReservations(
        reservation.start(),
        reservations as Array<Reservation>
      );
      if (monthSchedule.addReservation(reservation)) {
        return this.postReservation(reservation).pipe(
          map((x: any) => this.mapReservation(x))
        );
      }
      // eslint-disable-next-line no-throw-literal
      throw { status: 400, msg: "fully booked" };
    }));
  }
  public makeReservations(reservations:Array<Reservation>): Observable<Array<Reservation>>{
    return  this.http.post(`${this.reservations_url}/multiple`, reservations).pipe(
      map((x: Array<any>) => x.map(this.mapReservation))
    );
  }
  getReservations(reservation: Reservation): Observable<Array<Reservation>> {
    var date = reservation.start();
    var referenceId = reservation.referenceId;
    return this.http
      .get(`${this.reservations_url}/${referenceId}`, {
        params: { date: date.format("DD.MM.YYYY") },
      })
      .pipe(map((x: Array<any>) => this.mapReservations(x)));
  }
  public getReservationsFor(referenceId: string[], date: Moment = moment()): Observable<Array<Reservation>> {
    return this.http
      .get(`${this.reservations_url}/references?date=${date.toISOString()}&${referenceId.map(x => `referenceId=${x}`).join('&')}`)
      .pipe(map((x: Array<any>) => {
        return x.map(r => Reservation.mapReservation(r));
      }));
  }
  searchReservations(search: string): Observable<Array<Reservation>> {
    return this.http
      .get(`${this.reservations_url}/search`, { params: { search: search } })
      .pipe(map((x: Array<any>) => this.mapReservations(x)));
  }

  postReservation(reservation: Reservation): Observable<any> {
    return this.http.post(this.reservations_url, reservation);
  }
  postGroupReservation(groupReservation: any): Observable<any> {
    return this.http.post(`${this.reservations_url}/groups`, groupReservation);
  }

  putGroupReservation(groupReservation: GroupReservation): Observable<any> {
    return this.http.put(
      `${this.reservations_url}/groups/${groupReservation.id}`,
      groupReservation
    );
  }

  public makeGroupeReservation2(
    groupReservation: any
  ) : Observable<Reservation> {
    return this.postGroupReservation(groupReservation).pipe(
      map((x: any) => this.mapReservation(x))
    );
  }


  getGroupReservations(
    date: moment.Moment,
    referenceId: string
  ): Observable<Array<GroupReservation>> {
    return this.http
      .get(`${this.reservations_url}/groups`, {
        params: {
          date: date.toISOString(),
          referenceId: referenceId,
        },
      })
      .pipe(
        map((r: Array<any>) => {
          return r.map((i) => {
            var res = i as GroupReservation;
            if (res.reservation) {
              res.reservation = this.mapReservation(res.reservation);
            }
            return res;
          });
        })
      );
  }
  getGroupReservationById(id: string): Observable<GroupReservation> {
    return this.http.get(`${this.reservations_url}/groups/${id}`).pipe(
      map((data) => {
        var res = data as GroupReservation;
        res.reservation = this.mapReservation(res.reservation);
        return res;
      })
    );
  }
  updateGroupReservationAlergies(id: string, updateAlergies: { alergies: string }): Observable<GroupReservation> {
    return this.http.put(`${this.reservations_url}/groups/${id}/alergies`, updateAlergies).pipe(
      map((data) => {
        var res = data as GroupReservation;
        return res;
      })
    );
  }
  cancel(reservation: Reservation): Observable<any> {
    var cancelation = new Reservation(
      reservation.startTime,
      reservation.duration,
      -reservation.count,
      reservation.mobile,
      reservation.referenceId,
      reservation.tenantId,
      0,
      reservation.dayScheduleType
    );
    cancelation.referenceNumber = reservation.referenceNumber;
    cancelation.reservationId = reservation.reservationId;
    return this.postReservation(cancelation);
  }
}
