import React, { FC, useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ProductItem } from "../model";
import AttachMediaDialog, {
  FILEUPLOAD_URL,
} from "../../react-shared/AttachMediaDialog";
import MediaRender from "../../react-shared/MediaRender";
import DeleteIcon from "@mui/icons-material/Delete";
import { NumberField } from "../../react-shared/NumberField";
import { ContentCopyOutlined } from "@mui/icons-material";
import copy from 'copy-to-clipboard';

type Props = {
  item?: ProductItem;
  onChange?: (item: ProductItem) => any;
  onDelete?: () => any;
};

export const EditProductItemForm: FC<Props> = ({ item, onChange, onDelete }) => {
  const [name, setName] = useState<string>(item?.name ?? "");
  const [description, setDescription] = useState<string>(
    item?.description ?? ""
  );
  const [price, setPrice] = useState<number>(item?.price ?? 0);
  const [defaultCheckedItem, setDefaultCheckedItem] = useState<boolean>(
    item?.defaultCheckedItem ?? false
  );
  const [prOrder, setPrOrder] = useState<boolean>(!!item?.prOrder);
  const [imageUrl, setImageUrl] = useState<string>(item?.imageUrl ?? "");
  const [enabled, setEnabled] = useState<boolean>(item?.enabled ?? true);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  useEffect(() => {
    onChange({
      id: item?.id,
      name,
      description,
      price,
      defaultCheckedItem,
      prOrder,
      imageUrl,
      enabled,
    });
  }, [
    name,
    description,
    price,
    defaultCheckedItem,
    prOrder,
    imageUrl,
    enabled,
  ]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Grid container sx={{ opacity: enabled ? 1 : 0.5 }}>
        {item?.id && <Grid
          item
          sx={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          sm={12}
          md={12}
        >
          <Tooltip title="Copy ID">
            <IconButton onClick={() => copy(item.id)}>
              <ContentCopyOutlined />
            </IconButton>
          </Tooltip>
        </Grid>}
        <Grid
          item
          sx={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          sm={12}
          md={4}
        >
          <TextField
            variant="standard"
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid
          item
          sx={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          sm={12}
          md={8}
        >
          <TextField
            variant="standard"
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid
          item
          sx={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          sm={12}
          md={4}
        >
          <NumberField
            label="Price"
            variant="standard"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value as any);
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          sm={12}
          md={8}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={prOrder}
                onChange={() => setPrOrder(!prOrder)}
              />
            }
            label="Pr Order"
          />
        </Grid>
        <Grid
          item
          sx={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          sm={12}
          md={4}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultCheckedItem}
                onChange={() => setDefaultCheckedItem(!defaultCheckedItem)}
              />
            }
            label="Default Checked"
          />
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={() => setEnabled(!enabled)}
                color="primary"
              />
            }
            label={"Enabled"}
          />
        </Grid>
        <Grid item sx={{ padding: "0.5em" }} sm={12} md={8}>
          {imageUrl && (
            <MediaRender
              src={`${FILEUPLOAD_URL}/skyland-booking/${imageUrl}`}
            />
          )}
          <br />
          <Button variant="contained" onClick={() => setMediaDialogOpen(true)}>
            Set Image
          </Button>
        </Grid>
        <AttachMediaDialog
          open={mediaDialogOpen}
          onClose={() => setMediaDialogOpen(false)}
          onSubmit={(mediaId, extension) => {
            setImageUrl(`${mediaId}${extension}`);
          }}
        />
      </Grid>
      <IconButton onClick={() => onDelete()}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
