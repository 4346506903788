import { Injectable } from '@angular/core';
import { WaiverForm } from './waiverform.model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WaiverConfirmation } from './waiver-confirmation.model';
import { Observable } from 'rxjs/Observable';
@Injectable({ providedIn: 'root' })
export class WaverFromService {

  private readonly waiverApi = environment.dependencies.waiver_api.url + '/api/wavercontracts';
  private readonly waiverTextApi = environment.dependencies.waiver_text_api.url + '/umbraco/api/waivertext/get';

  getFiltered(filter: string): any {
    return this.http.get(`${this.waiverApi}/filter?filterName=${filter}`);
  }


  constructor(private http: HttpClient) { }

  public save(waiver: WaiverForm): any {
    return this.http
      .put(`${this.waiverApi}/${waiver.id}`, waiver);
  }

  public confirmWaiver(waiverConfirmation: WaiverConfirmation) {
    return this.http
      .post(`${this.waiverApi}/waiver_confirm`, waiverConfirmation).map(x => {
        return x as WaiverConfirmation;
      });
  }

  public post(waiver: WaiverForm): any {
    return this.http
      .post(`${this.waiverApi}`, waiver);
  }

  get(): any {
    return this.http.get(this.waiverApi);
  }

  getOne(id: string): any {
    return this.http.get(`${this.waiverApi}/${id}`);
  }
  search(phone: string): Observable<Array<WaiverForm>> {
    return this.http.get(`${this.waiverApi}/search?phone=${phone}`)
      .map((data: Array<any>) => data.map(obj => obj as WaiverForm));
  }

  confirmWaiverExist(phone: string|string[]):Observable<any>{
    if(Array.isArray(phone)) {
      return this.http.get(`${this.waiverApi}/confirm`, {
        params: { phone }
      });
    }
    return this.http.get(`${this.waiverApi}/${phone}/confirm`);
  }

  getWaiverText() {
    return this.http.get(this.waiverTextApi, {
      headers:
        new HttpHeaders({ 'Content-Type': 'application/hal+json' })
    });
  }

}
