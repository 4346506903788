import React, { FC, useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
  FormControlLabel,
  Switch,
  MenuItem,
  Theme,
} from "@mui/material";
import { ActivitiesContext } from "../ActivitiesReactWrapper";
import { initActivityService } from "../activityService";
import { useNavigate, useParams } from "react-router-dom";
import MediaRender from "../../react-shared/MediaRender";
import AttachMediaDialog, {
  FILEUPLOAD_URL,
} from "../../react-shared/AttachMediaDialog";
import { Activity, ActivityType } from "../model";
import MultiselectOption from "../../react-shared/MulitselectOption";
import { Resource } from "../../resources-react/model";
import { initResourceService } from "../../resources-react/resourceService";
import { PriceInfo } from "../../time-scheduling/day-schedule.model";
import { EditPriceInfosFieldsGroup } from "./EditPriceInfosFieldsGroup";
import { TimeIntervalInformation } from "../../models/day-rule-time-interval.model";
import DayRuleTimeIntervalDefinitionDialog from "../../react-shared/DayRuleTimeIntervalDefinitionDialog";
import { NumberField } from "../../react-shared/NumberField";
import { Guid } from "../../system/guid";
import { createStyles, makeStyles } from "@mui/styles";
import { ActivityPriceModel } from "../../time-scheduling/booking-form-react/activity.model";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    cancel: {
      marginLeft:"2rem !important"
    }
  });
});


type Props = {};

export const OVERVIEW_TAG = "overview";
const intervalOptions = [
  { label: "15 min", value: 15 },
  { label: "30 min", value: 30 },
  { label: "1 h", value: 60 },
]

export const EditActivityForm: FC<Props> = () => {
  const { adalService, siteIdPrittyUrl, siteId } =
    useContext(ActivitiesContext);
  const [activityService] = useState(initActivityService(adalService));
  const [resourceService] = useState(initResourceService(adalService));
  const classes = useStyles();
  const navigate = useNavigate();

  const [activityLoaded, setActivityLoaded] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [prittyUrl, setPrittyUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [activityType, setActivityType] = useState<ActivityType>(
    ActivityType.Regular
  );
  const [priceModel,setPriceModel] = useState<ActivityPriceModel>(ActivityPriceModel.AccumulateSubActivityCosts);
  const [minGroupSize, setMinGroupSize] = useState<number>(0);
  const [tags, setTags] = useState<string[]>([]);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [requiredResourceIds, setRequiredResourceIds] = useState<string[]>([]);
  const [priceInfos, setPriceInfos] = useState<PriceInfo[]>([]);
  const [priceInfoExceptions, setPriceInfoExceptions] =
    useState<TimeIntervalInformation<PriceInfo[]>[]>();
  const [reminderEnabled, setReminderEnabled] = useState<boolean>(false);
  const [reminderDaysBefore, setReminderDaysBefore] = useState<number>(0);
  const [concurrentStarts, setConcurrentStarts] = useState<number>(0);
  const [minInterval, setMinInterval] = useState<number>(15);
  const [isBirthday, setIsBirthday] = useState<boolean>(false);
  const [bookableAsSubActivity, setBookableAsSubActivity] = useState<boolean>(true);
  const [defaultSubActivityId, setDefaultSubActivity] = useState<string>(Guid.empty().toString());

  const [dialogOpen, setDialogOpen] = useState(false);

  const [resources, setResources] = useState<Resource[]>([]);
  const resourceIds = resources.map((r) => r.id);

  const { id } = useParams();

  const isAdd = !id;

  const [activities, setActivities] = useState<Activity[]>([]);

  const fetchActivities = () => {
    if(!siteId) return;
    activityService.getActivities(siteId).then(activities => setActivities(activities.filter(x=>x.id !== id)));
  }

  useEffect(() => {
    siteId && id && fetchActivities();
  }, [siteId]);

  useEffect(() => {
    if (id) {
      activityService
        .getActivity(id)
        .then((activity) => {
          setName(activity.name);
          setDescription(activity.description);
          setPrittyUrl(activity.prittyUrl);
          setImageUrl(activity.imageUrl);
          setTags(activity.tags ?? []);
          setRequiredResourceIds(activity.requiredResourceIds ?? []);
          setActivityType(activity.activityType);
          setPriceInfos(activity.priceInfos ?? []);
          setPriceInfoExceptions(activity.priceInfosExceptions ?? []);
          setMinGroupSize(activity.minGroupSize ?? 0);
          setReminderEnabled(activity.reminderEnabled ?? false);
          setReminderDaysBefore(activity.reminderDaysBefore ?? 0);
          setMinInterval(activity.minInterval ?? 15);
          setConcurrentStarts(activity.concurrentStarts ?? 0);
          setIsBirthday(activity.isBirthday ?? false);
          setBookableAsSubActivity(activity.bookableAsSubActivity ?? true);
          setActivityLoaded(true);
          setDefaultSubActivity(activity.defaultSubActivityId);
          setPriceModel(activity.priceModel ?? ActivityPriceModel.AccumulateSubActivityCosts);
        })
        .catch(console.error);
        !activities && fetchActivities();
    }
  }, [id,siteId]);
  const activityTypes = [{value:ActivityType.Group,label:"Group"},{value:ActivityType.Regular,label:"Regular"}, {value:ActivityType.Break,label:"Break"},{value:ActivityType.PayPrResource,label:"Betal pr. resurs"}];
  const priceModelTypes = [{value:ActivityPriceModel.AccumulateSubActivityCosts,label:"Add sub activity cost"},{value:ActivityPriceModel.UseMainActivityCostOnly,label:"Main activity cost only"}];
  useEffect(() => {
    siteId &&
      resourceService
        .getResources(siteId)
        .then(setResources)
        .catch(console.error);
  }, [siteId]);

  const [alert, setAlert] = useState<{ open: boolean; success: boolean }>({
    open: false,
    success: false,
  });
  const setAlertOpen = (open: boolean) => {
    setAlert({
      ...alert,
      open,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const defaultSubActivityIdValue = defaultSubActivityId === Guid.empty().toString()? undefined:defaultSubActivityId;
    const action = isAdd
      ? activityService.addActivity({
          name,
          siteId,
          description,
          prittyUrl,
          imageUrl,
          activityType,
          tags,
          requiredResourceIds,
          priceInfos,
          minGroupSize,
          priceInfosExceptions: priceInfoExceptions,
          reminderEnabled,
          reminderDaysBefore,
          minInterval,
          concurrentStarts,
          isBirthday,
          bookableAsSubActivity,
          defaultSubActivityId: defaultSubActivityIdValue,
          priceModel,
        })
      : activityService.updateActivity({
          id,
          name,
          siteId,
          description,
          prittyUrl,
          imageUrl,
          activityType,
          tags,
          requiredResourceIds,
          priceInfos,
          minGroupSize,
          priceInfosExceptions: priceInfoExceptions,
          reminderEnabled,
          reminderDaysBefore,
          minInterval,
          concurrentStarts,
          isBirthday,
          bookableAsSubActivity,
          defaultSubActivityId:defaultSubActivityIdValue,
          priceModel
        });

    action
      .then(() => {
        setAlert({
          open: true,
          success: true,
        });
        navigate(`/resource/${siteIdPrittyUrl}/activities`);
      })
      .catch((err) => {
        setAlert({
          open: true,
          success: false,
        });
        console.error(err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ padding: "1em" }}>
        <Typography variant="h5">
          {isAdd ? "Add New" : "Edit"} Activity
        </Typography>
        <Grid container>
          <Grid item sm={12} md={6}>
            <TextField
              label="Name"
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="Description"
              variant="standard"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="PrittyUrl"
              variant="standard"
              value={prittyUrl}
              onChange={(e) => setPrittyUrl(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <NumberField
              label="Min Group Size"
              variant="standard"
              value={minGroupSize}
              onChange={(e) => {
                setMinGroupSize(e.target.value as any);
              }}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={tags.includes(OVERVIEW_TAG)}
                  onChange={(e) =>
                    e.target.checked
                      ? setTags((tags) => [...tags, OVERVIEW_TAG])
                      : setTags((tags) =>
                          tags.filter((t) => t !== OVERVIEW_TAG)
                        )
                  }
                  color="primary"
                />
              }
              label={"Visible in Overview"}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <TextField
              label="Activity type"
              variant="standard"
              value={activityType}
              onChange={(e) => setActivityType(parseInt(e.target.value))}
              select
              fullWidth
            >
              {activityTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <TextField
              label="Price model"
              variant="standard"
              value={priceModel}
              onChange={(e) => setPriceModel(parseInt(e.target.value))}
              select
              fullWidth
            >
              {priceModelTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={reminderEnabled}
                  onChange={(e) => setReminderEnabled(e.target.checked)}
                  color="primary"
                />
              }
              label={"Reminder Enabled"}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            {reminderEnabled &&
              <NumberField
                label="Reminder Days Before"
                variant="standard"
                value={reminderDaysBefore}
                onChange={(e) => {
                  setReminderDaysBefore(e.target.value as any);
                }}
              />
            }
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <TextField
              label="Min Interval"
              variant="standard"
              value={minInterval}
              onChange={(e) => setMinInterval(parseInt(e.target.value))}
              select
            >
              {intervalOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <NumberField
              label="Concurrent Starts"
              variant="standard"
              value={concurrentStarts}
              onChange={(e) => {
                setConcurrentStarts(e.target.value as any);
              }}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={isBirthday}
                  onChange={(e) => setIsBirthday(e.target.checked)}
                  color="primary"
                />
              }
              label={"Is Birthday"}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={bookableAsSubActivity}
                  onChange={(e) => setBookableAsSubActivity(e.target.checked)}
                  color="primary"
                />
              }
              label={"Bookable as Sub Activity"}
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={12}>
            <Typography variant="h3">
              Price Infos
            </Typography>
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={12}>
            {(isAdd || activityLoaded) && (
              <EditPriceInfosFieldsGroup
                value={priceInfos}
                onChange={setPriceInfos}
              />
            )}
          </Grid>
          <Grid item sm={12} sx={{ marginTop: "2em" }}>
            <Button onClick={() => setDialogOpen(true)} variant="contained" type="button">
              Price Info Exceptions
            </Button>
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={12}>
            <MultiselectOption
              values={requiredResourceIds}
              onChange={setRequiredResourceIds}
              items={resources.map((r) => ({ label: r.name, value: r.id }))}
              selectAllLabel={`All Resources`}
              renderSelectedValues={(values: any) =>
                values.length === resourceIds.length
                  ? `All Resources`
                  : values
                      .map(
                        (value: any) =>
                          resources[resourceIds.findIndex((v) => v === value)]
                            ?.name ?? ''
                      )
                      .join(", ")
              }
            />
          </Grid>
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={12}>
            {imageUrl && (
              <MediaRender
                src={`${FILEUPLOAD_URL}/skyland-booking/${imageUrl}`}
              />
            )}
            <br />
            <Button
              variant="contained"
              onClick={() => setMediaDialogOpen(true)}
            >
              Set Image
            </Button>
          </Grid>

          <AttachMediaDialog
            open={mediaDialogOpen}
            onClose={() => setMediaDialogOpen(false)}
            onSubmit={(mediaId, extension) => {
              // console.log(mediaId, extension);
              setImageUrl(`${mediaId}${extension}`);
            }}
          />
          {activityType && activityType == ActivityType.Group && 
          activities && defaultSubActivityId && 
          <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <TextField
                label="Default sub activity"
                variant="standard"
                value={defaultSubActivityId}
                onChange={(e) => setDefaultSubActivity(e.target.value)}
                select
                fullWidth
              >
                <MenuItem key={Guid.empty().toString()} value={Guid.empty().toString()}>
                  Ingen    
                </MenuItem>
                {activities.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>}
          <Grid item sm={12} sx={{ marginTop: "2em" }}>
            <Button variant="contained" type="submit">
              Save
            </Button>
            <Button variant="contained" onClick={() => navigate(`/resource/${siteIdPrittyUrl}/activities`)} className={classes.cancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
      {(isAdd || activityLoaded) && (
        <DayRuleTimeIntervalDefinitionDialog<PriceInfo[]>
          title="Price Info Exceptions"
          allItemsLabel="All Price Info Exceptions"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          handleSave={setPriceInfoExceptions}
          timeIntervalInformations={priceInfoExceptions ?? []}
          infoComponent={EditPriceInfosFieldsGroup}
          initialInfoValue={[...priceInfos.map(x=> ({...x} as PriceInfo))]}
          infoToString={infos => infos.map(i => i.viewValue).join(',')}
        />
      )}
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alert.success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {alert.success ? "Activity saved." : "Something went wrong."}
        </Alert>
      </Snackbar>
    </>
  );
};
