import * as React from "react";
import { TextField, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorMessage, Field } from "formik";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  shrink?: boolean;
  error?: any;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  variant?: "standard" | "outlined" | "filled";
  className?: any;
  steps?: number;
};

const FormikField = ({
  name,
  label,
  type = "text",
  required = false,
  shrink,
  error,
  disabled = false,
  multiline = false,
  rows = 1,
  autoFocus = false,
  variant = "standard",
  className,
  steps,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Field
        required={required}
        autoComplete="off"
        as={TextField}
        label={label}
        name={name}
        fullWidth
        type={type}
        error={Boolean(error)}
        helperText={<ErrorMessage name={name} />}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        autoFocus={autoFocus}
        variant={variant}
        className={className && className}
        InputLabelProps={{
          shrink: shrink,
          steps: steps,
        }}
      />
    </div>
  );
};

export default FormikField;
