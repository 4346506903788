import { Reservation, CapacityTimeSlot, ReservationInfo, ReservationsAgregate } from "./timeslot.model";

export class TimeSlotBookings {
    private reservationInfos: Array<ReservationInfo>;
    constructor(private reservations: Array<ReservationsAgregate>) {
        this.reservationInfos = this.mapReservationsInfos();
    }

    private mapReservationsInfos(): Array<ReservationInfo> {
        var reservationMap = this.reservations.map((x)=> new ReservationInfo(x));
        return Array.from(reservationMap.values());
    }

    public getReservations(): Array<ReservationInfo> {
        return this.reservationInfos;
    }
    public getUnRegistered(): Array<ReservationsAgregate>{
        return this.reservationInfos
            .filter(x=>!x.isCheckedin())
            .map(x=>x.reservation);
    }
}