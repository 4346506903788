<div>
    <button mat-menu-item *ngIf="user$ | async">
      {{ (user$ | async).adalUser.profile.name }}
    </button>
    <button mat-menu-item (click)="updateCache()">
      <span class="personColor">Update</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <span class="personColor">Log Out</span>
    </button>
    <button mat-menu-item (click)="login()">
      <span class="personColor">Log In</span>
    </button>
    <button mat-menu-item (click)="clear()">
      <span class="personColor">Clear site data</span>
    </button>

</div>
