import * as moment from 'moment';

export class ReservationOverview {
    date: moment.Moment;
    total: number;
    regularTotal: number;

    constructor() {
        this.date = moment();
    }
}
