<h1 mat-dialog-title>Endre bursdag</h1>
<div mat-dialog-content>
    <form [formGroup]="adminEditForm">
        <fieldset class="field_set">
          <legend>Allergi / spesialkost</legend>
          <p>Her oppgir du om noen bursdagsdeltakere har allergi eller krever spesialkost.</p>
          <mat-form-field class="specificwith-class">
            <textarea formControlName="allergi" class="tekstStyle" maxlength="150" matInput placeholder="Din beskjed (max 150 ord)" matTextareaAutosize
              matAutosizeMinRows="2" matAutosizeMaxRows="5"></textarea>
          </mat-form-field>
        </fieldset>
        
        <fieldset class="field_set">
          <legend>Valgte tillegg</legend>
          <mat-form-field >
              <mat-select placeholder="Velg tillegg" (selectionChange)="addExtra($event)" formControlName="extraToAdd">
                  <mat-optgroup *ngFor="let product of catalog$ | async" label="{{product.name}}">
                      <mat-option *ngFor="let item of product.items" [value]="item" >
                          {{item.name}} {{item.price}},- kr
                        </mat-option>  
                  </mat-optgroup>
              </mat-select>
            </mat-form-field>
          <div formArrayName="extras">
            <div *ngFor="let extra of extras.controls; let i=index" [formGroupName]="i">
                <p>
                    <span>
                      <strong>Navn:</strong>
                    </span>{{extra.value.description}} {{extra.value.price}},- kr
                  </p>
                  <button mat-icon-button fxFlex="5">
                      <mat-icon type="button" (click)="deleteExtra(i)">delete</mat-icon>
                  </button>
            </div>
          </div>
        </fieldset>

      </form>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button color="warn" (click)="onNoClick()" cdkFocusInitial>Avbryt</button>
  <button mat-raised-button color="primary" (click)="ok()">Lagre Endringer</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>