import React, { FC, useContext } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ResourcesList } from "./components/ResourcesList";
import { ResourcesContext } from "./ResourcesReactWrapper";

type Props = {};

export const ResourcesPage: FC<Props> = () => {
  const { siteIdPrittyUrl } = useContext(ResourcesContext);

  return (
    <>
      <Link
        style={{ textDecoration: "none", marginBottom: "1em" }}
        to={`/resource/${siteIdPrittyUrl}/edit-resource`}
      >
        <Button variant="contained">New Resource</Button>
      </Link>
      <ResourcesList />
    </>
  );
};
