import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdalService } from "adal-angular4";
import { environment } from "../../environments/environment";


@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
    
    constructor(private adalService: AdalService) {
        
      }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.adalService.userInfo.authenticated) {
            return true;
        } else {
            this.adalService.login();
            return false;
        }
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }
}