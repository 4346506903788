import { Injectable, EventEmitter, OnDestroy } from '@angular/core';

import { SubscriptionsLifespanHandler } from './subscriptions-lifespan-handler';
import { Observable, Subscription, Subject, BehaviorSubject, ConnectableObservable } from 'rxjs/Rx';

@Injectable()
export class ConfigurableMessageBus implements OnDestroy {
	

	private channels: Map<string,Channel> = new Map<string, Channel>();
	private subscriptionHandler: SubscriptionsLifespanHandler = new SubscriptionsLifespanHandler();
	
	public add<T>(channel: Channel) {
		if(this.hasChannel(channel.name)){
			channel.copyObservers(this.channels.get(channel.name));
		}
		this.channels.set(channel.name, channel);
	}

	public subscribeTo(channelName: string, callback): Subscription {
		this.createIfChannelNotExists(channelName);
		var subscription = this.channels.get(channelName).subscribe(callback);
		this.subscriptionHandler.add(subscription)
		return subscription;
	}

	private createIfChannelNotExists(channelName: string) {
		if (!this.channels.get(channelName)) {
			this.add(new Channel(channelName));
		}
	}

	public observable(channelName: string): ConnectableObservable<any>{
		this.createIfChannelNotExists(channelName);
		return this.channels.get(channelName).observable(this.subscriptionHandler);
	}

	public emitTo<T>(channelName: string, event: T) {
		this.channels.get(channelName).emit(event);
	}

	public clearAll() {
		for (const name in this.channels) {
			if (this.channels.hasOwnProperty(name)) {
				const channel = this.channels.get(name);
				channel.clearMessage();
			}
		}
	}

	public hasChannel(name:string): boolean {
		return !!(this.channels.get(name));
	}
	ngOnDestroy(): void {
		this.subscriptionHandler.unsubscribe();
	}
}

export class Channel{
	protected subject: Subject<any>;
	private _observable: ConnectableObservable<any>;
	private observers: Array<any> = new Array<any>();
	constructor(public name: string) {
		this.subject = new Subject<any>();
	}

	public observable(subscriptionHandler: SubscriptionsLifespanHandler): ConnectableObservable<any>{
		if(this._observable){
			return this._observable;
		}
		this._observable = this.subject.asObservable().publish();
		subscriptionHandler.add(this._observable.connect());
		return this._observable;
	}
	public subscribe(callback): Subscription {
		this.observers.push(callback);
		return this.subject.subscribe(callback);
	}

	public emit(event) {
		this.subject.next(event);
	}

	public clearMessage() {
		const self = this as any;

		if (self.lastMessage) {
			self.lastMessage = null;
		}
	}
	public copyObservers(channel:Channel){
		channel.observers.forEach(observer=>{
			this.subscribe(observer);
		});
	}
}

export class EchoChannel extends Channel{
	private lastMessage: any;

	constructor(name: string) {
		super(name);
		this.subject = new BehaviorSubject<any>({type:'No message received'});		
	}

	public subscribe(callback): Subscription {
		if (this.lastMessage) {
			callback(this.lastMessage);
		}
		return super.subscribe(callback);
	}

	public emit(event) {
		this.lastMessage = event;
		super.emit(event);
	}
}
