import { Guid } from "../system/guid";
import { Moment } from "moment";

export class Site{

    constructor(public readonly id: Guid,
        public readonly name: string,
        public readonly createdOn: Moment, 
        public readonly prittyUrl: string,
        public readonly tenantId: Guid,
        public readonly phoneNumber: string = ""){

    }

    public static deSerialize(x:any): Site{
        
        return new Site(x.id,x.name,x.createdOn,x.prittyUrl,x.tenantId,x.phoneNumber||"");
    }
    public toString():string {
        return `Name: ${this.name}`;
    }
}
