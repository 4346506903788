import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { Routes, RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "./material.module";
import { InfrastructureModule } from "./infrastructure/infrastructure.module";

import { AppComponent } from "./app.component";
import { AboutComponent } from "./about/about.component";
import { MessageCenterComponent } from "./message-center/message-center.component";
import { ComponentLayoutComponent } from "./component-layout/component-layout.component";
import { WaverFormComponent } from "./waiver/waver-form/waver-form.component";
import { WaiverListComponent } from "./waiver/waiver-list/waiver-list.component";
import { LoginComponent } from "./login/login.component";
import { LoginRouteGuard } from "./login-route-guard";
import { WaverPhotoComponent } from "./waiver/waver-photo/waver-photo.component";
import { WaiverSignatureFormComponent } from "./waiver/waiver-signature-form/waiver-signature-form.component";
import { GbookingComponent } from "./bookingbirthday/gbooking/gbooking.component";
import { BirthdaybookingOverviewComponent } from "./bookingbirthday/birthdaybooking-overview/birthdaybooking-overview.component";
// import { BirthdaybookingdetailsComponent } from './bookingbirthday/birthdaybookingdetails/birthdaybookingdetails.component';
import { BookingoverviewComponent } from "./booking/bookingoverview/bookingoverview.component";
import { GroupBookingDetailsComponent } from "./bookingbirthday/group-booking-details/group-booking-details.component";
import { ReservationsComponent } from "./time-scheduling/reservations/reservations.component";
import { RegisterbokkingComponent } from "./booking/registerbokking/registerbokking.component";
import { MenuComponent } from "./menu/menu.component";
import { AuthenticationGuard } from "./system/AuthenticationGuard";
import { DaySchedulesComponent } from "./time-scheduling/day-schedules/day-schedules.component";
import { ResoursePathComponent } from "./resourse-path/resourse-path.component";
import { DaySchedulComponent } from "./time-scheduling/day-schedul/day-schedul.component";
import { BirthdaybookingdetailsComponent } from "./bookingbirthday/birthdaybookingdetails/birthdaybookingdetails.component";
import { BirthdaybookingPastComponent } from "./bookingbirthday/birthdaybooking-past/birthdaybooking-past.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
// tslint:disable-next-line:max-line-length
import { BirthdayBookingOverviewTableComponent } from "./bookingbirthday/birthday-booking-overview-table/birthday-booking-overview-table.component";
import { BookingOverviewTableComponent } from "./booking/booking-overview-table/booking-overview-table.component";
import { UnauthpageComponent } from "./unauthpage/unauthpage.component";
import { ProductsComponent } from "./products-react/ProductsReactWrapper";
import { ActivitiesComponent } from "./activities-react/ActivitiesReactWrapper";
import { ResourcesComponent } from "./resources-react/ResourcesReactWrapper";
import { WaiverRulesComponent } from "./waiver-rules-react/WaiverRulesReactWrapper";

export const APP_ROUTES: Routes = [
  { path: "fix", component: UnauthpageComponent },
  {
    path: "",
    canActivate: [AuthenticationGuard],
    component: ComponentLayoutComponent,
    children: [
      { path: "", component: MessageCenterComponent, outlet: "messagecenter" },

      { path: "about", component: AboutComponent },
      { path: "CreateW", component: WaverFormComponent },
      { path: "sjekkinn/:refId", component: RegisterbokkingComponent },
      { path: "egenerklering/:id", component: WaverFormComponent },
      { path: "photo", component: WaverPhotoComponent },
      { path: "egenerklering/:filter", component: WaiverListComponent },
      { path: "egenerklering", component: WaiverListComponent },
      { path: "waiversign", component: WaiverSignatureFormComponent },
      { path: "bursdagoversikt", component: BirthdaybookingOverviewComponent },
      { path: "bursdag/:id", component: GroupBookingDetailsComponent },
      { path: "reservations", component: ReservationsComponent },
      { path: "reservations/:phone", component: ReservationsComponent },
      { path: "products", component: ProductsComponent },
      { path: "edit-product", component: ProductsComponent },
      { path: "edit-product/:productId", component: ProductsComponent },
      { path: "waiver-rules", component: WaiverRulesComponent },
      { path: "edit-waiver-rules", component: WaiverRulesComponent },
      { path: "edit-waiver-rules/:waiverRulesId", component: WaiverRulesComponent },
      {
        path: "resource/:refId",
        component: ResoursePathComponent, // This component MUST be her to keep track of resource. Do not remove it!!!!!
        canActivate: [AuthenticationGuard],
        children: [
          { path: "activities", component: ActivitiesComponent },
          { path: "edit-activity", component: ActivitiesComponent },
          { path: "edit-activity/:activityId", component: ActivitiesComponent },
          { path: "resources", component: ResourcesComponent },
          { path: "edit-resource", component: ResourcesComponent },
          { path: "edit-resource/:resourceId", component: ResourcesComponent },
          {
            path: "",
            pathMatch: "full",
            redirectTo: "bursdagoversikt",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "schedules",
            canActivate: [AuthenticationGuard],
            component: DaySchedulesComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "schedules/:type",
            canActivate: [AuthenticationGuard],
            component: DaySchedulesComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "schedule/:scheduleId",
            canActivate: [AuthenticationGuard],
            component: DaySchedulComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "schedule/new/:type",
            canActivate: [AuthenticationGuard],
            component: DaySchedulComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "schedule/copy/:scheduleId",
            canActivate: [AuthenticationGuard],
            component: DaySchedulComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "sjekkinn",
            canActivate: [AuthenticationGuard],
            component: RegisterbokkingComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "bursdag",
            canActivate: [AuthenticationGuard],
            component: BirthdayBookingOverviewTableComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "booking",
            canActivate: [AuthenticationGuard],
            component: BookingOverviewTableComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "bursdagoversikt",
            canActivate: [AuthenticationGuard],
            component: BirthdaybookingOverviewComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "bursdagoversikt/past",
            canActivate: [AuthenticationGuard],
            component: BirthdaybookingPastComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "dagsoversikt",
            canActivate: [AuthenticationGuard],
            component: BookingoverviewComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "bursdagdetails/:day",
            canActivate: [AuthenticationGuard],
            component: BirthdaybookingdetailsComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
          {
            path: "bursdag/:id",
            canActivate: [AuthenticationGuard],
            component: GroupBookingDetailsComponent,
            children: [
              {
                path: "",
                component: MessageCenterComponent,
                outlet: "messagecenter",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
  },

  // {
  //   path: 'dialog',
  //   canActivate: [LoginRouteGuard],
  //   component: ComponentLayoutComponent,
  //   children: [
  //     { path: '', component: DialogOverviewComponent},
  //     { path: '', component: MessageCenterComponent, outlet: 'messagecenter' }
  //   ],
  // },
];
