<p>{{date | date:  'dd.MM.yyyy'}}</p>
<div class="main-box-div" fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">

  <div fxFlex="20%" class="label-items">
    <div class="label-reservation">Starter</div>
    <div *ngFor="let reserv of Reservationlabel">
      <div class="label-reservation">{{reserv.label}}</div>
    </div>
  </div>
  <div fxFlex="80%" class="resevation-items">
    <div fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center" *ngFor="let weekday of daySchedule$| async" >

      <div fxFlex class="bestillineinfo" *ngFor="let reservation of weekday.daySchedules">

        <div class="label-item">kL. {{reservation.time}}</div>
        <div  class="label-item"><span *ngFor="let c of reservation.children">{{c.firstname}} {{c.lastname}}  født: {{c.birth | date : 'dd.MM.yyyy'}} //  </span></div>
        <div class="label-item">{{reservation.count}}</div>
        <div class="label-item" ><span *ngFor="let extra of reservation.extras">{{extra.description}},</span></div>

        <div class="label-item">{{reservation.msg}}</div>
        <div class="label-item">
          <i class="material-icons">
            phone
          </i>{{reservation.tlf}}
          <i class="material-icons"></i>
          <a href="mailto:{{reservation.email}}">{{reservation.email}}</a>
        </div>
      </div>

    </div>


  </div>

</div>

