import React, { FC } from "react";
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

type Props = {
  label?: JSX.Element | string;
  defaultValueLabel?: string;
  value?: string|number;
  onChange?: (v: string|number) => any;
  options: { label: string | number; value: string | number }[];
};

export const SelectInput: FC<Props> = ({
  label,
  defaultValueLabel = "Select",
  options,
  value,
  onChange,
}) => {

  return (
    <div className={""}>
      <label>{label}</label>
      <Select
        variant="standard"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        className={`${""} ${""}`}
        displayEmpty={true}
        disableUnderline
        IconComponent={KeyboardArrowDownOutlinedIcon}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
      >
        <MenuItem value="">
          <em>{defaultValueLabel}</em>
        </MenuItem>
        {options.map((o, i: number) => (
          <MenuItem key={`${i}${o.value}${o.label}`} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
