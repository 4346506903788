
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { TimeScheduleService } from './time-schedule.service';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmbookingComponent } from './confirmbooking/confirmbooking.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BookingFormService } from './booking-form.service';
import { SimpleBookingComponent } from './simple-booking/simple-booking.component';
import { ReservationsComponent, DialogDeleteBookingComponent } from './reservations/reservations.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { BookableResourceService } from './bookable-resource.service';
import { RouterModule } from '@angular/router';
import { DaySchedulesComponent } from './day-schedules/day-schedules.component';
import { DaySchedulComponent } from './day-schedul/day-schedul.component';
import { OpenHourFormDialogComponent } from './day-schedul/open-hour-form.dialog';
import { VrBookingComponent } from "./vr-booking/vr-booking.component";
import { BookingFormReactComponent } from './booking-form-react/BookingFormReactWrapper';
@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    MatIconModule,
  ],
  entryComponents: [
    ReservationsComponent,
    DialogDeleteBookingComponent,
    OpenHourFormDialogComponent
  ],
  declarations: [
    BookingFormComponent,
    BookingFormReactComponent,
    ConfirmbookingComponent,
    SimpleBookingComponent,
	  VrBookingComponent,
    ReservationsComponent,
    DialogDeleteBookingComponent,
    DaySchedulesComponent,
    DaySchedulComponent,
    OpenHourFormDialogComponent
  ],
  providers: [TimeScheduleService, BookingFormService, BookableResourceService],
  exports: [
    BookingFormComponent,
    BookingFormReactComponent,
    ConfirmbookingComponent
  ]
})
export class TimeSchedulingModule { }
