import React from 'react';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { WaiverRules } from '../model';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import copy from 'copy-to-clipboard';
import { ConfirmationDialog } from '../../react-shared/ConfirmationDialog';
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  waiverRules: WaiverRules;
  onDelete?: (id: string) => any;
}

const languageLabels = {
  no: "Norsk",
  en: "English",
}

export const WaiverRulesCard: React.FC<Props> = ({ waiverRules, onDelete = () => {} }) => {
  const nav = useNavigate();

  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">{languageLabels[waiverRules.language] ?? "Unknown Language"}</Typography>
        </div>
        <div>
          <Tooltip title="Copy ID">
            <IconButton onClick={() => copy(waiverRules.id)}>
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => nav(`/edit-waiver-rules/${waiverRules.id}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <ConfirmationDialog
            dialogOpener={(
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            dialogTitle={`Are you sure that you want to delete ${waiverRules.language} waiver rules?`}
            dialogContent={`By doing this you are removing it from the system. Be aware of the fact that this action can not be undone.`}
            onConfirm={() => {
              onDelete(waiverRules.id);
            }}
          />
        </div>
      </div>
    </Paper>
  );
}