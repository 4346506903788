import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ServiceBusService } from '../system/service-bus.service';
import { Chanels } from '../chanels';
import { BookableResource } from '../time-scheduling/bookable-resource';
import { TimeScheduleService } from '../time-scheduling/time-schedule.service';
import { Reservation } from '../time-scheduling/timeslot.model';
import { ReservationOverview } from '../models';
import { GroupReservation } from '../time-scheduling/group/GroupReservation';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  private currentResource: BookableResource;
  statsMonths: ReservationOverview[] = [];

  constructor(private bus: ServiceBusService,
    private timeScheduleService: TimeScheduleService) {
  }

  ngOnInit() {
    this.bus.subscribe(Chanels.RESOURCE, (r) => {
      if (!this.currentResource || (r && this.currentResource.id !== r.id)) {
        this.currentResource = r as BookableResource;
        this.calculateBookingNumbers();
      }
    });
  }

  private calculateBookingNumbers() {
    this.statsMonths = [];
    if (!this.currentResource) {
      return;
    }
    let result = new Map<string, number>();
    const endOfCurrentMonth = moment().endOf('M');
    const dateFormat = 'MM.Y';
    const currentPeriodKey = endOfCurrentMonth.format(dateFormat);
    const lastPeriodKey = moment(endOfCurrentMonth).subtract(1, 'year').format(dateFormat);
    result.set(currentPeriodKey, 0);
    result.set(lastPeriodKey, 0);

    this.timeScheduleService.getPastRegularReservations(endOfCurrentMonth, this.currentResource.id)
    .subscribe((reses: Reservation[]) => {
      result = reses.reduce((_stats: Map<string, number>, _currentRes: Reservation) => {
        const key = moment(_currentRes.startTime).format(dateFormat);
        const count = _stats.get(key) || 0;
        _stats.set(key, count + 1);
        return _stats;
      }, result);

      result.forEach((value, key) => {
        const overview = new ReservationOverview();
        overview.date = moment(key, dateFormat).startOf('M');
        overview.regularTotal = value;
        this.timeScheduleService.getGroupReservations(overview.date, this.currentResource.id)
        .subscribe((grReses: GroupReservation[]) => {
          overview.total = grReses.filter(grRes => {
            const resDate = moment(grRes.reservationDate);
            return (resDate.month() === overview.date.month()) && (resDate.year() === overview.date.year());
          }).length;
        });
        this.statsMonths.push(overview);
      });
    });
  }

}
