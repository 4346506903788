import React, { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export interface SelectItem {
  label: string;
  value: string|number;
}

type RenderValuesFunction = (values: string[]|number[]) => string;

type Props = {
  items: SelectItem[];
  values?: string[]|number[];
  onChange?: any;
  renderSelectedValues?: RenderValuesFunction;
  selectAllLabel?: string;
};

const MultiselectOption: FC<Props> = ({ items, values, onChange, renderSelectedValues=(values: string[]|number[]) => values.join(", "), selectAllLabel="Select All" }) => {
  const isAllSelected = items.length > 0 && values.length === items.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      onChange(isAllSelected ? [] : items.map(item => item.value));
      return;
    }
    onChange(value);
  };

  return (
      <Select
        labelId="mutiple-select-label"
        variant="standard"
        multiple
        value={values}
        onChange={handleChange}
        renderValue={renderSelectedValues}
      >
        <MenuItem
          value="all"
        >
          <ListItemIcon>
            <Checkbox
              color={'primary'}
              checked={isAllSelected}
              indeterminate={
                values.length > 0 && values.length < items.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={selectAllLabel}
          />
        </MenuItem>
        {items.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            <ListItemIcon>
              <Checkbox color={'primary'} checked={values.findIndex((v: string|number) => v === item.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
  );
}

export default MultiselectOption;
