import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { WaiverForm } from '../waiverform.model';
import { Person } from '../Person.model';
import { NgForm } from '@angular/forms';
import { WaverFromService } from '../waver-from.service';
import { Guid } from '../../system/guid';
import { UplaodService } from '../../uplaod.service';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';



@Component({
  selector: '[app-waver-form]',
  templateUrl: './waver-form.component.html',
  styleUrls: ['./waver-form.component.scss']
})
export class WaverFormComponent implements OnInit {

  private uploading: boolean = false;
  isShow: boolean = false;
  btaddChild: string = "pluss Barn";
  newChild: Person;
  @Input("waiver")
  public newWaiver: WaiverForm;
  public fileErrorMessage: string = null;
  public showForm: boolean = true;
  public fileInfo: string = null;
  public message: string = "";
  public isMobileOk = false;
  private id: string = null;
  private sub: any;
  public imageUrl:string = null;

  private readonly waiverApi = environment.dependencies.waiver_api.url + "/api/wavercontracts";
  public focusChildrenEventEmiter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public focusPhone: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("fileInput", { static: false }) fileInput;

  constructor(private service: WaverFromService, private uploadService: UplaodService, private router: Router, private route: ActivatedRoute) {
    // this.isMobileOk = true;
    // this.fileInfo = "jjj";
  }

  private initChild() {
    this.newChild = new Person("", "", moment());
  }

  private initWaiver() {

    this.newWaiver = {
      id: Guid.newGuid().toString(),
      contactPerson: { firstname: "", lastname: "", birth: moment() },
      email: "",
      mobile: "",
      signingdate: null,
      children: [],
      advertising: false,
      tenantId: environment.tenantId,
      referenceId: Guid.empty().toString()
    };
    this.initChild();
    this.fileInfo = null;
    this.showForm = false;
    this.fileErrorMessage = null;
    this.isShow = false;
    this.isMobileOk = false
    this.message = "";
    this.imageUrl = null;

  }

  checkMobile(event) {
    if (event.keyCode == 13) {
      this.service.search(this.newWaiver.mobile).subscribe(data => {
        this.isMobileOk = data['length'] == 0;
        if (!this.isMobileOk) {
          this.message = "Telefon nummer eksisterer";
        }
        else {
          this.message = "";
        }
      });
    }
  }

  ngOnInit() {
    this.isShow = false;
    this.btaddChild = "pluss Barn";
    this.sub = this.route.params.subscribe(params => {
      if (!params['id']) return;
      this.id = params['id'];
      this.service.getOne(this.id).subscribe(waiver => {
        this.newWaiver = waiver;
        this.showForm = true;
        this.isMobileOk = true;
        this.imageUrl = this.getImageUrl();
      });
    });
    this.initWaiver();
    setTimeout(() => {
      this.focusPhone.emit(true);
    });

  }


  deletefromlist(child) {
    this.newWaiver.children.splice(this.newWaiver.children.indexOf(child), 1);
  }

  showaddChild() {
    this.isShow = !this.isShow;
    this.btaddChild = this.isShow ? "Hide barn" : "pluss Barn";
  }

  SaveWaiver(empForm: NgForm): void {
    if (!this.isMobileOk) return;
    this.newWaiver.signingdate = moment(this.newWaiver.signingdate);
    this.newWaiver.signingdate = this.newWaiver.signingdate.minute(this.newWaiver.signingdate.utcOffset())
    this.service.save(this.newWaiver)
      .catch((err) => {
        let details = err.json();
        console.error(details);
        return Observable.throw(err);
      }).subscribe((result) => {
        this.initWaiver();
        if (this.id) {
          this.router.navigateByUrl("/egenerklering/no_mobile");
        }
      });
  }

  addChildToList() {
    //this.newWaiv.Children.push();
    this.newWaiver.children.push(this.newChild);
    this.initChild();
    this.focusChildrenEventEmiter.emit(true);
  }

  addFile(): void {
    this.fileErrorMessage = null;
    this.uploading = true;
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileName = this.newWaiver.id;
      let fileToUpload = fi.files[0];
      this.uploadService
        .upload(fileName, fileToUpload)
        .catch((err) => {
          this.fileErrorMessage = "Det oppstod en feil under filopplasting. Prøv igjen!"
          return Observable.throw(err);
        })
        .finally(() => {
          this.uploading = false;
        })
        .subscribe(res => {
          this.fileInfo = `Fil med id ${this.newWaiver.id} er lastet opp`;
          this.showForm = true;
          this.imageUrl = this.getImageUrl();
        });
    }
  }

  getImageUrl() {
    return `${this.waiverApi}/upload/${this.newWaiver.id}`;
  }
  isUploading() {
    return this.uploading;
  }
}
