import * as moment from "moment";
import { CalendarTool } from "../system/calendartool";
import { FormBuilder, Validators } from "@angular/forms";
 
export enum DayScheduleTypes {
  Regular = 0,
  Birthday = 1,
  VR = 3,
}
export class DaySchedule {
  public static readonly DayScheduleTypesText: Array<string> = [
    "Vanlig",
    "Bursdag",
    "VR",
  ];

  public get maxCapacity(): number {
    return this.capacity;
  }

  public get weekDays(): Array<number> {
    return this.days;
  }
  public getSortSize(): number {
    return (this.ValidFrom ? 1 : 0) + (this.ValidTo ? 1 : 0);
  }
  private originalPriceInfos: PriceInfo[] = [];
  constructor(
    public id: string,
    private days: Array<number>,
    private startTime: any,
    private endTime: any,
    private capacity: number,
    public readonly referenceId: string,
    public readonly tenantId: string,
    public interval: number = 15,
    public priceInfos: Array<PriceInfo> = new Array<PriceInfo>(),
    public scheduleType: DayScheduleTypes = DayScheduleTypes.Regular,
    public concurrentReservations: number = 0,
    public concurrentStarts: number = 0,
    public ValidFrom: moment.Moment|null = null,
    public ValidTo: moment.Moment|null = null
  ) {
    this.originalPriceInfos = [...this.priceInfos];
  }
  public static deSerialize(day: any) {
    var from = day.validFrom
      ? CalendarTool.justDate(moment(day.validFrom))
      : null;
    var to = day.validTo ? CalendarTool.justDate(moment(day.validTo)) : null;
    return new DaySchedule(
      day.id,
      day.days,
      day.startTime,
      day.endTime,
      +day.capacity,
      day.referenceId,
      day.tenantId,
      day.interval,
      day.priceInfos? day.priceInfos.map(PriceInfo.deSerialize):day.Info?day.Info.map(PriceInfo.deSerialize):[],
      day.scheduleType,
      day.concurrentReservations,
      day.concurrentStarts,
      from,
      to,
    );
  }
  public useIncludeVr(include: boolean) {
    this.priceInfos = this.originalPriceInfos.filter(
      (x) => (include && x.includeVr) || (!include && !x.includeVr)
    );
  }
  public start(date: moment.Moment): moment.Moment {
    const newDate = CalendarTool.justDate(date);
    return newDate.set(this.startTime);
  }

  public end(date: moment.Moment): moment.Moment {
    const newDate = CalendarTool.justDate(date);
    return newDate.set(this.endTime);
  }

  public forEachTimeSlot(
    date: moment.Moment,
    requestedDuration: number = 60,
    callback: (time: moment.Moment, interval: number) => any
  ): any {
    var start = this.start(date),
      end = this.end(date);
    var current = start;
    while (current.clone().add(requestedDuration, "m").isSameOrBefore(end)) {
      // TODO HANDLE THE MIN TIME SLOT RESOLUTION
      callback(current, this.interval);
      current = current.clone().add(this.interval, "m");
    }
  }

  public validDates(): Array<moment.Moment> {
    if(this.ValidFrom === null || this.ValidTo === null) return [];
    var validFrom = this.ValidFrom || moment().subtract(1, "day"); //CalenderTool.justDate(moment().utc().subtract(1,'day'));
    var validDaysCount = this.ValidTo.diff(validFrom, "days", true) + 1;
    if(validDaysCount < 0) {
      console.log("Debug");
    }
    const ranged = Array.from(Array(Math.ceil(validDaysCount)).keys());
    return ranged
      .map((days) => validFrom.clone().add(days, "days"))
      .filter((date) => this.days.indexOf(date.weekday()) >= 0 && date.isSameOrBefore(this.ValidTo));
  }
  getForm(fb: FormBuilder): import("@angular/forms").FormGroup {
    return fb.group({
      id: [this.id, Validators.required],
      tenantId: [this.tenantId, Validators.required],
      days: fb.control(this.days, Validators.required),
      startTime: fb.group({
        hour: [this.startTime.hour, Validators.required],
        minute: [this.startTime.minute, Validators.required],
      }),
      endTime: fb.group({
        hour: [this.endTime.hour, Validators.required],
        minute: [this.endTime.minute, Validators.required],
      }),
      capacity: [
        this.capacity,
        [Validators.required, Validators.pattern("^[0-9]+$")],
      ],
      referenceId: [this.referenceId, Validators.required], // Set fra url
      interval: [this.interval, Validators.required], // Set til 30
      priceInfos: fb.array(
        this.priceInfos.map((x) =>
          fb.group({
            duration: [x.duration, Validators.required],
            viewValue: [x.viewValue, Validators.required],
            price: [
              x.price,
              [Validators.required, Validators.pattern("^[0-9]+$")],
            ],
            campaign: [x.campaign],
          })
        )
      ), //
      scheduleType: [this.scheduleType, Validators.required], // Set fra url?
      concurrentReservations: [
        this.concurrentReservations,
        Validators.required,
      ], // Set i forhold til valgt type
      validFrom: [this.ValidFrom],
      validTo: [this.ValidTo],
    });
  }
  private Infinite: moment.Moment = moment(1e15);

  public getDays(): Array<string> {
    return this.days.map((d) => moment().weekday(d).format("dddd"));
  }
  public toString(): string {
    var display = `${this.timeOfDayString(
      this.startTime
    )} - ${this.timeOfDayString(this.endTime)}`;
    if (this.ValidFrom) {
      display += ` Fra ${this.ValidFrom.format("DD.MM.YYYY")}`;
    }
    if (this.ValidTo) {
      display += ` Til ${this.ValidTo.format("DD.MM.YYYY")}`;
    }
    return display;
  }
  public isValidOn(currentDate: moment.Moment): boolean {
    return (
      currentDate.isSame(this.ValidFrom) ||
      currentDate.isBetween(
        this.ValidFrom || moment().utc().subtract(1, "day"),
        this.ValidTo || this.Infinite
      )
    );
  }
  private timeOfDayString(t: any): string {
    return `${t.hour}:${this.padZeros(t.minute)}`;
  }
  private padZeros(text: any): string {
    return ("00" + text).slice(-2);
  }
}

export class PriceInfo {
  constructor(
    public duration: number,
    public viewValue: string,
    public price: number,
    public campaign: string = "",
    public includeVr: boolean = true,
    public subActivityPrice: boolean = false,
  ) {}

  public static deSerialize(json: any) {
    return new PriceInfo(
      json.duration,
      json.viewValue,
      json.price,
      json.campaign,
      json.includeVr,
      json.subActivityPrice,
    );
  }
}
