import { AdalService } from "adal-angular4";
import axios from "axios";
import { environment } from "../../environments/environment";
import { setAxiosInterceptors } from "../react-shared/axios-utils";
import { AddActivity, Activity } from "./model";

export type ActivityService = {
  getActivities: (siteId?: string) => Promise<Activity[]>;
  getActivity: (activityId: string) => Promise<Activity>;
  addActivity: (activity: AddActivity) => Promise<Activity>;
  updateActivity: (activity: Activity) => Promise<Activity>;
  deleteActivity: (activityId: string) => Promise<Activity>;
}

export const initActivityService = (adalService: AdalService): ActivityService => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = environment.dependencies.activities_api.url;
  setAxiosInterceptors(axiosInstance, adalService);

  const getActivities = async (siteId?: string): Promise<Activity[]> => {
    const params = {};
    if(siteId) params['siteId'] = siteId;
    return (await axiosInstance.get(`/api/activities`, { params })).data;
  }

  const getActivity = async (activityId: string): Promise<Activity> => {
    return (await axiosInstance.get(`/api/activities/${activityId}`)).data;
  }

  const addActivity = async (activity: AddActivity): Promise<Activity> => {
    return (await axiosInstance.post(`/api/activities`, activity)).data;
  }

  const updateActivity = async (activity: Activity): Promise<Activity> => {
    return (await axiosInstance.put(`/api/activities/${activity.id}`, activity)).data;
  }

  const deleteActivity = async (activityId: string): Promise<Activity> => {
    return (await axiosInstance.delete(`/api/activities/${activityId}`)).data;
  }

  return {
    getActivities,
    getActivity,
    addActivity,
    updateActivity,
    deleteActivity,
  };
}