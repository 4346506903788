import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { TimeScheduleService } from "./time-schedule.service";
import { TimeSlot, Reservation } from "./timeslot.model";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/of";
import { Schedule } from "./schedule.model";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class BookingFormService {
  clearAll(): any {
    //this.reservation = null;
    this.reservationForm = null;
    this.reservationUserInfoForm = null;
  }
  private reservationForm: FormGroup;
  private reservationUserInfoForm: FormGroup;
  private reservation: Reservation;
  private static instCount: number = 0;
  // private weekSchedule: Map<string, DaySchedule> = new Map<string, DaySchedule>();
  // private monthSchedule: Map<string,DateSchedule>;
  reservationPrice(): any {
    var duration = this.reservationForm.controls.duration.value;
    var reservation = this.createReservation();
    if (!reservation) return 0;
    return reservation.count * duration.price;
  }

  groupReservationPrice(): any {
    var duration = this.reservationForm.controls.duration.value;
    var reservation = this.createReservation();
    if (!reservation) return 0;
    const count = reservation.count >= 10 ? reservation.count : 10;
    return count * duration.price;
  }

  constructor(
    private timeScheduleService: TimeScheduleService,
    private formBuilder: FormBuilder
  ) {
    BookingFormService.instCount++;
  }
  private reservationForm$: BehaviorSubject<FormGroup> = new BehaviorSubject(
    null
  );
  private set resForm(r: FormGroup) {
    this.reservationForm = r;
    this.reservationForm$.next(this.reservationForm);
  }
  getReservationForm(): Observable<FormGroup> {
    if (!this.reservationForm) this.createReservationForm();

    return this.reservationForm$;
  }
  getReservationUserInfoForm(): FormGroup {
    if (!this.reservationUserInfoForm) this.createUserInfoForm();
    return this.reservationUserInfoForm;
  }

  private createReservationForm(defaults: any = {}) {
    this.reservation = null;
    this.resForm = this.formBuilder.group({
      ignoreAvailabilityCheck: [false],
      day: [defaults.day ? defaults.day : "", Validators.required],
      duration: [
        { value: defaults.duration ? defaults.duration : "", disabled: true },
        Validators.required,
      ],
      count: [defaults.count ? defaults.count : "", Validators.required],
      timeSlot: [{ value: null, disabled: true }, Validators.required],
    });
  }
  private createUserInfoForm() {
    this.reservationUserInfoForm = this.formBuilder.group({
      mobile: ["", Validators.required],
    });
    return this.reservationUserInfoForm;
  }

  public makeReservation(monthSchedule: Schedule): Observable<Reservation> {
    //var reservation:Reservation = this.getReservation();
    var reservation: Reservation = monthSchedule.getReservation(
      this.reservationForm
    );

    reservation.mobile = this.reservationUserInfoForm.controls.mobile.value;

    return this.timeScheduleService
      .makeReservation(reservation, monthSchedule)
      .map((reservation: Reservation) => {
        this.reservation = reservation;
        return reservation;
      });
  }

  public makeGroupeReservation(
    groupReservationReq: any,
    monthSchedule: Schedule
  ) {
    return this.timeScheduleService
      .makeGroupeReservation(groupReservationReq, monthSchedule)
      .map((reservation: Reservation) => {
        this.reservation = reservation;
        return reservation;
      });
  }
  // This should not be used to send reservations. Only for calculations!
  // This is missing TennantID and can not be saved.
  public getReservation(monthSchedule: Schedule): Reservation {
    if (!monthSchedule)
      throw { error: 402, msg: "Error must have schedule to make reservation" };
    return monthSchedule.getReservation(this.reservationForm);
  }
  public getStoredReservation(): Reservation {
    return this.reservation;
  }

  public createReservation(): Reservation {
    if (!this.reservationForm.valid) return null;
    var timeSlot: TimeSlot = this.reservationForm.controls.timeSlot.value;
    var price = this.reservationForm.controls.duration.value.price;

    return new Reservation(
      timeSlot.startTime,
      timeSlot.duration,
      this.reservationForm.controls.count.value,
      "",
      timeSlot.referenceId,
      "",
      price
    );
  }
  patchValue(reservation: Reservation) {
    this.getReservationForm().subscribe((form) => {
      form.patchValue(
        {
          day: reservation.startTime,
          count: reservation.count,
        },
        {
          onlySelf: false,
          emitEvent: true,
        }
      );
    });
  }
  patchReservationFrom(patch: any) {
    this.getReservationForm().subscribe((form) => {
      form.patchValue(patch, {
        onlySelf: false,
        emitEvent: true,
      });
    });
  }
}
