import { Component, OnInit, OnDestroy } from "@angular/core";
import { AdalService } from "adal-angular4";
import { environment } from "../environments/environment";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as jwt_decode from "jwt-decode";
import { ServiceBusService } from "./system/service-bus.service";
import { Chanels } from "./chanels";
import { BUser } from "./system/buser";
import { LayoutService } from "./services/layout.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "app";

  public user: adal.User;

  constructor(
    private adalService: AdalService,
    private matIconRegistry: MatIconRegistry,
    private bus: ServiceBusService,
    private domSanitizer: DomSanitizer,
    private layoutService: LayoutService
  ) {
    adalService.init(environment.config);
    this.matIconRegistry.addSvgIcon(
      "copy",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/copy.svg")
    );
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  ngOnInit() {
    this.adalService.handleWindowCallback();
    this.adalService
      .acquireToken(
        this.adalService.getResourceForEndpoint(
          environment.dependencies.time_scheduling_api.url + "/api/"
        )
      )
      .subscribe((resToken: string) => {
        this.user = this.adalService.userInfo;
        setTimeout(() => {
          this.bus.publish(
            Chanels.USER_CHANEL,
            new BUser(this.user, this.getDecodedAccessToken(this.user.token))
          );
        });
      });

    this.layoutService.onInit();
  }
  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }

  ngOnDestroy(): void {
    this.layoutService.onDestroy();
  }
}
