import { Component, OnInit } from '@angular/core';
import { TimeScheduleService } from '../time-schedule.service';
import { DaySchedule, DayScheduleTypes } from '../day-schedule.model';

import { BookableResource } from '../bookable-resource';
import { Observable } from 'rxjs/Observable';
import { ServiceBusService } from '../../system/service-bus.service';
import { ActivatedRoute } from '@angular/router';
import { Chanels } from '../../chanels';
import { MatDialog } from '@angular/material/dialog';
import { OpenHourFormDialogComponent } from '../day-schedul/open-hour-form.dialog';
import { FormAction, DialogAction } from '../../system/const';

@Component({
  selector: 'app-day-schedules',
  templateUrl: './day-schedules.component.html',
  styleUrls: ['./day-schedules.component.scss']
})
export class DaySchedulesComponent implements OnInit {

  birthdayBookingSchedules: Array<DaySchedule>;
  birthdayBookingExceptionSchedules: Array<DaySchedule>;

  regularBirthdayBookingSchedules: Array<DaySchedule>;
  regularBookingExceptionSchedules: Array<DaySchedule>;

  birthdayScheduleType = DayScheduleTypes.Birthday;
  regularScheduleType = DayScheduleTypes.Regular;

  public resource: BookableResource;

  constructor(private timeScheduleService: TimeScheduleService,
    private aroute: ActivatedRoute,
    private bus: ServiceBusService,
    public dialog: MatDialog) {
  }

  ngOnInit() {

    this.bus.subscribe(Chanels.RESOURCE, (r: BookableResource) => {
      if (!r || (this.resource && this.resource.id === r.id)) {
        return;
      }
      this.resource = r;
      this.loadBirthdaySchedules();
      this.loadRegularSchedules();

    });
  }

  private loadBirthdaySchedules() {
    this.timeScheduleService.getDaySchedules(DayScheduleTypes[1], this.resource.id)
    .subscribe((list: DaySchedule[]) => {
      this.birthdayBookingSchedules = list.filter(x => !x.ValidTo);
      this.birthdayBookingExceptionSchedules = list.filter(x => x.ValidTo);
    });
  }

  private loadRegularSchedules() {
    this.timeScheduleService.getDaySchedules(DayScheduleTypes[0], this.resource.id)
    .subscribe((list: DaySchedule[]) => {
      this.regularBirthdayBookingSchedules = list.filter(x => !x.ValidTo);
      this.regularBookingExceptionSchedules = list.filter(x => x.ValidTo);
    });
  }


  showOpenHourFormDialogForAdding(type: number) {
    const dialogRef = this.dialog.open(OpenHourFormDialogComponent, {
      data: {
        type: type,
        action: FormAction.ADD,
        resource: this.resource
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.handleDiaglogResult(result);
    });
  }

  showOpenHourFormDialogForEditing(type: number, scheduleId: string) {
    const dialogRef = this.dialog.open(OpenHourFormDialogComponent, {
      data: {
        type: type,
        action: FormAction.EDIT,
        scheduleId: scheduleId,
        resource: this.resource
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.handleDiaglogResult(result);
    });
  }

  private handleDiaglogResult(result: {action: string, type: number, data: any}) {
    if (result) {
      switch (result.action) {
        case DialogAction.SAVE:
        case DialogAction.DELETE:
          if (result.type === DayScheduleTypes.Birthday) {
            this.loadBirthdaySchedules();
          } else if (result.type === DayScheduleTypes.Regular) {
            this.loadRegularSchedules();
          }
          break;
        default:
          break;
      }
    }
  }

}
