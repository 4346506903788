<div class="header" fxLayout="row">
  <div class="text-title" fxFlex="shrink"><h1>Åpningstider</h1></div>
</div>
<div class="normal-cards">
  <div class="card" fxFlex fxLayout="row" fxLayout.sm="column">

    <mat-card fxFlex="100 60" class="push-right push-bottom">
      <mat-card-header>
        <div mat-card-avatar><mat-icon>people_outline</mat-icon></div>
        <mat-card-title>Vanlig</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li *ngFor="let schedule of regularBirthdayBookingSchedules">
            <ng-container [ngTemplateOutlet]="scheduleItem" [ngTemplateOutletContext]="{schedule: schedule}"></ng-container>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="exceptions">
  <div class="card" fxFlex fxLayout="row" fxLayout.sm="column">
      <mat-card fxFlex="100 60" class="push-right push-bottom">
        <mat-card-header>
          <div mat-card-avatar><mat-icon>error_outline</mat-icon></div>
          <mat-card-title>Untak</mat-card-title>
          <button mat-icon-button color="primary" (click)="showOpenHourFormDialogForAdding(regularScheduleType)"><mat-icon>add</mat-icon></button>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li *ngFor="let schedule of regularBookingExceptionSchedules">
              <ng-container [ngTemplateOutlet]="scheduleItem" [ngTemplateOutletContext]="{schedule: schedule}"></ng-container>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
  </div>
</div>

<ng-template #scheduleItem let-schedule="schedule">
  <div class="schedule-item push-bottom">
    <div class="schedule-summary">
      <span fxFlex="shrink">{{schedule}}</span>
      <!-- <a [routerLink]="['/resource', resource.prittyUrl, 'schedule', schedule?.id]"><mat-icon type="button">edit</mat-icon></a> -->
      <button mat-icon-button (click)="showOpenHourFormDialogForEditing(schedule.scheduleType, schedule?.id)"><mat-icon>edit</mat-icon></button>
    </div>
    <ul>
      <li *ngFor="let day of schedule?.getDays()">
        {{day}}
      </li>
    </ul>
  </div>
</ng-template>

<!-- <h2>Normalt</h2>
<ul *ngIf="resource">
  <li *ngFor="let type of scheduleTypes">
    <a [routerLink]="['/resource/'+ resource.prittyUrl, 'schedules',type.value]" routerLinkActive="router-link-active" >{{type.name}}</a>
  </li>
</ul>
<ul>
  <li *ngFor="let schedule of normalSchedules$|async">
    {{schedule}}
    <a [routerLink]="['/resource/'+ resource.prittyUrl, 'schedule', schedule.id]"><mat-icon type="button">edit</mat-icon></a>
    <a [routerLink]="['/resource/'+ resource.prittyUrl, 'schedule','copy', schedule.id]"><mat-icon svgIcon="copy"></mat-icon></a>
    <ul>
      <li *ngFor="let day of schedule.getDays()">
        {{day}}
      </li>
    </ul>
  </li>
</ul>
<h2>Untak</h2>
<ul *ngIf="resource">
  <li *ngFor="let schedule of exceptionsSchedules$|async">
    {{schedule}}
    <a [routerLink]="['/resource/'+ resource.prittyUrl, 'schedule', schedule.id]"><mat-icon type="button">edit</mat-icon></a>
    <a [routerLink]="['/resource/'+ resource.prittyUrl, 'schedule','copy', schedule.id]"><mat-icon svgIcon="copy"></mat-icon></a>
    <ul>
      <li *ngFor="let day of schedule.getDays()">
        {{day}}
      </li>
    </ul>
  </li>
</ul>
<a *ngIf="resource" mat-raised-button color="accent" type="button" [routerLink]="['/resource/'+ resource.prittyUrl, 'schedule','new', type]" routerLinkActive="router-link-active" >Ny</a> -->
