import React, { useContext } from 'react';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { Activity } from '../model';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import copy from 'copy-to-clipboard';
import { ActivitiesContext } from '../ActivitiesReactWrapper';
import { ConfirmationDialog } from '../../react-shared/ConfirmationDialog';
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  activity: Activity;
  onDelete?: (id: string) => any;
}

export const ActivityCard: React.FC<Props> = ({ activity, onDelete = () => {} }) => {
  const nav = useNavigate();

  const { siteIdPrittyUrl } = useContext(ActivitiesContext);

  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">{activity.name}</Typography>
        </div>
        <div>
          <Tooltip title="Copy ID">
            <IconButton onClick={() => copy(activity.id)}>
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => nav(`/resource/${siteIdPrittyUrl}/edit-activity/${activity.id}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <ConfirmationDialog
            dialogOpener={(
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            dialogTitle={`Are you sure that you want to delete ${activity.name} activity?`}
            dialogContent={`By doing this you are removing it from the system. Be aware of the fact that this action can not be undone.`}
            onConfirm={() => {
              onDelete(activity.id);
            }}
          />
        </div>
      </div>
    </Paper>
  );
}