<p>
  Anttal treff på  waiver {{count}}
</p>
<!-- <button mat-button (click)="filtrerMobile()">Mangler mobil</button>
<button mat-button (click)="filtrerEmail()">Mangler epost</button>
<button mat-button (click)="filtrerAlle()">Alle</button> -->
<mat-nav-list>
  <h3 matSubheader>Søk på registrerte Waiver/egenerklæring liste</h3>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <input type="text" placeholder="Tast inn mobilnr" aria-label="Number" matInput [formControl]="waiverControl" >
    </mat-form-field>
  </form>
  <mat-list-item *ngFor="let waiver of contracts$ | async">
    <!-- <a target="_blank" matLine href="{{getImageUrl(waiver)}}"><strong>MobilNr:</strong> {{waiver.mobile}} <strong>Navn:</strong>  {{waiver.contactPerson.firstname}}  {{waiver.contactPerson.lastname}}  <strong>Antall barn:</strong> {{waiver.children.length}}  </a> -->
    <a matLine [href]="'/egenerklering/'+waiver.id"><strong>MobilNr:</strong> {{waiver.mobile}} <strong>Navn:</strong>  {{waiver.contactPerson.firstname}}  {{waiver.contactPerson.lastname}}  <strong>Antall barn:</strong> {{waiver.children.length}}  </a>

    <!-- <a type="button" [class]="getTaskClass(waiver.id)" [href]="'/waiver/'+waiver.id" mat-button>Edit</a> -->
    <mat-divider [inset]="true"></mat-divider>
  </mat-list-item>

</mat-nav-list>

<!--
<form class="example-form">
  <mat-form-field class="example-full-width">
    <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.mobile }} {{ option.contactPerson.firstname }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form> -->


<!-- <div>

        <mat-selection-list #waivers  >
            <mat-list-option *ngFor ="let waiver of contracts$ | async">
                {{waiver.contactPerson.firstname}} {{waiver.contactPerson.lastname}}
            </mat-list-option>
          </mat-selection-list>

          <p>
            Options selected: {{waivers.selectedOptions.selected.length}}
          </p>
    </div> -->
