import { Component, OnInit } from '@angular/core';
import { BookableResource } from '../time-scheduling/bookable-resource';
import { BookableResourceService } from '../time-scheduling/bookable-resource.service';
import { ActivatedRoute } from '@angular/router';
import { ServiceBusService } from '../system/service-bus.service';
import { Chanels } from '../chanels';

@Component({
  selector: 'app-resourse-path',
  templateUrl: './resourse-path.component.html',
  styleUrls: ['./resourse-path.component.scss']
})
export class ResoursePathComponent implements OnInit {
  resourceUrl: string;
  public resource: BookableResource;
  constructor(private bookingResourceService: BookableResourceService,
    private aroute: ActivatedRoute,
    private bus: ServiceBusService) {

    // this.aroute.url.subscribe(url => {
    //   console.log('url', url);
    // });

    this.aroute.params.subscribe(params => {
      this.resourceUrl = params['refId'];
      if (this.resourceUrl) {
        this.bookingResourceService.getResource(this.resourceUrl).subscribe(r => {
          this.resource = r;
          this.bus.publish(Chanels.RESOURCE, this.resource);
        });
      }
    });
  }

  ngOnInit() {
  }

}
