import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private toogleResourceMenuSubject = new Subject();
  public toggleResourceMenu$ = this.toogleResourceMenuSubject.asObservable();

  private resourcesLoadedSubject = new Subject();
  public resourcesLoaded$ = this.resourcesLoadedSubject.asObservable();

  constructor() { }

  public openResourceMenu() {
    this.toogleResourceMenuSubject.next(true);
  }

  public notifyResourcesLoaded() {
    this.resourcesLoadedSubject.next(true);
  }

}
