import React, { FC, useState } from 'react';
import { TextField } from '@mui/material';

type Props = {
  list: any[];
  renderElement: (item: any) => JSX.Element;
  elementsContainerStyle?: any;
}

export const SearchableList: FC<Props> = ({ list, renderElement, elementsContainerStyle={} }) => {

  const [searchArgument, setSearchArgument] = useState<string>('');

  const filteredElements = list?.filter(elem => 
    Object.keys(elem).map(k => elem[k]).join(',')
    .toLocaleLowerCase().includes(searchArgument.toLocaleLowerCase()));

  return (
    <>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        sx={{ marginTop: '1em' }}
        onChange={e => setSearchArgument(e.target.value)}
      />
      <div style={{...elementsContainerStyle}}>
        {filteredElements?.map((elem: any) => renderElement(elem))}
      </div>
    </>
  );
}