import React, { FC, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";

export const NumberField: FC<TextFieldProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <TextField
        {...props}
        type="text"
        onChange={(e) => {
          props.onChange(e);
          const matches = e.target.value.match(/^\d+$/);
          if(!matches) {
            setErrorMessage("Must be a number.");
          } else {
            setErrorMessage("");
          }
        }}
        
      />
      {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
    </>
  );
};
