<div class="item-content" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">

  <h1>Historisk Oversikt over bursdags reservasjoner for <b *ngIf="resource">{{resource.name}}</b></h1>
  <div fxFlex="50%" class="item-top">
      <div fxFlex ="80%">
        <button (click)="onClickNavigate_before()" >
          <i class="material-icons">
            navigate_before
          </i>
        </button>
        <button (click)="onClickNavigate_next()">
          <i class="material-icons">
            navigate_next
          </i>
        </button>
        <button (click)="export(pastBookingList)">
            Eksporter {{currentDay.year()-1}}
        </button>
        <button (click)="export(nowBookingList)">
            Eksporter {{currentDay.year()}}
        </button>
      </div>
      <div fxFlex ="20%"class="today">
        Måned: {{getCurrentMonth() }}
      </div>
    </div>
  <div fxFlex="50%" class="item-reservation">

    <div class="item-matcard" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
      <div>
        <h2>Historisk</h2>
        <ul>
          <li *ngFor="let reservasjon of past$| async" [ngClass]="{booked:hasReBooked(reservasjon), canceled:!reservasjon.reservationId}" >
            <span fxFlex ><b *ngIf="hasReBooked(reservasjon)">Har booket igjen </b> <b>Kontakt</b>:{{reservasjon.details.mobile}} <b>Kontakt</b>:{{reservasjon.details.email}} <b>Date</b>:{{reservasjon.reservationDate | date : 'dd.MM.yyyy'}}</span>
          </li>
        </ul>
      </div>
      <div>
        <h2>{{currentDay.year()}}</h2>
        <ul>
          <li *ngFor="let reservasjon of  now$| async" [ngClass]="{booked:hasBooked(reservasjon), canceled:!reservasjon.reservationId}">
            <span fxFlex><b>Kontakt</b>:{{reservasjon.details.mobile}} <b>Kontakt</b>:{{reservasjon.details.email}} <b>Date</b>:{{reservasjon.reservationDate | date : 'dd.MM.yyyy'}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div fxFlex="50%" class="item-reservation">
    <div class="item-matcard" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">

    </div>
  </div> -->
</div>
