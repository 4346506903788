import React, { FC, useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Theme
} from "@mui/material";
import { ResourcesContext } from "../ResourcesReactWrapper";
import { initResourceService } from "../resourceService";
import { useNavigate, useParams } from "react-router-dom";
import { TimeIntervalInformation } from "../../models/day-rule-time-interval.model";
import DayRuleTimeIntervalDefinitionDialog, { InfoComponentProps } from "../../react-shared/DayRuleTimeIntervalDefinitionDialog";
import { NumberField } from "../../react-shared/NumberField";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    cancel: {
      marginLeft:"2rem !important"
    }
  });
});

const CapacityExceptionInfoComponent: FC<InfoComponentProps<number>> = ({ value, onChange }) => {
  return (
    <NumberField
      label="Capacity"
      variant="standard"
      value={value}
      onChange={(e) => {
        onChange(e.target.value as any);
      }}
    />
  )
}

type Props = {};

export const EditResourceForm: FC<Props> = () => {
  const { adalService, siteId, siteIdPrittyUrl } = useContext(ResourcesContext);
  const [resourceService] = useState(initResourceService(adalService));
  const classes = useStyles();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [capacity, setCapacity] = useState<number>(0);
  const [concurrentReservations, setConcurrentReservations] =
    useState<number>(0);
  const [prittyUrl, setPrittyUrl] = useState<string>("");
  const [capacityExceptions, setCapacityExceptions] = useState<TimeIntervalInformation<number>[]>();

  const [dialogOpen, setDialogOpen] = useState(false);

  const { id } = useParams();

  const isAdd = !id;

  useEffect(() => {
    if (id) {
      resourceService
        .getResource(id)
        .then((resource) => {
          setName(resource.name);
          setCapacity(resource.capacity);
          setConcurrentReservations(resource.concurrentReservations);
          setPrittyUrl(resource.prittyUrl);
          setCapacityExceptions(resource.capacityExceptions ?? []);
        })
        .catch(console.error);
    }
  }, [id]);

  const [alert, setAlert] = useState<{ open: boolean; success: boolean }>({
    open: false,
    success: false,
  });
  const setAlertOpen = (open: boolean) => {
    setAlert({
      ...alert,
      open,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = isAdd
      ? resourceService.addResource({
          name,
          siteId,
          capacity,
          prittyUrl,
          concurrentReservations,
          capacityExceptions: capacityExceptions ?? [],
        })
      : resourceService.updateResource({
          id,
          name,
          siteId,
          capacity,
          prittyUrl,
          concurrentReservations,
          capacityExceptions: capacityExceptions ?? [],
        });

    action
      .then(() => {
        setAlert({
          open: true,
          success: true,
        });
        navigate(`/resource/${siteIdPrittyUrl}/resources`);
      })
      .catch((err) => {
        setAlert({
          open: true,
          success: false,
        });
        console.error(err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ padding: "1em" }}>
        <Typography variant="h5">
          {isAdd ? "Add New" : "Edit"} Resource
        </Typography>
        <Grid container>
          <Grid item sm={12} md={6}>
            <TextField
              label="Name"
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="Pritty URL"
              variant="standard"
              value={prittyUrl}
              onChange={(e) => setPrittyUrl(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <NumberField
              label="Capacity"
              variant="standard"
              value={capacity}
              onChange={(e) => {
                setCapacity(e.target.value as any);
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <NumberField
              label="Concurrent Limit (0 to disable limit)"
              variant="standard"
              value={concurrentReservations}
              onChange={(e) => {
                setConcurrentReservations(e.target.value as any);
              }}
            />
          </Grid>
          <Grid item sm={12} sx={{ marginTop: "2em" }}>
            <Button onClick={() => setDialogOpen(true)} variant="contained" type="button">
              Capacity Exceptions
            </Button>
          </Grid>
          <Grid item sm={12} sx={{ marginTop: "2em" }}>
            <Button variant="contained" type="submit">
              Save
            </Button>
            <Button variant="contained" onClick={() => navigate(`/resource/${siteIdPrittyUrl}/resources`)} className={classes.cancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
      {(isAdd || capacityExceptions) && <DayRuleTimeIntervalDefinitionDialog<number>
        title="Capacity Exceptions"
        allItemsLabel="All Capacity Exceptions"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        handleSave={setCapacityExceptions}
        timeIntervalInformations={capacityExceptions ?? []}
        infoComponent={CapacityExceptionInfoComponent}
      />}
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alert.success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {alert.success ? "Resource saved." : "Something went wrong."}
        </Alert>
      </Snackbar>
    </>
  );
};
