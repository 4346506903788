import { Component, OnInit } from "@angular/core";
import { LayoutService } from "../services";

@Component({
  selector: "app-component-layout",
  templateUrl: "./component-layout.component.html",
  styleUrls: ["./component-layout.component.scss"],
})
export class ComponentLayoutComponent implements OnInit {
  sidenavMode: "side" | "over";
  constructor(private layoutService: LayoutService) {}
  ngOnInit(): void {
    this.layoutService.responsiveSize$.subscribe((size: string) => {
      this.sidenavMode =
        size === "xs" || size === "sm" || size === "md" ? "over" : "side";
    });
  }
}
