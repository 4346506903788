import { AdalService } from "adal-angular4";
import axios from "axios";
import { environment } from "../../environments/environment";
import { setAxiosInterceptors } from "../react-shared/axios-utils";
import { AddWaiverRules, WaiverRules } from "./model";

export type WaiverRulesService = {
  getAllWaiverRules: (siteId?: string) => Promise<WaiverRules[]>;
  getWaiverRules: (waiverRulesId: string) => Promise<WaiverRules>;
  addWaiverRules: (waiverRules: AddWaiverRules) => Promise<WaiverRules>;
  updateWaiverRules: (waiverRules: WaiverRules) => Promise<WaiverRules>;
  deleteWaiverRules: (waiverRulesId: string) => Promise<WaiverRules>;
}

export const initWaiverRulesService = (adalService: AdalService): WaiverRulesService => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = environment.dependencies.waiver_rules_api.url;
  setAxiosInterceptors(axiosInstance, adalService);

  const getAllWaiverRules = async (language?: string): Promise<WaiverRules[]> => {
    const params = {};
    if(language) params['language'] = language;
    return (await axiosInstance.get(`/api/waiverrules`, { params })).data;
  }

  const getWaiverRules = async (waiverRulesId: string): Promise<WaiverRules> => {
    return (await axiosInstance.get(`/api/waiverrules/${waiverRulesId}`)).data;
  }

  const addWaiverRules = async (waiverRules: AddWaiverRules): Promise<WaiverRules> => {
    return (await axiosInstance.post(`/api/waiverrules`, waiverRules)).data;
  }

  const updateWaiverRules = async (waiverRules: WaiverRules): Promise<WaiverRules> => {
    return (await axiosInstance.put(`/api/waiverrules/${waiverRules.id}`, waiverRules)).data;
  }

  const deleteWaiverRules = async (waiverRulesId: string): Promise<WaiverRules> => {
    return (await axiosInstance.delete(`/api/waiverrules/${waiverRulesId}`)).data;
  }

  return {
    getAllWaiverRules,
    getWaiverRules,
    addWaiverRules,
    updateWaiverRules,
    deleteWaiverRules,
  };
}