import { Injectable,EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Guid } from '../system/guid';
import { Product, ProductItem } from './catalog/product';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { isArray } from 'rxjs/internal/util/isArray';
import { BookableResource } from '../time-scheduling/bookable-resource';

@Injectable({providedIn: 'root'})
export class GbookingService {
  clearAll(): any {
    this.extrasForm = null;
    this.beskjedFormGroup = null;
    this.bekredtelseFormGroup = null;
    this.detailsFormGroup = null;
  }
  
  extrasForm: FormGroup;
  adminForm: FormGroup;
  detailsFormGroup: FormGroup;
  beskjedFormGroup: FormGroup;
  bekredtelseFormGroup: FormGroup;
  
  constructor(private fb: FormBuilder) { }

  
  createExtrasForm(catalog:Array<Product>) : FormGroup {
    var formInfo = catalog.reduce((acc:any,product)=>{
      acc[product.formGroup] = product.multiselect? this.getFormArray(product): null;
      return acc;
    },{});
    var extrasForm = this.fb.group(formInfo);
    return extrasForm;
  }
  createAdminEditForm(extras:Array<ProductItem>) : FormGroup {
    this.adminForm = this.fb.group({
      allergi: [''],
      extraToAdd: [null],
      extras: this.fb.array(extras.map(item=> this.fb.group(item)))
    });
    return this.adminForm;
  }
  
  private getFormArray(product: Product): any {
    return this.fb.array(product.items.map(item=>this.fb.group(item)));
  }

  createDetailsForm(resource:BookableResource=null): FormGroup {
    var formdefinition = {
      id: [Guid.newGuid().toString()],
      email: ['', Validators.pattern(/(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/)],
      advertising: [false],
      mobile: [null, [Validators.required, Validators.pattern(/^[0-9]{8}$/)]],
      children: this.fb.array([]),
      contactPerson: this.fb.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        birth: [moment().utc()]


      })
    };
    if(resource!= null){
      formdefinition['referenceId'] = [resource.id];
      formdefinition['tenantId'] = [resource.tenantId];
    }
    this.detailsFormGroup = this.fb.group(formdefinition);
    return this.detailsFormGroup;
  }
  // createFormbekredtelseFormGroup(): FormGroup {

  //   this.bekredtelseFormGroup = this.fb.group({
  //     // 'advertising':['']
     
  //   });
  //   return this.bekredtelseFormGroup
  // }
  createBeskjedForm(): FormGroup {
    this.beskjedFormGroup= this.fb.group({
      
      allergi: ['']

    });
    return this.beskjedFormGroup;
  }
  
  // getfirstFormGroup(): any {
  //   return this.firstFormGroup || this.createFormfirstFormGroup();
  // }
  private _extrasForm$: BehaviorSubject<FormGroup> =  new BehaviorSubject<FormGroup>(this.createExtrasForm(new Array<Product>()));
  public get extrasForm$(): Observable<FormGroup>{
    return this._extrasForm$.asObservable();
  };

  getExtrasFrom(catalog:Array<Product>): FormGroup {
    if(!this.extrasForm){
      this.extrasForm = this.createExtrasForm(catalog);
      this._extrasForm$.next(this.extrasForm);
    }
    return this.extrasForm;
  }
  getAdminEditFrom(catalog:Array<ProductItem>): FormGroup {
    return this.createAdminEditForm(catalog);
  }

  getDetailsFormGroup(resource:BookableResource=null): FormGroup {
    return this.detailsFormGroup || this.createDetailsForm(resource);
  }
  getBeskjedFormGroup(): any {
    return this.beskjedFormGroup || this.createBeskjedForm();
  }

  // getBekredtelseFormGroup(): any {
  //   return this.bekredtelseFormGroup || this.createFormbekredtelseFormGroup();
  // }
  getExtras(): Array<ProductItem>{
    var extra = this.extrasForm.value;
    var res =  Object.values(extra)
      .reduce( (acc: Array<ProductItem>,product:any)=>{
        if(!product) return acc;
        if(isArray(product)){
          acc = acc.concat(product.filter(x=>x.selected)
				.map((item)=>this.createCartItem(item)));
        } 
        else acc.push(this.createCartItem(product));
        return acc;
      }, []) as Array<ProductItem>;
    return res;
  }

  private createCartItem(item: ProductItem): ProductItem {
    return { description: item.name, price: Number(item.price), prOrder:item.prOrder  } as ProductItem;
  }
  
  // sumPrice(): any {
  //   var extras = this.getExtras();
  //   const reduceValue = extras.reduce((sum, curr) => (curr.prOrder?sum:sum + curr.price), 0);
  //   return reduceValue;
  // }
  totalPriceExtras(count:number): number {
    var extras = this.getExtras();
    return this.calculatePrice(extras, count);
  }

  public calculatePrice(extras: any[], count: number) {
    const reduceValue = extras?.reduce((sum, curr) => (curr.prOrder ? sum + curr.price : sum + (curr.price * count)), 0);
    return reduceValue ?? 0;
  }
  

}
