import React, { FC } from "react"
import { EditActivityForm } from "./components/EditActivityForm"

type Props = {

}

export const EditActivityPage: FC<Props> = () => {
  return (
    <>
      <EditActivityForm />
    </>
  )
}