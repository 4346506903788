<mat-grid-list cols="2" class="waiver_form_cols" md-row-height="fit">
  <mat-grid-tile colspan="1">
    <form #waiverForm="ngForm">
      <h1>Ta waiver bilde</h1>
      <p *ngIf="message">{{message}}</p>
      <div *ngIf="!imageUrl; then uploadform"></div>
      <ng-template #uploadform>
        <div *ngIf="!isUploading();else processing">
          <input #fileInput type="file" placeholder="WaverScan" name="waverFile" />
          <button (click)="addFile()" mat-button type="button">Add</button>
          <span md-error *ngIf="fileErrorMessage">{{fileErrorMessage}}</span>
        </div>
        <ng-template #processing>
          <mat-progress-bar class="inline_progress" mode="indeterminate" aria-label="Uploading image"></mat-progress-bar>
        </ng-template>
      </ng-template>
      <button *ngIf="imageUrl" mat-button type="button" (click)="SaveWaiver(waiverForm)"> save </button>
    </form>
  </mat-grid-tile>
  <mat-grid-tile>
    <div id="waiver_image" *ngIf="imageUrl">
      <img [src]="imageUrl" />
    </div>
  </mat-grid-tile>
</mat-grid-list>
<!-- {{waiverForm.value | json}} -->
<!-- {{ newWaiver | json}} -->