import { Component, OnInit } from '@angular/core';
import { MomentDateModule } from '@angular/material-moment-adapter';

import * as moment from "moment";
import { ActivatedRoute, ParamMap } from '@angular/router';
import { subscribeOn } from 'rxjs/operators';
import { TimeScheduleService } from '../../time-scheduling/time-schedule.service';
import { GroupReservation } from '../../time-scheduling/group/GroupReservation';
import { CalendarTool } from '../../system/calendartool';
import { Observable, BehaviorSubject } from 'rxjs/Rx';

import { range } from 'rxjs/observable/range';
import { BookableResource } from '../../time-scheduling/bookable-resource';
import { BookableResourceService } from '../../time-scheduling/bookable-resource.service';
import { ServiceBusService } from '../../system/service-bus.service';
import { Chanels } from '../../chanels';


@Component({
  selector: 'app-birthdaybookingdetails',
  templateUrl: './birthdaybookingdetails.component.html',
  styleUrls: ['./birthdaybookingdetails.component.scss']
})
export class BirthdaybookingdetailsComponent implements OnInit {
  public  birthdate = moment([1945, 11, 29]);
  public age: number;
  public date: moment.Moment = moment();

  public groupReservation$: Observable<Array<GroupReservation>>;
  public daySchedule$: Observable<Array<any>>;
  public dayRange$: BehaviorSubject<Array<moment.Moment>> = new BehaviorSubject<Array<moment.Moment>>([]);
  private weekDiff = 0;
  resourceUrl:string = "krsvag";
  public resource: Observable<BookableResource>;
  constructor(private route: ActivatedRoute,
    private bus:ServiceBusService,
    private timeScheduleService: TimeScheduleService) {
    this.CalculateAge();
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.resource = this.bus.chanelObservable(Chanels.RESOURCE);
      if(params.day){
        this.date = moment(params.day,'DDMMYYYY');
      }
      this.resource.subscribe(r=>{
        if(!r) return;
        this.resourceUrl = r.prittyUrl;
        this.groupReservation$ = this.timeScheduleService.getGroupReservations(CalendarTool.justDate(moment(this.date)),r.id);
        this.groupReservation$.subscribe((reservations: Array<GroupReservation>) => {
          this.daySchedule$ = this.dayRange$
              .map((days: Array<moment.Moment>) => {
              return days.reduce((acc, day) => {
                var dayDayte = CalendarTool.justDate(day).format('DD.MM.YYYY');
                var daySchedules = reservations
                  .filter((res) => res.reservation && CalendarTool.justDate(res.reservation.startTime).format('DD.MM.YYYY') == dayDayte)
                  .map(res => {
                    return {
                      time: res.reservation.startTime.format('HH:mm:ss'),
                      count: res.reservation.count,
                      contact: res.details.contactPerson.firstname + " " + res.details.contactPerson.lastname,
                      tlf: res.details.mobile,
                      email: res.details.email,
                      extras: res.extras,
                      msg: res.message,
                      children:res.details.children,
                      ref: res.reservation.referenceNumber
                    }
                  });

                const dayObject = { day: day.format('dddd DD MMM'), dato: day.format('DD MMM'), daySchedules: daySchedules };
                acc.push(dayObject);
                return acc;
              }, new Array<any>());
            });
            this.showWeek();
        });

        this.showWeek();
      });

    });
  }



  private showWeek() {
    range(0, 1).map((day) => this.date.clone().add(day, 'days'))
      .reduce((acc, day) => {
        acc.push(day);
        return acc;
      }, [])
      .subscribe((days) => {
        this.dayRange$.next(days);
      });
  }

  public CalculateAge(): void
  {
      if(this.birthdate){
        //  var timeDiff = Math.abs(Date.now() - this.birthdate );
        // hvorfor funker ikke - this.birthday?`??
        //  this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
         this.age = moment().diff(this.birthdate, 'years');
     }

    }
    Reservationlabel = [
    { label: 'Bursdagsbarn' },
    { label: 'Antall' },
    { label: 'MAt, drikke& tillegg' },
    { label: 'Allergi, annet' },
    { label: 'Bestiller' },

  ];
}
