import { Component, OnInit, Input } from '@angular/core';
import { TimeScheduleService } from '../../time-scheduling/time-schedule.service';
import { Reservation } from '../../time-scheduling/timeslot.model';
import * as moment from 'moment';
import { GroupReservation } from '../../time-scheduling/group/GroupReservation';
import { BookableResource } from '../../time-scheduling/bookable-resource';
import { ReservationOverview } from '../../models';

@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss']
})
export class BookingCardComponent implements OnInit {

  @Input() data: ReservationOverview;

  public birthdayBookingCount = 0;
  public alwaysBookingCount = 0;
  constructor(
    private timeScheduleService: TimeScheduleService
  ) { }

  ngOnInit() {
  }

}
