import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { Formik, Form, Field } from "formik";
import FormikField from "./FormikField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Info, DateTime } from "luxon";
import * as Yup from "yup";
import DialogTitle from "./DialogTitle"
import { TimeIntervalInformation, TimeOfDay } from "../models/day-rule-time-interval.model";
import { DateInput } from "./DateInput";
import { useEffect, useState } from "react";

export type InfoComponentProps<T> = {
  value: T;
  onChange: (value: T) => any;
}

type Props<T> = {
  open: boolean;
  onClose: () => void;
  timeIntervalInformations: TimeIntervalInformation<T>[];
  handleSave: (timeIntervalInformations: TimeIntervalInformation<T>[]) => void;
  infoComponent: React.FC<InfoComponentProps<T>>;
  title: string;
  allItemsLabel?: string;
  noItemsLabel?: string;
  initialInfoValue?: T;
  infoToString?: (info: T) => string;
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    row: {
      display: "flex",
    },
    button: {
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
  });

const useStyles = makeStyles(styles);

const formatTime = (t: TimeOfDay) => `${t.hour}:${t.minute}`;
const parseTime = (t: string): TimeOfDay => {
  const split = t.split(':');
  return {
    hour: parseInt(split[0]),
    minute: parseInt(split[1]),
  }
} 

const DayRuleTimeIntervalDefinitionDialog = <T,>({
  open,
  onClose,
  timeIntervalInformations,
  handleSave,
  infoComponent,
  title,
  allItemsLabel = "All items",
  noItemsLabel = "No items",
  initialInfoValue,
  infoToString = (info: T) => info + ""
}: Props<T>) => {
  const EditInfo = infoComponent;
  const classes = useStyles();
  
  
  const exceptionSchema = Yup.object().shape({
    customValidation: Yup.boolean().test(
      "my test",
      "From has to be before To",
      function (val) {
        const { from, to } = this.parent;
        if (!from || !to) {
          return null;
        }
        return (
          DateTime.fromFormat(from, "HH:mm") <= DateTime.fromFormat(to, "HH:mm")
        );
      }
    ),
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
    days: Yup.array().min(1, "Select at least one day"),
  });
  
  const [initialsFields, setInitialsFields] = useState({
    validFrom: undefined,
    validTo: undefined,
    from: "",
    to: "",
    days: [],
    info: initialInfoValue ?? ""
  });
  useEffect(() => {
    console.log(initialsFields);
  }, [initialsFields]);

  const [timeIntervalInformationsLocal, setTimeIntervalInformations] = React.useState<TimeIntervalInformation<T>[]>(timeIntervalInformations);

  const handleSubmit = (values: any, { resetForm }) => {
    setTimeIntervalInformations(prev => [...prev, {
      days: values.days.map(d => parseInt(d)),
      info: values.info,
      validFrom: values.validFrom,
      validTo: values.validTo,
      startTime: parseTime(values.from),
      endTime: parseTime(values.to),
    }])

    resetForm();
  };

  const handleDelete = (item: any) => {
    setTimeIntervalInformations((prev) => prev.filter((e) => e !== item));
  };
  const handleEdit = (item: any) => {
    setInitialsFields( item);
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{`${title}`}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialsFields}
          onSubmit={handleSubmit}
          validationSchema={exceptionSchema}
          enableReinitialize={true}

        >
          {({ errors, values, setFieldValue, setValues }) => (
            <Form>
              <Box className={classes.row}>
                <DateInput label={<>From</>} onChange={(value)=> setFieldValue("validFrom",value)} value={initialsFields.validFrom || ""}/>
                <DateInput label={<>To</>} onChange={(value)=> setFieldValue("validTo",value)} value={initialsFields.validTo|| ""}/>
              </Box>
              <Box className={classes.row}>
                <FormikField name="from" label={`Start Time`} type="time" shrink steps={300} />
                <FormikField name="to" label={`End Time`} type="time" shrink  steps={300}/>
              </Box>

              {errors.customValidation ? (
                <Alert severity="error">{errors.customValidation}</Alert>
              ) : null}

              <Box className={classes.row}>
                <Field name="days">
                  {({ field }) => {
                    return Info.weekdays()?.map((item, key) => {
                      return (
                        <FormControlLabel
                          key={key}
                          {...field}
                          labelPlacement="bottom"
                          label={
                            Info.weekdays("short", { locale: 'no' })[
                              key
                            ]
                          }
                          control={
                            <Field
                              type="checkbox"
                              {...field}
                              as={Checkbox}
                              color="primary"
                              value={key}
                              checked={!!field.value.includes(key.toString())}
                            />
                          }
                        />
                      );
                    });
                  }}
                </Field>
              </Box>
              <Box className={classes.row}>
                <EditInfo
                  value={values.info}
                  onChange={v => setFieldValue('info', v)}
                />
              </Box>

              {errors.days ? (
                <Alert severity="error">{errors.days}</Alert>
              ) : null}

              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
              >
                {`add`}
              </Button>
            </Form>
          )}
        </Formik>

        <Typography variant="h5" component="h3">
          {timeIntervalInformationsLocal?.length ? allItemsLabel : noItemsLabel}
        </Typography>
        <List>
          {timeIntervalInformationsLocal?.map((item, key) => {
            const sortedDays = item.days.sort();
            const days = sortedDays.map((day) => Info.weekdays("short")[day]).join(",");

            const timeText = `${days}, ${formatTime(item.startTime)}-${formatTime(item.endTime)}`;
            return (
              <ListItem key={key}>
                <ListItemText primary={`${timeText} | ${infoToString(item.info)}`} />
                <ListItemSecondaryAction>
                {/* <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(item)}
                  >
                    <EditIcon />
                  </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => {
            handleSave(timeIntervalInformationsLocal);
            onClose();
          }}
        >
          {`Done`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DayRuleTimeIntervalDefinitionDialog;
