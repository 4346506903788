<div class="item-content" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
  <div fxFlex="50%" class="item-top">
    <div fxFlex ="80%">
      <button (click)="onClickNavigate_before()" >
        <i class="material-icons">
          navigate_before
        </i>
      </button>
      Navigere
      <button (click)="onClickNavigate_next()">
        <i class="material-icons">
          navigate_next
        </i>
      </button>
    </div>
    <div fxFlex ="20%"class="today">
      <i class="material-icons">
        today 
      </i>{{now}}  <span class="ukecolor">-Uke {{weeknumber}}</span>
    </div>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center" class="right-controls">
    <mat-chip-list aria-label="Fish selection" multiple="true">
      <mat-chip *ngFor="let r of resources" [selected]="isSelectedResource(r)" (click)="toggleResource(r)">{{r.name}}</mat-chip>
    </mat-chip-list>
  </div>
  <div fxFlex="50%" class="item-reservation">
      <h3 >Dagens bookinger for <b *ngIf="resource">{{resource.name}}</b></h3>
   
    <div class="maindiv" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
      <div fxFlex class="timestemp" *ngFor="let capacity of dayCapacity">
          {{capacity.startTime| date:'dd.MM.yyy'}}
        <div fxFlex="20%" class="time" fxLayout="row" >
          <span class="time-content">{{capacity.startTime| date:'HH:mm'}} - {{capacity.end() | date:'HH:mm'}}</span>
        </div>
        <div fxFlex="50%" class="time" fxLayout="column">
          <div fxFlex="50%">
            <mat-progress-bar mode="determinate" [value]="capacity.count"></mat-progress-bar>
          </div>
          <div fxFlex="50%" class="time-bar-value">
            <span> {{capacity.count}}/{{capacity.totalCapacity}} </span>

          </div>

        </div>

      </div>

    </div>

  </div>
</div>
