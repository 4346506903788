import React, { FC } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { WaiverRulesList } from "./components/WaiverRulesList";

type Props = {};

export const WaiverRulesPage: FC<Props> = () => {
  return (
    <>
      <Link
        style={{ textDecoration: "none", marginBottom: "1em" }}
        to={`/edit-waiver-rules`}
      >
        <Button variant="contained">New Waiver Rules</Button>
      </Link>
      <WaiverRulesList />
    </>
  );
};
