export const FormAction = {
  ADD: 'Add',
  EDIT: 'Edit',
  COPY: 'Copy'
};

export const DialogAction = {
  SAVE: 'SAVE',
  DELETE: 'DELETE',
  CANCEL: 'CANCEL',
  CLOSE: 'CLOSE'
};

