import { Injectable } from '@angular/core';
import { BookableResource } from './bookable-resource';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment.test';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class BookableResourceService {

  private resources = {
    // "krsvag": new BookableResource("3C1D737A-2B02-4BEB-9548-2B6E64DB7BF0","Skyland Vågsbygd","krsvag", "40418182"),
    // "jaerhagen": new BookableResource("D9A01940-52DF-4C08-B630-A722F79BA5C3","Skyland Jærhagen","jaerhagen","40418182")
  }
  constructor(private serviceService: SiteService) { 

  }

  public getResource(prittyUrl:string): Observable<BookableResource>{
    var subject = new Subject<BookableResource>();
    if(this.resources[prittyUrl]){
      return Observable.of(this.resources[prittyUrl]);
    }
    this.getResources().subscribe((x)=>{
      subject.next(this.resources[prittyUrl]);
    });
    return subject.asObservable();
  }
  
  // TODO EXTRACT tennant id so this can be configured for next app
  getResources(): Observable<Array<BookableResource>> {
    var resources = this.serviceService.getPublicSites("AmusementPark",environment.tenantId).map(x=>{
      return x.map(y=>{
        var o = BookableResource.deSerialize(y);
        this.resources[o.prittyUrl] = o;
        return o;
      });
    });
    
    return resources;
  }
}
