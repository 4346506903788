<div class="header" fxLayout="row">
  <mat-icon>cake</mat-icon>
  <div mat-dialog-title class="push-left title-text" fxFlex="shrink">{{scheduleTypeText}} Booking Åpningstider</div>
  <button mat-icon-button (click)="onCloseDialog()"><mat-icon>close</mat-icon></button>
</div>

<div *ngIf="!dayForm">
  <mat-spinner></mat-spinner>
</div>
<div mat-dialog-content *ngIf="dayForm" class="pad">
  <hr/>
  <form [formGroup]="dayForm" fxLayout="column">
    <div fxLayout="row" class="date-range">
      <mat-form-field class="push-right">
        <input formControlName="validFrom" [min]="minDate" name="datpicker" matInput [matDatepicker]="validFrom"
          placeholder="Velg fra dato (dd.mm.yyyy)">
        <mat-datepicker-toggle matSuffix [for]="validFrom"></mat-datepicker-toggle>
        <mat-datepicker #validFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input formControlName="validTo" [min]="minDate" name="datpicker" matInput [matDatepicker]="validTo"
          placeholder="Velg til dato (dd.mm.yyyy)">
        <mat-datepicker-toggle matSuffix [for]="validTo"></mat-datepicker-toggle>
        <mat-datepicker #validTo></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxLayout="row" formGroupName="startTime">
      <mat-form-field class="push-right">
        <mat-select placeholder="Åpningstid time" formControlName="hour">
          <mat-option *ngFor="let hour of hours" [value]="hour">
            {{hour}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="push-right">
        <mat-select placeholder="minut" formControlName="minute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{minute}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" formGroupName="endTime">
      <mat-form-field class="push-right">
        <mat-select placeholder="Stengetid time" formControlName="hour">
          <mat-option *ngFor="let hour of hours" [value]="hour">
            {{hour}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="push-right">
        <mat-select placeholder="minut" formControlName="minute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{minute}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" class="date-options">
      <mat-form-field class="push-right" fxFlex="50">
        <mat-select formControlName="days" multiple placeholder="Stengetid day">
          <mat-option *ngFor="let day of days" [value]="day.value">{{day.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field>
        <input formControlName="capacity" name="capacity" matInput placeholder="Total Kapasitet">
      </mat-form-field> -->
    </div>

    <!-- <div formArrayName="priceInfos" fxLayout="column">
      <div *ngFor="let info of priceInfos.controls; let i=index" [formGroupName]="i"
      fxLayout="row">
        <mat-form-field class="push-right">
          <input formControlName="viewValue" name="viewValue" matInput placeholder="Visningstekst">
        </mat-form-field>
        <mat-form-field class="push-right">
          <mat-select placeholder="Varighet i min" formControlName="duration">
            <mat-option *ngFor="let minute of durations" [value]="minute">
              {{minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="push-right">
          <input formControlName="price" name="price" matInput placeholder="Pris">
        </mat-form-field>
        <mat-form-field class="push-right">
          <input formControlName="campaign" name="campaign" matInput placeholder="Kampanje">
        </mat-form-field>
        <button mat-icon-button type="button" matTooltip="Slette pris" matTooltipPosition="above"
        (click)="deletePriceInfo(i)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button color="accent" type="button" matTooltip="Legg pris" matTooltipPosition="above"
          (click)="addPriceInfo()"
          *ngIf="i === (priceInfos.controls.length - 1)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div> -->
  </form>
  <hr/>
</div>
<div mat-dialog-actions *ngIf="dayForm" fxLayoutAlign="center center">
  <button mat-raised-button *ngIf="scheduleId" color="warn" (click)="deleteDaySchedule()">Slette</button>
  <button mat-raised-button *ngIf="!scheduleId" color="warn"  (click)="onCancelClick()">Avbryt</button>      
  <button mat-raised-button (click)="onSaveClick()" color="primary" 
    [disabled]="dayForm?.invalid" class="push-left">
    Lagre
  </button>
</div>