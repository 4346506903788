import { Component, OnInit, OnDestroy } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { SubscriptionsLifespanHandler } from '../../infrastructure/subscriptions-lifespan-handler';
import { Subscription } from 'rxjs/Rx';
import { WaiverForm } from '../waiverform.model';
import { WaverFromService } from '../waver-from.service';
import { FormControl } from '@angular/forms';
import {map, startWith, flatMap, filter} from 'rxjs/operators';
import 'rxjs/add/operator/filter';

declare var require: any;
var aminsJobb:Array<string> = require('./amin.json');

@Component({
  selector: 'app-waiver-list',
  templateUrl: './waiver-list.component.html',
  styleUrls: ['./waiver-list.component.scss']
})
export class WaiverListComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    // this.urlSub.unsubscribe();
    this.subscriptions.unsubscribe();
  }
  waiverControl: FormControl = new FormControl();

   filteredOptions: Observable<Array<WaiverForm>>;
  public count: number;
  private readonly waiverApi = environment.dependencies.waiver_api.url + "/api/wavercontracts";
  private filter:string = "";
  public contracts$:Observable<Array<WaiverForm>> ;

  private subscriptions:SubscriptionsLifespanHandler = new SubscriptionsLifespanHandler();
  private urlSub: Subscription;

  constructor(private service: WaverFromService,private route: ActivatedRoute, private router: Router) {
  }
  ngOnInit() {
    this.getWaivers();
  }


  private getWaivers() {
    this.subscriptions.unsubscribe();

    // this.contracts$ = this.service.getFiltered(this.filter);'
    this.contracts$ = this.waiverControl.valueChanges
    .filter((x:string)=>{
      return x.startsWith('');
    })
    .flatMap((data)=>{
      return this.service.search(`${data}*`).map((x:Array<WaiverForm>)=>x.slice(0,15));
    });
    this.subscriptions.add(this.contracts$.subscribe((data) => {
      this.count = data['length'];
    }));

  }

  getImageUrl(waiver){
    return `${this.waiverApi}/upload/${waiver.id}`;
  }
  filtrerMobile(){
    this.router.navigateByUrl("/egenerklering/no_mobile");
  }
  filtrerAlle(){
    this.router.navigateByUrl("/egenerklering");
  }
  filtrerEmail(){
    this.router.navigateByUrl("/egenerklering/no_email");
  }

  public getTaskClass(id){
    return aminsJobb.indexOf(id)>=0?"even":"";
  }


}
