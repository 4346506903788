import { AdalService } from "adal-angular4";
import axios from "axios";
import { environment } from "../../environments/environment";
import { setAxiosInterceptors } from "../react-shared/axios-utils";
import { AddResource, Resource } from "./model";

export type ResourceService = {
  getResources: (siteId?: string) => Promise<Resource[]>;
  getResource: (resourceId: string) => Promise<Resource>;
  addResource: (resource: AddResource) => Promise<Resource>;
  updateResource: (resource: Resource) => Promise<Resource>;
  deleteResource: (resourceId: string) => Promise<Resource>;
}

export const initResourceService = (adalService: AdalService): ResourceService => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = environment.dependencies.resources_api.url;
  setAxiosInterceptors(axiosInstance, adalService);

  const getResources = async (siteId?: string): Promise<Resource[]> => {
    const params = {};
    if(siteId) params['siteId'] = siteId;
    return (await axiosInstance.get(`/api/resources`, { params })).data;
  }

  const getResource = async (resourceId: string): Promise<Resource> => {
    return (await axiosInstance.get(`/api/resources/${resourceId}`)).data;
  }

  const addResource = async (resource: AddResource): Promise<Resource> => {
    return (await axiosInstance.post(`/api/resources`, resource)).data;
  }

  const updateResource = async (resource: Resource): Promise<Resource> => {
    return (await axiosInstance.put(`/api/resources/${resource.id}`, resource)).data;
  }

  const deleteResource = async (resourceId: string): Promise<Resource> => {
    return (await axiosInstance.delete(`/api/resources/${resourceId}`)).data;
  }

  return {
    getResources,
    getResource,
    addResource,
    updateResource,
    deleteResource,
  };
}