import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TimeScheduleService } from '../../time-scheduling/time-schedule.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupReservation } from '../../time-scheduling/group/GroupReservation';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm, FormArray } from '@angular/forms';
import { TimeSlot, Reservation, ActivityReserved } from '../../time-scheduling/timeslot.model';
import { DateSchedule } from '../../time-scheduling/date-schedule.model';
import { BookingFormService } from '../../time-scheduling/booking-form.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingFormComponent } from '../../time-scheduling/booking-form/booking-form.component';
import { DayScheduleTypes } from '../../time-scheduling/day-schedule.model';
import { Guid } from '../../system/guid';
import { DialogDeleteBookingComponent } from '../../time-scheduling/reservations/reservations.component';
import { timeout } from 'rxjs/operators';
import { CalendarTool } from '../../system/calendartool';
import { BookingDetailsDialogComponent } from './dialog-booking-details.dialog';
import { GroupBookingEditDialog } from './group-booking-edit.dialog';
import { GbookingService } from '../../group-booking/gbooking.service';
import { ActivityService, initActivityService } from '../../activities-react/activityService';
import { Activity } from '../../activities-react/model';
import { AdalService } from 'adal-angular4';
import { ServiceBusService } from '../../system/service-bus.service';
import { BookableResource } from '../../time-scheduling/bookable-resource';
import { Chanels } from '../../chanels';


@Component({
  selector: 'app-group-booking-details',
  templateUrl: './group-booking-details.component.html',
  styleUrls: ['./group-booking-details.component.scss']
})
export class GroupBookingDetailsComponent implements OnInit {

  private sub: any;
  private id: string;
  public groupReservation: GroupReservation;
  extrasForm: FormGroup;
  detailsForm: FormGroup;
  beskjedForm: FormGroup;
  bekreftelsesForm: FormGroup;
  reservationForm: FormGroup;
  monthSchedule: Map<string, DateSchedule>;
  reservation: Reservation;
  private refId: string;
  isLoading: boolean = false;
  defaultDate = "0001-01-01T00:00:00Z";
  private activityService: ActivityService;
  private activitiesRegistry: { [key: string]: Activity } = {};

  constructor(private timeScheduleService: TimeScheduleService,private gbookingservice: GbookingService,
    private bookingFormService: BookingFormService,private route: ActivatedRoute,
    public dialog: MatDialog,private router: Router,
    private adalService: AdalService,private bus: ServiceBusService) { }

  ngOnInit() {
    this.bus.chanelObservable(Chanels.RESOURCE).subscribe((r:BookableResource) => {
      if (r) {
        this.refId = r.prittyUrl;
      }
    });
    this.isLoading = true;
    this.activityService = initActivityService(this.adalService);
    this.activityService.getActivities().then(activities => {
      activities.forEach(activity => this.activitiesRegistry[activity.id] = activity);

      this.sub = this.route.params.subscribe(params => {
        if (!params['id']){
          this.isLoading = false;
          return;
        }
        this.id = params['id'];
        var self = this;
        this.timeScheduleService.getGroupReservationById(this.id).subscribe(reservation => {
          self.groupReservation = reservation;
          this.isLoading = false;
        });
      });
    });
  }

  get sumPrice(): number{
    return this.groupReservation.reservation.getTotalPrice();
  }
  get sumPriceExtras(): number{
    if(!this?.groupReservation?.reservation?.count) return 0;
    var sum = this.gbookingservice.calculatePrice(this.groupReservation.extras,this.groupReservation.reservation.count);
    return sum;
  }
  get children(): FormArray {
    return this.detailsForm.get('children') as FormArray;
  }

  isDefaultDate(birth:any){
    return birth == this.defaultDate;
  }
  // reschedule(){

  //   this.timeScheduleService.cancel(this.groupReservation.reservation).subscribe(d=>{
  //     this.groupReservation.reservation = this.reservation;
  //     this.groupReservation.reservationId = this.reservation.id;
  //     this.timeScheduleService.putGroupReservation(this.groupReservation).subscribe((res)=>{
  //       console.log("Reservation updated " + JSON.stringify(res));
  //     });
  //   });
  // }
  cancel(){

    const dialogRef = this.dialog.open(DialogDeleteBookingComponent ,{
      // data: this.groupReservation
      data: this.groupReservation.reservation
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.isLoading = true;
        this.timeScheduleService.cancelGroupReservation(this.groupReservation).subscribe(d=>{
          this.isLoading = false;
          this.router.navigate([`/resource/${this.refId}/bursdagoversikt`]);
        });
      }
    });

  }

  getActivity(id: string): Activity {
    return this.activitiesRegistry[id];
  }

  getActivityReservations(groupReservation: any): ActivityReserved[] {
    return groupReservation.reservations
      .filter(x => x.type === 'ActivityReserved')
      .map(x => Reservation.mapReservation(x));
  }

  openDialog() {
    const dialogRef = this.dialog.open(BookingDetailsDialogComponent, {
      data: this.groupReservation
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.router.navigate([`/resource/${this.refId}/bursdagoversikt`]);
    });
  }
  editGroupBooking() {
    const dialogRef = this.dialog.open(GroupBookingEditDialog, {
      data: this.groupReservation
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}


