import { AdalService } from "adal-angular4";
import { AxiosInstance } from "axios";

const interceptors = {};

export function setAxiosInterceptors(
  axios: AxiosInstance,
  adalService: AdalService
) {
  const interceptorName = "authHeader";

  if (interceptors[interceptorName]) {
    axios.interceptors.request.eject(interceptors[interceptorName]);
  }

  interceptors[interceptorName] = axios.interceptors.request.use((config) => {
    var originalRequest = config;
    return new Promise(function (resolve, reject) {
      adalService
        .acquireToken(adalService.getResourceForEndpoint(originalRequest.url))
        .subscribe(
          (resToken: string) => {
            if (resToken) {
              originalRequest.headers.authorization = `Bearer ${resToken}`;
              resolve(config);
            }
          },
          (error) => {
            console.error("ERROROR:", error);
            reject(error);
          }
        );

      // return config;
    });
  });
}
