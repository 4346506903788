import { Component, OnInit } from "@angular/core";
import { TimeScheduleService } from "../../time-scheduling/time-schedule.service";
import { BookingFormService } from "../../time-scheduling/booking-form.service";
import { Observable } from "rxjs/Observable";
import { CalendarTool } from "../../system/calendartool";
import { GroupReservation } from "../../time-scheduling/group/GroupReservation";
import { range } from "rxjs/observable/range";
import * as moment from "moment";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  Reservation,
  CapacityTimeSlot,
  ReservationInfo,
  ReservationsAgregate,
} from "../../time-scheduling/timeslot.model";
import { DayScheduleTypes } from "../../time-scheduling/day-schedule.model";
import { TimeSlotBookings } from "../../time-scheduling/time-slot-bookings.model";
import { BookableResourceService } from "../../time-scheduling/bookable-resource.service";
import { ActivatedRoute } from "@angular/router";
import { BookableResource } from "../../time-scheduling/bookable-resource";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { WaverFromService } from "../../waiver/waver-from.service";
import { boolean } from "yup";
import { ActivityService, initActivityService } from "../../activities-react/activityService";
import { AdalService } from "adal-angular4";
import { Activity, ActivityType } from "../../activities-react/model";
@Component({
  selector: "app-registerbokking",
  templateUrl: "./registerbokking.component.html",
  styleUrls: ["./registerbokking.component.scss"],
})
export class RegisterbokkingComponent implements OnInit {
  public simpleReservation$: Observable<Map<string, Array<CapacityTimeSlot>>>;
  public monthCapacity: Map<string, Array<CapacityTimeSlot>>;
  public dayCapacity: Array<CapacityTimeSlot>;

  public timeSlotBookings: TimeSlotBookings;
  public expectedBookings: Array<ReservationsAgregate>;
  reservationsPaymentInfo: Map<string, Boolean> = new Map();
  public allReservations: Array<ReservationInfo>;
  public currentDay: moment.Moment = moment().startOf("day");
  public timeSlot: moment.Moment = moment().startOf("day");
  resourceUrl: string = "krsvag";
  public resource$: Observable<BookableResource>;
  public resource: BookableResource;
  mobilePhoneWaiverSigns: { [key: string]: boolean } = {};
  private activityService: ActivityService;
  groupActivityIds: Set<string> = new Set();
  activities: Activity[];
  constructor(
    private timeScheduleService: TimeScheduleService,
    private bus: ServiceBusService,
    private http: HttpClient,private waverService: WaverFromService,
    private adalService: AdalService,
  ) {
    this.activityService = initActivityService(this.adalService);
  }

  ngOnInit() {
    var now = moment();
    var refreshInterval = 30 - now.minutes();
    if (refreshInterval < 0) {
      refreshInterval = 30 + refreshInterval;
    }
    this.resource$ = this.bus.chanelObservable(Chanels.RESOURCE);
    this.resource$.subscribe((r) => {
      if (!r) return;
      this.resource = r;
      this.getMonthCapacity(this.resource.id);
      setTimeout(() => {
        this.getMonthCapacity(this.resource.id);
        setInterval(() => {
          this.getMonthCapacity(this.resource.id);
        }, 30 * 60 * 1000);
      }, refreshInterval * 60 * 1000);
      
      this.activityService.getActivities(this.resource.id).then(activities => {
        this.activities = activities;
        this.groupActivityIds = new Set(activities.filter(a => a.activityType == ActivityType.Group).map(x=>x.id));
      });
    });
  }
  isGroupActivity(reservation:ReservationsAgregate){
    return this.groupActivityIds.has(reservation.referenceId);
  }
  getActivityName(reservation:ReservationsAgregate){
    return this.activities.find(x=>x.id === reservation.referenceId)?.name;
  }
  private getMonthCapacity(referenceId: string) {
    this.simpleReservation$ = this.timeScheduleService.getMonthCapacity(
      this.currentDay,
      "regular",
      referenceId
    );
    this.simpleReservation$.subscribe((monthCapacity) => {
      this.monthCapacity = monthCapacity;
      this.getDay(referenceId);
    });
  }
  public showCheckinTimeslot(timeSlotStart: moment.Moment) {
    this.showTimeslot(timeSlotStart, this.resource.id);
  }
  private showTimeslot(timeSlotStart: moment.Moment, referenceId: string) {
    if (!timeSlotStart) {
      this.timeSlot = null;
      this.expectedBookings = new Array<ReservationsAgregate>();
      this.allReservations = new Array<ReservationInfo>();
      return;
    }
    this.timeSlot = timeSlotStart;
    this.timeScheduleService
      .getTimeslotBookings(timeSlotStart, referenceId)
      .subscribe((timeslots) => {
        this.timeSlotBookings = timeslots;
        this.expectedBookings = timeslots.getUnRegistered();
        if(this.expectedBookings.length > 0)
          this.waverService
              .confirmWaiverExist([...new Set(this.expectedBookings.map(x => encodeURIComponent(x.mobile)))].filter(x => !!x))
              .subscribe({
                next: (r) => {
                  r.contractsExists.forEach(c => {
                    this.mobilePhoneWaiverSigns[decodeURIComponent(c.phone)] = c.contractExists;
                  })
                }
              })
        this.allReservations = timeslots.getReservations();
        
        this.expectedBookings.forEach((reservation: ReservationsAgregate) => {
          this.checkPayment(reservation.reservationId).subscribe((paymentInfo)=>{
            const existing = this.reservationsPaymentInfo.get(reservation.reservationId);
            if(!existing){
              this.reservationsPaymentInfo.set(
                reservation.reservationId,
                paymentInfo
              );
            }            
          })
          
        });
      });
  }
  isPaid(reservationId:string):string{
    var res = this.reservationsPaymentInfo.get(reservationId);
    return (!!res).toString();
  }
  isWaiverSigned(mobile:string): string{
    return this.mobilePhoneWaiverSigns[mobile]?'true':'false'
  }
  checkPayment(reservationId: string): Observable<Boolean> {
    var apiUrl = environment.dependencies.time_scheduling_api.url;
    var url = `${apiUrl}/api/payments/isPaid/${reservationId}`;
    return this.http.get(url).reduce((acc:Boolean,obj: any) => {
      return acc || obj.isPaid === true;
    },false);
  }

  private getDay(referenceId: string) {
    this.dayCapacity = this.monthCapacity.get(
      this.currentDay.format("DD.MM.YYYY")
    );
    var timeSlot = null;
    var now = moment().subtract("minutes", 30);

    this.dayCapacity = this.dayCapacity.filter((value) => {
      var checkTimeSlot = value.startTime.clone().add(1, "hour");
      return checkTimeSlot > now;
    });
    if (this.dayCapacity.length > 0) {
      timeSlot = this.dayCapacity[0].startTime;
      this.showTimeslot(timeSlot, referenceId);
    }
  }
  private getDayShort() {
    this.dayCapacity = this.monthCapacity.get(this.currentDay.format("dddd"));
  }
  private bookingTypeMap: Map<DayScheduleTypes, string> = new Map<
    DayScheduleTypes,
    string
  >([
    [DayScheduleTypes.Regular, "vanlig"],
    [DayScheduleTypes.Birthday, "bursdag"],
    [DayScheduleTypes.VR, "VR"],
  ]);
  public getTypeText(dayScheduleType: DayScheduleTypes) {
    const text = this.bookingTypeMap.get(dayScheduleType);
    return text || this.bookingTypeMap.get(DayScheduleTypes.Regular);
  }

  public register(reservation: ReservationsAgregate) {
    this.timeScheduleService.checkin(reservation).subscribe(() => {
      this.showCheckinTimeslot(this.timeSlot);
    });
  }
}
