import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { Routes, RouterModule, RouterLink } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "./material.module";
import { InfrastructureModule } from "./infrastructure/infrastructure.module";

import { AppComponent } from "./app.component";
import { AboutComponent } from "./about/about.component";
import { MessageCenterComponent } from "./message-center/message-center.component";
import { ComponentLayoutComponent } from "./component-layout/component-layout.component";
import { APP_ROUTES } from "./app.routes";
import { UplaodService } from "./uplaod.service";
import { ErrorComponent } from "./error/error.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ResponsiveModule } from "ngx-responsive";

import { JwtModule, JwtHelperService } from "@auth0/angular-jwt";
import { LoginComponent } from "./login/login.component";
import { LoginService } from "./login/login.service";
import { LoginRouteGuard } from "./login-route-guard";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { environment } from "../environments/environment";
import { WaiverModule } from "./waiver/waiver.module";
import { GbookingComponent } from "./bookingbirthday/gbooking/gbooking.component";
import { BookingbirthdayModule } from "./bookingbirthday/bookingbirthday.module";
import { TimeSchedulingModule } from "./time-scheduling/time-scheduling.module";
import { BookingModule } from "./booking/booking.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MenuComponent } from "./menu/menu.component";
import { AdalService, AdalInterceptor } from "adal-angular4";
import { AuthenticationGuard } from "./system/AuthenticationGuard";
import { ResoursePathComponent } from "./resourse-path/resourse-path.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { ToolbarMenuComponent } from "./toolbar-menu/toolbar-menu.component";
import { LayoutService, SidebarService } from "./services";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BookingCardComponent } from "./dashboard/booking-card/booking-card.component";
// tslint:disable-next-line:max-line-length
import { BirthdayBookingOverviewTableComponent } from "./bookingbirthday/birthday-booking-overview-table/birthday-booking-overview-table.component";
import { UnauthpageComponent } from "./unauthpage/unauthpage.component";
import { MaintenanceComponent } from "./maintenance-react/MaintenanceReactWrapper";
import { ProductsComponent } from "./products-react/ProductsReactWrapper";
import { ActivitiesComponent } from "./activities-react/ActivitiesReactWrapper";
import { ResourcesComponent } from "./resources-react/ResourcesReactWrapper";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

export const MY_FORMATS = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD.MM.YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    MessageCenterComponent,
    ComponentLayoutComponent,
    ErrorComponent,
    LoginComponent,
    MenuComponent,
    ResoursePathComponent,
    SideMenuComponent,
    ToolbarMenuComponent,
    DashboardComponent,
    BookingCardComponent,
    BirthdayBookingOverviewTableComponent,
    UnauthpageComponent,
    MaintenanceComponent,
    ProductsComponent,
    ActivitiesComponent,
    ResourcesComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      APP_ROUTES
      // { enableTracing: true }
    ),
    ResponsiveModule.forRoot(),
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InfrastructureModule,
    HttpClientModule,
    ScrollingModule,
    WaiverModule,
    BookingbirthdayModule,
    TimeSchedulingModule,
    BookingModule,
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: tokenGetter,
    //     whitelistedDomains: [environment.dependencies.waiver_api.domain],
    //     blacklistedRoutes: [environment.dependencies.waiver_api.domain+'/api/token']
    //   }
    // })
  ],
  providers: [
    UplaodService,
    // WaverFromService,
    // LoginService,
    // LoginRouteGuard,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "nb" },
    JwtHelperService,
    { provide: "environment", useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdalInterceptor,
      multi: true,
    },
    AdalService,
    AuthenticationGuard,
    SidebarService,
    LayoutService,
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
