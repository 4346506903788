<h1 mat-dialog-title>Bursdags bestilling</h1>
<div mat-dialog-content class="info">
  <p>Bestillerens Navn : {{data.contact}}</p>
  <p>Mobil :{{data.tlf}}</p>
  <p>Activity:{{data.activityName}}</p>
  <p *ngIf="data.groupReservation.notGroup && data.unitPrice">
    Pris: {{data.unitPrice}} kr
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>OK</button>
</div>
