import { Component, OnInit } from "@angular/core";

import { Observable } from "rxjs/Observable";

import * as moment from "moment";
import { TimeScheduleService } from "../../time-scheduling/time-schedule.service";

import {
  Reservation,
  ReservationsAgregate,
} from "../../time-scheduling/timeslot.model";
import { DayScheduleTypes } from "../../time-scheduling/day-schedule.model";
import { TimeSlotBookings } from "../../time-scheduling/time-slot-bookings.model";
import { BookableResource } from "../../time-scheduling/bookable-resource";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { initResourceService, ResourceService } from "../../resources-react/resourceService";
import { AdalService } from "adal-angular4";
import { Resource as ResourceTimeSchedule } from "../../time-scheduling/booking-form-react/activity.model";
import { Resource } from "../../resources-react/model";
import { CapacityTimeSlot, Reservation as ReservationNew } from "../../time-scheduling/booking-form-react/timeslot.model.new";
import { CalendarTool } from "../../system/calendartool";

@Component({
  selector: "app-bookingoverview",
  templateUrl: "./bookingoverview.component.html",
  styleUrls: ["./bookingoverview.component.scss"],
})
export class BookingoverviewComponent implements OnInit {
  public weeknumber = Number(moment().format("ww"));
  public day: moment.Moment = moment().subtract(15,"minutes");

  public now = moment().format("MMM DD YYYY");

  public simpleReservation$: Observable<Map<string, Array<CapacityTimeSlot>>>;

  public monthCapacity: Map<string, Array<CapacityTimeSlot>>;
  public dayCapacity: Array<CapacityTimeSlot>;
  public timeSlotBookings: TimeSlotBookings;
  public expectedBookings: Array<ReservationsAgregate>;
  public currentDay: moment.Moment = this.day.startOf("day");
  public timeSlot: moment.Moment = this.day.startOf("day");
  public startOfPeriod: moment.Moment = this.day.startOf("day");
  resourceUrl: string = "krsvag";
  public resource$: Observable<BookableResource>;
  public resource: BookableResource;
  resourceService: ResourceService;
  public resources: ResourceTimeSchedule[] = [];
  public resourceFilter:ResourceTimeSchedule;
  constructor(
    private timeScheduleService: TimeScheduleService,
    private bus: ServiceBusService,
    private adalService: AdalService
  ) {
    this.resourceService = initResourceService(this.adalService);
  }
  
  ngOnInit() {
    this.resource$ = this.bus.chanelObservable(Chanels.RESOURCE);
    this.resource$.subscribe((r) => {
      if (!r) return;
      this.resource = r;
      
      
      this.resourceService
        .getResources(this.resource.id)
        .then((resources:Resource[])=>{
          this.resources = resources.map(x=>ResourceTimeSchedule.deSerialize(x));
          if(this.resources.length>0)
            this.resourceFilter = this.resources[0];
            this.getMonthCapacity(this.resourceFilter);
        })
        .catch(console.error);;
    });
  }
  

  toggleResource(resource: ResourceTimeSchedule) {
    this.resourceFilter = resource;
    this.getMonthCapacity(this.resourceFilter);
  }
  isSelectedResource(resource: ResourceTimeSchedule) {
    return this.resourceFilter === resource;
  }
  private getMonthCapacity(resource: ResourceTimeSchedule) {
    this.timeScheduleService.getAllReservations(resource.id,this.startOfPeriod).subscribe((x:ReservationsAgregate[])=>{
      var reservations = x.map(r=>r.reservations.find(q=>q.referenceId == resource.id))??[] as Reservation[];
      var resMap = reservations.map(ReservationNew.mapReservation).reduce((a,r)=>{
        const dayKey = CalendarTool.formatDate(r.startTime);
        if(!a[dayKey]) a[dayKey] = [];
        a[dayKey].push(r);
        return a;
      },{});
      Object.keys(resMap).forEach(key=>{
        const dayReservations = resMap[key];
        const startTime = dayReservations[0]?.startTime;
        startTime && resource.setReservations(startTime,resMap[key]);
      });      
      this.monthCapacity = resource.getMonthCapacity(this.currentDay);
      this.getDay(resource.id);
    });

  }

  private showTimeslot(timeSlotStart: moment.Moment, referenceId: string) {
    if (!timeSlotStart) {
      this.timeSlot = null;
      this.expectedBookings = new Array<ReservationsAgregate>();
      return;
    }
    this.timeSlot = timeSlotStart;

    this.timeScheduleService
      .getTimeslotBookings(timeSlotStart, referenceId)
      .subscribe((timeslots) => {
        this.timeSlotBookings = timeslots;
        this.expectedBookings = timeslots.getUnRegistered();
      });
  }

  private getDay(referenceId: string) {
    this.dayCapacity = this.monthCapacity.get(
      this.currentDay.format("DD.MM.YYYY")
    );
    var timeSlot = null;
    this.dayCapacity.forEach((value) => {
      var now = this.day;
      if (this.currentDay.format("DD.MM.YYYY") != now.format("DD.MM.YYYY")) {
        now = this.currentDay;
      }
      var checkTimeSlot = value.startTime;
      if (!timeSlot && checkTimeSlot >= now && value.count > 0) {
        timeSlot = checkTimeSlot;
      }
    });
    this.showTimeslot(timeSlot, referenceId);
  }
  private getDayShort() {
    this.dayCapacity = this.monthCapacity.get(this.currentDay.format("dddd"));
  }
  public getTypeText(dayScheduleType: DayScheduleTypes) {
    return dayScheduleType == DayScheduleTypes.Regular ? "vanlig" : "bursdag";
  }

  onClickNavigate_before() {
    this.AddDays(-1);
  }

  onClickNavigate_next() {
    this.AddDays(1);
  }
  AddDays(days: number) {
    this.currentDay.add(days, "day");
    this.now = this.currentDay.clone().format("MMM DD YYYY");
    this.weeknumber = Number(this.currentDay.format("ww"));
    this.dayCapacity = this.monthCapacity.get(
      this.currentDay.format("DD.MM.YYYY")
    );
    if (!this.dayCapacity) this.getMonthCapacity(this.resourceFilter);
    else this.getDay(this.resourceFilter.id);
    // public weeknumber = Number(moment().format('ww'));
    // // public day= (moment().format('dddd'));
    // public day: moment.Moment = moment();

    // public now = moment.utc().format("MMM DD YYYY");
  }
}
