import { TimeIntervalInformation } from "../models/day-rule-time-interval.model";
import { PriceInfo } from "../time-scheduling/day-schedule.model";

export enum ActivityType {
  Regular = 0,
  Group = 1,
  Break = 2,
  PayPrResource = 3,
}
export enum ActivityPriceModel{
  AccumulateSubActivityCosts = 0,
  UseMainActivityCostOnly = 1,
}
export interface AddActivity {
  siteId: string;
  name: string;
  description: string;
  prittyUrl: string;
  minGroupSize: number;
  imageUrl: string;
  activityType: ActivityType;
  tags: string[];
  requiredResourceIds: string[];
  priceInfos: PriceInfo[];
  priceInfosExceptions: TimeIntervalInformation<PriceInfo[]>[];
  reminderEnabled: boolean;
  reminderDaysBefore: number;
  minInterval: number;
  concurrentStarts: number;
  isBirthday: boolean;
  bookableAsSubActivity: boolean;
  defaultSubActivityId?: string;
  priceModel: ActivityPriceModel;
}

export interface Activity extends AddActivity {
  id: string;
}
