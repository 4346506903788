import React, { useContext, useEffect, useState } from 'react';
import { Activity } from '../model';
import { ActivitiesContext } from '../ActivitiesReactWrapper';
import { initActivityService } from '../activityService';
import { ActivityCard } from './ActivityCard';
import { SearchableList } from '../../react-shared/SearchableList';

export const ActivitiesList = () => {
  const { adalService, siteId } = useContext(ActivitiesContext);

  const [activityService] = useState(initActivityService(adalService));

  const [activities, setActivities] = useState<Activity[]>([]);

  const fetchActivities = () => {
    if(!siteId) return;
    activityService.getActivities(siteId).then(products => setActivities(products));
  }
  const deleteActivity = (id: string) => {
    activityService.deleteActivity(id).then(() => fetchActivities());
  }

  useEffect(() => {
    fetchActivities();
  }, [siteId]);

  return (
    <>
      <SearchableList
        list={activities}
        renderElement={(u: Activity) => <ActivityCard key={u.id} activity={u} onDelete={deleteActivity} />}
      />
    </>
  );
}