<h1 mat-dialog-title>Kansellere Booking</h1>
<hr/>
<div mat-dialog-content>
    Vil du slette booking for mobile:<strong> {{ data.mobile }}</strong>
    <p>Referanse  : {{data.referenceNumber}}</p>  
    <p>Antall :{{data.count}} </p>
    <p>Pris :{{(data.count*data.unitPrice)}} </p>
    <p>Dato:  {{ data.start().local() | date:'dd.MM.yyyy' }} </p>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button color="warn" (click)="onNoClick()" cdkFocusInitial>Avbryt</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data">Slett booking</button>
</div>