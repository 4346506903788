import React from 'react';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import copy from 'copy-to-clipboard';
import { Product } from '../model';
import { ConfirmationDialog } from '../../react-shared/ConfirmationDialog';
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  product: Product;
  onDelete?: (id: string) => any;
}

export const ProductCard: React.FC<Props> = ({ product, onDelete = () => {} }) => {
  const nav = useNavigate();

  return (
    <Paper sx={{
      padding: '1em',
      marginTop: '1em'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">{product.name}</Typography>
        </div>
        <div>
          <Tooltip title="Copy ID">
            <IconButton onClick={() => copy(product.id)}>
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => nav(`/edit-product/${product.id}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <ConfirmationDialog
            dialogOpener={(
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            dialogTitle={`Are you sure that you want to delete ${product.name} product?`}
            dialogContent={`By doing this you are removing it from the system. Be aware of the fact that this action can not be undone.`}
            onConfirm={() => {
              onDelete(product.id);
            }}
          />
        </div>
      </div>
    </Paper>
  );
}