import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaverFromService } from './waver-from.service';
import { WaverPhotoComponent } from './waver-photo/waver-photo.component';
import { WaverFormComponent } from './waver-form/waver-form.component';
import { WaiverListComponent } from './waiver-list/waiver-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemModule } from '../system/system.module';
import { MaterialModule } from '../material.module';
import { WaiverSignatureFormComponent } from './waiver-signature-form/waiver-signature-form.component';
import { SignatureLogModule } from '../signature-log/signature-log.module';
import { MatInputModule } from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SystemModule,
    MaterialModule,
    SignatureLogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  declarations: [
    WaverFormComponent,
    WaverPhotoComponent,
    WaiverListComponent,
    WaiverSignatureFormComponent,
  ],
  providers: [
    WaverFromService
  ]
})
export class WaiverModule { }
