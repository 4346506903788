export const environment = {
  version: "1.3.0-99427",
  production: false,
  tenantId: "9C0C8951-B0B1-42B3-A638-07F765E20CC7",
  dependencies: {
    waiver_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    products_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    activities_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    resources_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    waiver_rules_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    signature_log_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    time_scheduling_api: {
      url: "https://api.skyland.no",
      domain: "api.skyland.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    waiver_text_api: { url: "https://api.skyland.no" },
    catalog_api: { url: "https://api.skyland.no" },
    entity_api: {
      url: "https://tapi.bmesh.io/entity",
      domain: "tapi.bmesh.io",
    },
    file_api: { url: 'https://tapi.bmesh.io/file', domain:"tapi.bmesh.io" },
    // waiver_api: {url: 'http://52.226.72.57', domain :'52.226.72.57'},
    // signature_log_api: {url: 'http://52.226.72.57', domain :'52.226.72.57'},
    // waiver_text_api:{url: ''}
  },
  config: {
    tenant: "577c80ff-449e-4fb3-897f-269d41e57e8b",
    //clientId: 'c0570187-e61a-46da-b197-1007ee995a7d',
    clientId: "300dc07f-ae46-466e-8e12-d1e68df3079e",
    redirectUri: window.location.origin,
    expireOffsetSeconds: 60,
    endpoints: {
      "https://api.skyland.no/api/":
        "300dc07f-ae46-466e-8e12-d1e68df3079e",
      "https://tapi.bmesh.io/entity/api/": "c0570187-e61a-46da-b197-1007ee995a7d",
      "https://tapi.bmesh.io/file/api/": "c0570187-e61a-46da-b197-1007ee995a7d",
      "http://localhost/api/": "300dc07f-ae46-466e-8e12-d1e68df3079e",
    },
    cacheLocation: "localStorage",
  },
};
