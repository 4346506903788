import React, { FC, useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
  MenuItem,
  IconButton,
} from "@mui/material";
import { WaiverRulesContext } from "../WaiverRulesReactWrapper";
import { initWaiverRulesService } from "../waiverRulesService";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {};

export const EditWaiverRulesForm: FC<Props> = () => {
  const { adalService } = useContext(WaiverRulesContext);
  const [waiverRulesService] = useState(initWaiverRulesService(adalService));

  const navigate = useNavigate();

  const [language, setLanguage] = useState<string>("");
  const [rules, setRules] = useState<string[]>([]);

  const { id } = useParams();

  const isAdd = !id;

  useEffect(() => {
    if (id) {
      waiverRulesService
        .getWaiverRules(id)
        .then((waiverRules) => {
          setLanguage(waiverRules.language);
          setRules(waiverRules.rules ?? []);
        })
        .catch(console.error);
    }
  }, [id]);

  const [alert, setAlert] = useState<{ open: boolean; success: boolean }>({
    open: false,
    success: false,
  });
  const setAlertOpen = (open: boolean) => {
    setAlert({
      ...alert,
      open,
    });
  };

  const handleRuleChange =
  (i: number) => (rule: string) => {
    rules[i] = rule;
    setRules([...rules]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = isAdd
      ? waiverRulesService.addWaiverRules({
          language,
          rules,
        })
      : waiverRulesService.updateWaiverRules({
          id,
          language,
          rules,
        });

    action
      .then(() => {
        setAlert({
          open: true,
          success: true,
        });
        navigate(`/waiver-rules`);
      })
      .catch((err) => {
        setAlert({
          open: true,
          success: false,
        });
        console.error(err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ padding: "1em" }}>
        <Typography variant="h5">
          {isAdd ? "Add New" : "Edit"} Waiver Rules
        </Typography>
        <Grid container>
          <Grid item sm={12} md={6}>
            <TextField
              label="Language"
              select
              variant="standard"
              value={language}
              fullWidth
              onChange={(e) => setLanguage(e.target.value)}
            >
              <MenuItem value={"no"}>
                Norsk
              </MenuItem>
              <MenuItem value={"en"}>
                English
              </MenuItem>
            </TextField>
          </Grid>
          {rules.map((rule, i) => (
            <Grid item key={i} sm={12} sx={{ marginTop: "2em", display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="Rule"
                variant="standard"
                value={rule}
                fullWidth
                multiline
                rows={3}
                onChange={(e) => handleRuleChange(i)(e.target.value)}
              />
              <IconButton onClick={() => setRules((prev) => [...prev.filter((_, j) => j !== i)])}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          ))}
          <Grid item sm={12} sx={{ marginTop: "2em", paddingRight: '2em' }}>
            <Button
              variant="contained"
              onClick={() => setRules(prev => [...prev, ""])}
              sx={{ float: "right" }}
            >
              Add Rule
            </Button>
          </Grid>
          <Grid item sm={12} sx={{ marginTop: "2em" }}>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alert.success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {alert.success ? "WaiverRules saved." : "Something went wrong."}
        </Alert>
      </Snackbar>
    </>
  );
};
