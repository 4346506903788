import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TimeScheduleService } from '../../time-scheduling/time-schedule.service';
import { GroupReservation } from '../../time-scheduling/group/GroupReservation';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { TimeSlot, Reservation } from '../../time-scheduling/timeslot.model';
import { DateSchedule } from '../../time-scheduling/date-schedule.model';
import { BookingFormService } from '../../time-scheduling/booking-form.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingFormComponent } from '../../time-scheduling/booking-form/booking-form.component';
import { DayScheduleTypes } from '../../time-scheduling/day-schedule.model';
import { CalendarTool } from '../../system/calendartool';
import { GbookingService } from '../../group-booking/gbooking.service';
import { CatalogService } from '../../group-booking/catalog.service';
import { Observable } from 'rxjs/Observable';
import { Product } from '../../group-booking/catalog/product';

@Component({
  selector: 'group-booking-edit-dialog',
  templateUrl: 'group-booking-edit.dialog.html',
})
export class GroupBookingEditDialog implements OnInit {
  reservation: Reservation;

  adminEditForm: FormGroup;
  public catalog$: Observable<Array<Product>>;
  public catalog: Array<Product> = new Array<Product>();
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GroupBookingEditDialog>,
    public timeScheduleService: TimeScheduleService,
    public bookingFormService: BookingFormService, private catalogService: CatalogService,
    private gbookingservice: GbookingService, @Inject(MAT_DIALOG_DATA) public data: GroupReservation) {

  }

  ngOnInit() {
    const reservation = this.data.reservation;
    this.adminEditForm = this.gbookingservice.getAdminEditFrom(this.data.extras);
    this.adminEditForm.patchValue({
      allergi: this.data.message
    });
    this.catalog$ = this.catalogService.getCatalog();
    this.catalog$.subscribe(catalog => {
      this.catalog = catalog;
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ok() {
    this.data.message = this.adminEditForm.controls.allergi.value;
    const extras = this.adminEditForm.controls.extras.value;
    this.data.extras = extras;
    this.timeScheduleService.putGroupReservation(this.data).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }

  get extras(): FormArray {
    return this.adminEditForm.get('extras') as FormArray;
  }

  get extraToAdd(): FormControl {
    return this.adminEditForm.get('extraToAdd') as FormControl;
  }

  deleteExtra(index: number) {
    const extra = this.extras.removeAt(index);
    this.extras.markAsDirty();
  }

  addExtra(event: Event) {
    this.extras.push(this.fb.group(this.extraToAdd.value));
  }

}
