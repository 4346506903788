<mat-toolbar class="app-toolbar pad-none" fxFlex fxLayout="row" fxfill>
  <div class="left-side resourses" fxLayout="row">
    <!-- <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button> -->
    <button mat-icon-button (click)="toggleMenu()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="current-resource text-md text-center">
      <span *ngIf="selectedResource">{{ selectedResource.name }}</span>
    </div>
    <button mat-icon-button (click)="onOpenResourceMenu()" *ngIf="hasResources">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <div class="right-side" fxFlex fxLayout="row">
    <div class="search-box pad-left-xs" fxFlex></div>
    <div
      class="user-controls text-md text-center"
      fxFlex="20"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <button mat-icon-button><mat-icon>notifications</mat-icon></button>
      <hr class="push-xs" style="height: 80%;" />
      <div class="display-name">
        {{ user?.adalUser.profile.given_name }}
        {{ user?.adalUser.profile.family_name }}
      </div>
      <button mat-icon-button [matMenuTriggerFor]="userMenu">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <div class="avatar">
        <img src="/assets/default-avatar.png" />
      </div>
    </div>
  </div>
  <mat-menu #userMenu>
    <button mat-menu-item (click)="logout($event)">Log out</button>
    <button mat-menu-item (click)="updateCache()">
      Version: {{ version }} Update App
    </button>
  </mat-menu>
</mat-toolbar>
