import React, { FC, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Theme } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import noLocale from "date-fns/locale/nb";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      position: 'relative',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginTop: '35px',
      display: 'inline-flex',
      '& label': {
        fontSize: '14px',
        position: 'absolute',
        top: '-30px',
        left: '10px',
        '& svg': {
          color: theme.palette.primary.main,
          marginLeft: '15px'
        }
      }
    },
    field: {
      padding: '0.2em 0.5em 0.2em 0.5em',
      margin: '0.2em 0.5em 0.2em 0.5em',
      border: '1px solid #fff',
      borderRadius: theme.shape?.borderRadius,
      fontSize: '14px',
      maxWidth: '150px',
    },
  })
);

type Props = {
  label?: JSX.Element;
  onChange?: (date: Date) => any;
  value?: Date|null;
};

export const DateInput: FC<Props> = ({ label, onChange, value: defaultValue }) => {
  const classes  = useStyles();
  const [value, setValue] = useState<Date | null>(defaultValue);

  useEffect(() => {
    value && onChange && onChange(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={classes.inputContainer}>
      <label>{label}</label>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={noLocale}>
        <DesktopDatePicker
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          OpenPickerButtonProps={{
            className: ""
          }}
          className={classes.field}
          inputFormat="dd.MM.yyyy."
          mask="__.__.____."
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              className={""}
              InputProps={{ ...params.InputProps, className: "", disableUnderline: true }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
