<h1 mat-dialog-title>Endre tidspunkt</h1>
<div mat-dialog-content>
  <!-- <app-booking-form [schedule]="'birthday'" (select)="timeSlotSelected($event)" [resourceUrl]="resourceUrl"
    (bookingForm)="receiveBookignForm($event)" (monthSchedule)="setMonthSchedule($event)" #booking></app-booking-form> -->
  <app-booking-form-react [activityIds]="activityIds" [mainActivityId]="mainActivityId" (reservations)="setNewReservations($event)" [cancelations]="cancelations"></app-booking-form-react>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button color="warn" (click)="onNoClick()" cdkFocusInitial [disabled]="loading">Avbryt</button>
  <button mat-raised-button color="primary" (click)="ok()">
    <span *ngIf="!loading">Lagre Endringer</span>
    <mat-spinner *ngIf="loading" [diameter]="37" [color]="'warn'"></mat-spinner>
  </button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
