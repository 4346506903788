import { Component, OnInit, ViewChild } from "@angular/core";
import { Chanels } from "../../chanels";
import { ServiceBusService } from "../../system/service-bus.service";
import { TimeScheduleService } from "../../time-scheduling/time-schedule.service";
import { BookableResource } from "../../time-scheduling/bookable-resource";
import * as moment from "moment";
import {
  GroupReservation,
  GroupReservationFlat,
} from "../../time-scheduling/group/GroupReservation";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { map } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { Reservation, ReservationsAgregate } from "../../time-scheduling/timeslot.model";
import { DialogDeleteBookingComponent } from "../../time-scheduling/reservations/reservations.component";
import { GroupBookingEditDialog } from "../../bookingbirthday/group-booking-details/group-booking-edit.dialog";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { forkJoin, scheduled } from "rxjs";

import { environment } from "../../../environments/environment";
import { DayScheduleTypes } from "../../time-scheduling/day-schedule.model";
import { Activity } from "../../activities-react/model";
import { ActivityService, initActivityService } from "../../activities-react/activityService";
import { AdalService } from "adal-angular4";
import { Router } from "@angular/router";
import { WaverFromService } from "../../waiver/waver-from.service";
import { useDebounce } from "../../system/debounse";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MMMM YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-booking-overview-table",
  templateUrl: "./booking-overview-table.component.html",
  styleUrls: ["./booking-overview-table.component.scss"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class  BookingOverviewTableComponent implements OnInit {
  mobilePhoneWaiverSigns: { [key: string]: boolean } = {};
  resource: BookableResource;
  isLoadingResults = true;
  selectedDate = new FormControl(moment().startOf("month"));
  date = moment(this.selectedDate.value).startOf("month");
  columnDefs: {
    title: string;
    field: string;
    getValue: (res: ReservationsAgregate) => any;
  }[] = [
    { title: "Mobil", field: "mobile", getValue: (res) => res.mobile },
    { title: "Signed", field: "signed", getValue: (res) => {
        if(!res.mobile)
          return '';
        if(this.mobilePhoneWaiverSigns[res.mobile] === undefined)
          return 'Checking';
        return this.mobilePhoneWaiverSigns[res.mobile] ? 'Yes' : 'No';
      }
    },
    {
      title: "Ref nr",
      field: "referenceNumber",
      getValue: (res) => res.referenceNumber,
    },
    { title: "Antall", field: "count", getValue: (res) => res.count },
    {
      title: "Pris",
      field: "childNames",
      getValue: (res) => res.count * res.unitPrice,
    },
    {
      title: "Dato",
      field: "startTime",
      getValue: (res) => moment(res.startTime).format("DD/MM/YYYY"),
    },
    {
      title: "Klokken",
      field: "clock",
      getValue: (res) =>
        `${res.start().format("HH:mm")} - ${res.end().format("HH:mm")}`,
    },
    {
      title: "Status",
      field: "paiment",
      getValue: (res) => (
        this.isPaid(res.reservationId) ? "betalt" : ""),
    },
    {
      title: "Activities",
      field: "activityIds",
      getValue: (res) => this.getActivitiesText(res.activityIds),
    },
    { title: "Actions", field: "actions", getValue: null },
  ];

  displayedColumns: string[] = this.columnDefs.map((c) => c.field);

  private exportColumnDefs = this.columnDefs.slice(
    0,
    this.columnDefs.length - 1
  );

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<ReservationsAgregate>();
  toggleActivity(activity: Activity) {
    var index = this.activityFilter.indexOf(activity);
    if (index >= 0) {
      this.activityFilter = this.activityFilter.filter(
        (x) => x.id !== activity.id
      );
    } else {
      this.activityFilter.push(activity);
    }
    this.updateBookings();
  }
  isSelectedActivity(activity: Activity) {
    return this.activityFilter.findIndex(a => a.id === activity.id) >= 0;
  }
  allActivitiesFilter: Array<Activity> = [
  ];
  activityFilter: Array<Activity> = [];
  calculating = true;
  payments = [];
  payedIds = [];

  private activityService: ActivityService;
  private fetchedReservations: Reservation[];

  constructor(
    private bus: ServiceBusService,
    private timeScheduleService: TimeScheduleService,
    public dialog: MatDialog,
    private http: HttpClient,
    private adalService: AdalService,
    private router: Router,
    private waverService: WaverFromService
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.bus.chanelObservable(Chanels.RESOURCE).subscribe((r) => {
      if (r) {
        this.resource = r;
        this.getBookings();
        this.activityService = initActivityService(this.adalService);
        this.activityService.getActivities().then(activities => {
          this.allActivitiesFilter = activities.filter(a => a.siteId === r.id);
        });
      }
    });
    this.initFilter();
  }
  checkPayment(reservations: ReservationsAgregate[]): Observable<string[]> {
    var pageIndex = 0;
    var pageSize = 50;
    const reservationIds = reservations
      .map((x) => `reservationIds=${x.reservationId}`)
      .reduce(
        (acc, curr, currentIndex) => {
          if (currentIndex % pageSize === 0) {
            pageIndex++;
            acc.push("");
          }
          acc[pageIndex] += `${curr}&`;
          return acc;
        },
        [""]
      );
    var requests = reservationIds.map((ids) => {
      var apiUrl = environment.dependencies.time_scheduling_api.url;
      var url = `${apiUrl}/api/payments/isPaid?${ids}`;
      return this.http.get<string[]>(url);
    });
    return forkJoin(requests).map((results) => {
      var paiedIds = results.reduce((acc, curr) => {
        if (curr.length <= 0) return acc;
        return [...acc, ...curr];
      }, []);
      return paiedIds;
    });
  }
  private getBookings() {
    this.isLoadingResults = true;
    this.calculating = true;

    const start = this.date;
    const end = moment(this.date).endOf("month");
    this.timeScheduleService
      .getAllReservations(this.resource.id, start)
      .map((reservations: ReservationsAgregate[]) =>
        reservations
        .filter(r => !!this.allActivitiesFilter.find(activity => r.hasActivityId(activity.id)))
        .filter(
          (r: ReservationsAgregate) =>
            this.activityFilter.length === 0 ||
            !!this.activityFilter.find(activity => r.hasActivityId(activity.id))
        )
      )
      .take(1)
      .subscribe((response: ReservationsAgregate[]) => {
        this.dataSource.data = response.filter(
          (r) =>
            r.count > 0 &&
            start.isSameOrBefore(r.start()) &&
            end.isSameOrAfter(r.start())
        ,(error)=>{
          console.error(error);
          this.dataSource.data = [];
        });

        this.dataSource.connect().subscribe({
          next: r => this.waverService
            .confirmWaiverExist([...new Set(r.map(x => encodeURIComponent(x.mobile)))].filter(x => !!x))
            .subscribe({
              next: (r) => {
                r.contractsExists.forEach(c => {
                  this.mobilePhoneWaiverSigns[decodeURIComponent(c.phone)] = c.contractExists;
                })
              }
            })
        });

        this.isLoadingResults = false;
        this.calculating = true;
        this.payments = [];
        const bookedThisMonth = response.filter(
          (r) =>
            r.count > 0 &&
            start.isBefore(r.occuredTime) &&
            end.isAfter(r.occuredTime)
        );
        // const reservationIds = bookedThisMonth
        //   .map((x) => `reservationIds=${x.reservationId}`)
        //   .reduce((acc, curr) => {
        //     acc += `${curr}&`;
        //     return acc;
        //   }, "");
        this.checkPayment(bookedThisMonth).subscribe((payments) => {
          this.payedIds = payments;
          this.payments = bookedThisMonth
            .filter((x) => {
              return payments.indexOf(x.reservationId) >= 0;
            })
            .map((r) => {
              return r.unitPrice * r.count;
            });
          this.calculating = false;
        });
      });
  }
  public isPaid(reservationId: string) {
    return this.payedIds.indexOf(reservationId) >= 0;
  }
  private bookingTypeMap: Map<DayScheduleTypes, string> = new Map<
    DayScheduleTypes,
    string
  >([
    [DayScheduleTypes.Regular, "vanlig"],
    [DayScheduleTypes.Birthday, "bursdag"],
    [DayScheduleTypes.VR, "VR"],
  ]);
  public getTypeText(scheduleType: DayScheduleTypes) {
    return this.bookingTypeMap.get(scheduleType) || "NA";
  }
  public getActivitiesText(activityIds: string[]) {
    return activityIds.map(id => this.allActivitiesFilter.find(a => a.id === id)?.name).join(', ');
  }
  public get sumPaidMonth() {
    return this.payments.reduce((acc, c) => acc + c, 0);
  }
  private updateBookings() {
    this.date = moment(this.selectedDate.value).startOf("month");
    this.getBookings();
  }
  private runFilter: any;
  private initFilter(){
    this.runFilter = useDebounce((filterValue:string)=>{
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    },500);
  }
  applyFilter(filterValue: string) {
    this.runFilter(filterValue.trim().toLowerCase());
  }

  chosenMonthHandler(
    normalizedDate: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.selectedDate.value;
    ctrlValue.year(normalizedDate.year());
    ctrlValue.month(normalizedDate.month());
    this.selectedDate.setValue(ctrlValue);
    datepicker.close();
    this.updateBookings();
  }

  export() {
    let content = [this.exportColumnDefs.map((c) => c.title).join(";")];
    const lines = this.dataSource.data.map((v) => {
      return this.exportColumnDefs
        .map((c) => {
          let value = c.getValue(v);
          if (typeof value === "string" && value.indexOf(",") > -1) {
            value = `"${value}"`;
          }
          return value;
        })
        .join(";")
      }
    );
    content = content.concat(lines);
    this.saveFile(
      content.join("\r\n"),
      `bursdags-oversikt-export_${this.date.format("MM_Y")}.csv`
    );
  }

  saveFile(content: string, filename: string) {
    const universalBOM = "\uFEFF";
    const blob = new Blob([universalBOM + content], {
      type: "text/csv;charset=utf-8;",
    });
    if (((navigator as any) as any).msSaveBlob) {
      // IE 10+
      ((navigator as any) as any).msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  onEditBookingClick(reservation: Reservation) {
    this.router.navigate([`/resource/${this.resource.prittyUrl}/bursdag/${reservation.reservationId}`])
  }

  onCancelBookingClick(reservation: ReservationsAgregate) {
    const dialogRef = this.dialog.open(DialogDeleteBookingComponent, {
      data: reservation,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.timeScheduleService
          .cancelMany(reservation.reservations)
          .subscribe((cancelation) => {});
        }
    });
  }
}
