import { Guid } from "../../system/guid";
import { Product, ProductItem } from "../../group-booking/catalog/product";
import { Reservation } from "../timeslot.model";
import * as moment from 'moment';


export class GroupReservation {
    public id: string;

    public reservationDate: moment.Moment;

    public details: any;

    public extras: Array<ProductItem>;

    public message: string;

    public reservationId: string;

    public reservation: Reservation;

    public reservations?: Reservation[];
}

export class GroupReservationFlat {
    public _original: GroupReservation;
    public mobile: string;
    public referenceNumber: string;
    public fullname: string;
    public email: string;
    public childNames: string;
    public childCount: number;
    public startTime: string;
    constructor(res: GroupReservation) {
        this._original = res;
        if (res.details) {
            this.mobile = res.details.mobile;
            this.referenceNumber = res?.reservation?.referenceNumber;
            if (res.details.contactPerson) {
                this.fullname = res.details.contactPerson.firstname + ' ' + res.details.contactPerson.lastname;
            }
            this.email = res.details.email;
            if (res.details.children) {
                this.childNames = res.details.children ? res.details.children.map(child => (child.firstname || '').trim()).join(', ') : '';
                this.childCount = res.details.children ? res.details.children.length : 0;
            }
            this.startTime = moment(res.reservation.startTime).format('DD.MM.Y');
        }
    }
}
