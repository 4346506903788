import { Component, OnInit, ViewChild } from '@angular/core';
import { Chanels } from '../../chanels';
import { ServiceBusService } from '../../system/service-bus.service';
import { TimeScheduleService } from '../../time-scheduling/time-schedule.service';
import { BookableResource } from '../../time-scheduling/bookable-resource';
import * as moment from 'moment';
import { GroupReservation, GroupReservationFlat } from '../../time-scheduling/group/GroupReservation';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { GroupBookingEditDialog } from '../group-booking-details/group-booking-edit.dialog';
import { DialogDeleteBookingComponent } from '../../time-scheduling/reservations/reservations.component';
import { BookingDetailsDialogComponent } from '../group-booking-details/dialog-booking-details.dialog';
import { WaverFromService } from '../../waiver/waver-from.service';
import { useDebounce } from '../../system/debounse';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-booking-overview-table',
  templateUrl: './birthday-booking-overview-table.component.html',
  styleUrls: ['./birthday-booking-overview-table.component.scss'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}]
})
export class BirthdayBookingOverviewTableComponent implements OnInit {
    mobilePhoneWaiverSigns: { [key: string]: boolean } = {};
  weeks: any[] = []
  resource: BookableResource;
  isLoadingResults = true;
  selectedDate = new FormControl(moment());
  date = moment(this.selectedDate.value).startOf('month');
  columnDefs: {title: string, field: string, getValue: (res: GroupReservationFlat) => any}[] = [
    {title: 'Mobil', field: 'mobile', getValue: (res) => res.mobile },
    { title: "Signed", field: "signed", getValue: (res) => {
        if(!res.mobile)
          return '';
        if(this.mobilePhoneWaiverSigns[res.mobile] === undefined)
          return 'Checking';
        return this.mobilePhoneWaiverSigns[res.mobile] ? 'Yes' : 'No';
      }
    },
    {title: 'Kontakt', field: 'fullname', getValue: (res) => res.fullname },
    {title: 'Epost', field: 'email', getValue: (res) => res.email },
    {title: 'Barn navn', field: 'childNames', getValue: (res) => res.childNames },
    {title: 'Antall bursdagsbarn', field: 'childrenCount', getValue: (res) => res.childCount },
    {title: 'Dato', field: 'startTime', getValue: (res) => res.startTime },
    {title: 'Actions', field: 'actions', getValue: null}
  ];

  displayedColumns: string[] = this.columnDefs.map(c => c.field);

  private exportColumnDefs = this.columnDefs.slice(0, this.columnDefs.length - 1);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSource = new MatTableDataSource<GroupReservationFlat>();

  constructor(
    private bus: ServiceBusService,
    private timeScheduleService: TimeScheduleService,
    public dialog: MatDialog,
    private waverService: WaverFromService) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.bus.chanelObservable(Chanels.RESOURCE).subscribe(r => {
      if (r) {
        this.resource = r;
        this.getBookings();
        this.updateWeeks();
      }
    });
    this.initFilter();
  }

  private getBookings() {
    this.isLoadingResults = true;
    this.timeScheduleService.getGroupReservations(this.date, this.resource.id)
    .pipe(map(( response: GroupReservation[] ) => {
      return response.filter(gr => gr && gr.reservation && moment(gr.reservation.startTime).month() === this.date.month())
      .map( gres => new GroupReservationFlat(gres));
    }))
    .take(1)
    .subscribe(( response: GroupReservationFlat[] ) => {
      this.dataSource.data = response;
      this.dataSource.connect().subscribe({
        next: r => this.waverService
          .confirmWaiverExist([...new Set(r.map(x => encodeURIComponent(x.mobile)))].filter(x => !!x))
          .subscribe({
            next: (r) => {
              r.contractsExists.forEach(c => {
                this.mobilePhoneWaiverSigns[decodeURIComponent(c.phone)] = c.contractExists;
              })
            }
          })
        });
        this.isLoadingResults = false;
      },
      (error)=>{
        console.error(error);
        this.dataSource.data = [];
        this.isLoadingResults = false;
      });
  }

  private updateBookings() {
    this.date = moment(this.selectedDate.value).startOf('month');
    this.getBookings();
  }
  private runFilter: any;
  private initFilter(){
    this.runFilter = useDebounce((filterValue:string)=>{
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    },500);
  }
  applyFilter(filterValue: string) {
    this.runFilter(filterValue.trim().toLowerCase());
  }

  updateWeeks() {
    const weeks = [];
    const month = moment(this.selectedDate.value).month()
    let lastDay = moment(this.selectedDate.value).endOf('month').endOf('week');
    while (weeks.length === 0 || lastDay.month() === month) {
      weeks.push({
        end: lastDay,
        start: moment(lastDay).subtract(1, 'week').add(1, 'millisecond')
      });
      lastDay = moment(lastDay).subtract(1, 'week')
    }
    this.weeks = weeks;
  }

  chosenMonthHandler(normalizedDate: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.selectedDate.value;
    ctrlValue.year(normalizedDate.year());
    ctrlValue.month(normalizedDate.month());
    this.selectedDate.setValue(ctrlValue);
    datepicker.close();
    this.updateBookings();
    this.updateWeeks();
  }


  export(data: GroupReservationFlat[] = this.dataSource.data, fileName: string = `bursdags-oversikt-export_${this.date.format('MM_Y')}.csv`) {
    const titles = [...this.exportColumnDefs.map(c => c.title), 'Products']
    let content = [titles.join(';')];
    const lines = data.map(v => {
      const productItemsColumn = v._original.extras.filter(x => x.name || x.description)
        .map(x => `${x.name || ''} ${x.description || ''} - ${x.prOrder ? 'Per Order' : ''}`).join(', ');
      const columns = this.exportColumnDefs.map(c => {
        let value = c.getValue(v);
        if ((typeof(value) === 'string') && value.indexOf(',') > -1 ) {
          value = `"${value}"`;
        }
        return value;
      });
      return [...columns, productItemsColumn].join(';')
    });
    content = content.concat(lines);
    this.saveFile(content.join('\r\n'), fileName);
  }

  exportFor(date: moment.Moment, dateTo: moment.Moment) {
    this.timeScheduleService.getGroupReservations(date, this.resource.id, dateTo)
    .pipe(map(( response: GroupReservation[] ) => {
      return response.filter(gr => gr && gr.reservation && moment(gr.reservation.startTime).month() === this.date.month())
      .map( gres => new GroupReservationFlat(gres));
    }))
    .subscribe(( response: GroupReservationFlat[] ) => {
      this.export(response, `bursdags-oversikt-export_${date.format('D.MM')}-${dateTo.format('D.MM')}.csv`)
    },
    (error)=>{
      console.error(error);
    });
  }

  saveFile(content: string, filename: string) {
    const universalBOM = '\uFEFF';
    const blob = new Blob([universalBOM + content], { type: 'text/csv;charset=utf-8;' });
    if ((navigator as any).msSaveBlob) { // IE 10+
        (navigator as any).msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  onEditBookingClick(reservationFlat: GroupReservationFlat) {
    const dialogRef = this.dialog.open(GroupBookingEditDialog, {
      data: reservationFlat._original
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Do nothing
      }
    });
  }

  onChangeBookingTimeClick(reservationFlat: GroupReservationFlat) {
    const dialogRef = this.dialog.open(BookingDetailsDialogComponent, {
      data: reservationFlat._original
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBookings();
      }
    });
  }

  onCancelBookingClick(reservationFlat: GroupReservationFlat) {
    const dialogRef = this.dialog.open(DialogDeleteBookingComponent, {
      data: reservationFlat._original
    });
    dialogRef.afterClosed().subscribe(reservation => {
      if (reservation) {
        this.timeScheduleService.cancel(reservation).subscribe((cancelation) => {
          this.getBookings();
        });
      }
    });
  }



}
