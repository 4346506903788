import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeScheduleService } from '../time-schedule.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { DayScheduleTypes, DaySchedule, PriceInfo } from '../day-schedule.model';
import * as moment from 'moment';
import { BookableResource } from '../bookable-resource';
import { Guid } from '../../system/guid';
import { FormAction, DialogAction } from '../../system/const';

@Component({
  selector: 'app-open-hour-form-dialog',
  templateUrl: 'open-hour-form.dialog.html',
  styleUrls: ['./open-hour-form.dialog.scss']
})
export class OpenHourFormDialogComponent implements OnInit {

  scheduleType: number;
  scheduleTypeText: String;
  scheduleId: string;
  daySchedule: DaySchedule;
  dayForm: FormGroup;
  minDate: moment.Moment;
  hours: Array<number> = Array(24).fill(0).map((x, y) => x + y);
  minutes: Array<number> = Array(4).fill(0).map((x, y) => y * 15);
  durations: Array<number> = Array(20).fill(1).map((x, y) => y * 30);
  public resource: BookableResource;
  days: Array<any> = Array(7).fill(0).map((x, y) => {
    const day = x + y;
    return { value: day, display: moment().weekday(day).format('dddd') };
  });
  get selectedScheduleType(): string {
    if (!this.daySchedule) {
      return '';
    }
    return DaySchedule.DayScheduleTypesText[this.daySchedule.scheduleType];
  }

  private formAction: string;

  constructor(
    public dialogRef: MatDialogRef<OpenHourFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private timeScheduleService: TimeScheduleService,
    private router: Router,
    private fb: FormBuilder) {
  }



  ngOnInit() {
    this.scheduleType = this.data['type'];
    if (this.scheduleType === undefined) {
      this.dialogRef.close({message: 'Type is not defined'});
      return;
    }
    this.scheduleId = this.data['scheduleId'];
    this.resource = this.data['resource'] as BookableResource;
    this.formAction = this.data['action'];

    this.scheduleTypeText = DaySchedule.DayScheduleTypesText[this.scheduleType];
    if (this.formAction === FormAction.ADD) {
      this.minDate = moment();
      this.newDaySchedule(this.resource, this.scheduleType);
    } else if (this.formAction === FormAction.COPY) {
      this.timeScheduleService.getDaySchedule(this.scheduleId).subscribe(d => {
        this.daySchedule = DaySchedule.deSerialize({ ...d, id: Guid.newGuids() });
        this.dayForm = this.daySchedule.getForm(this.fb);
      });
    } else if (this.formAction === FormAction.EDIT && this.scheduleId) {
      this.timeScheduleService.getDaySchedule(this.scheduleId).subscribe(d => {
        this.daySchedule = d;
        this.dayForm = this.daySchedule.getForm(this.fb);
      });
    }
  }

  newDaySchedule(resource: BookableResource, type: DayScheduleTypes) {
    if (type === DayScheduleTypes.Birthday) {
      this.daySchedule = new DaySchedule(
        Guid.newGuids(),
        [0, 1, 2, 3, 4],
        { hour: 10, minute: 0 },
        { hour: 20, minute: 0 },
        100,
        resource.id,
        resource.tenantId,
        15, // interval
        [
          new PriceInfo(120, 'Bursdag 2 timer', 214, ''),
          new PriceInfo(90, 'Kun hopp 1,5 timer', 199, ''),
        ], // priceInfos
        type, // schedule type
        2,  // concurrentReservations
        null, // validFrom
        null // validTo
      );

    } else  if (this.scheduleType === DayScheduleTypes.Regular) {

      this.daySchedule = new DaySchedule(
        Guid.newGuids(),
        [0, 1, 2, 3, 4],
        { hour: 10, minute: 0 },
        { hour: 20, minute: 0 },
        100,
        resource.id,
        resource.tenantId,
        15, // interval
        [
          new PriceInfo(60, '1 time', 155, ''),
          new PriceInfo(120, '2 time', 249, ''),
          new PriceInfo(180, '3 time', 343, ''),
          new PriceInfo(240, '4 time', 437, ''),
        ], // priceInfos
        type, // schedule type
        0,  // concurrentReservations
        null, // validFrom
        null // validTo
      );
    }

    this.dayForm = this.daySchedule.getForm(this.fb);
  }
  get priceInfos(): FormArray {
    return this.dayForm.get('priceInfos') as FormArray;
  }
  addPriceInfo() {
    this.priceInfos.push(this.fb.group(new PriceInfo(30, '', 0)));
  }
  deletePriceInfo(index) {
    this.priceInfos.removeAt(index);
    this.priceInfos.markAsDirty();
  }
  getLevelUp() {
    const route = this.router.url.split('/');
    return [route[1]];
  }
  deleteDaySchedule() {
    this.timeScheduleService.deleteDaySchedule(this.daySchedule)
      .subscribe(x => {
        this.dialogRef.close({
          action: DialogAction.DELETE,
          type: this.scheduleType,
          data: x
        });
      });
  }
  getDayScheduleValues() {
    return Object.keys(this.daySchedule).map(x => {
      return { name: x, value: this.daySchedule[x] };
    });
  }

  onSaveClick(): void {
    if (this.dayForm.invalid) {
      return;
    }
    const daySchedule = DaySchedule.deSerialize(this.dayForm.value);
    this.timeScheduleService.putDaySchedule(daySchedule).subscribe(x => {
      this.dialogRef.close({
        action: DialogAction.SAVE,
        type: this.scheduleType,
        data: x
      });
    });
  }

  onCancelClick(): void {
    this.dialogRef.close({
      action: DialogAction.CANCEL,
      type: this.scheduleType
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close({
      action: DialogAction.CLOSE,
      type: this.scheduleType
    });
  }
}
