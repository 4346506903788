import moment from "moment";
import { CalendarTool } from "../../system/calendartool";
import { Guid } from "../../system/guid";
import { Activity, ActivityType } from "./activity.model";
import { Reservation, TimeSlot } from "./timeslot.model.new";

export type TimeSlotReservations = {
  timeSlot: TimeSlot;
  reservations: Reservation[];
}

// Get the timeslots that are available for all required resources.
export function getResourceAvailableTimeSlots(timeSlots: TimeSlot[], intersectionCountTarget: number) {
  const intersectionCount= new Map<string,number>();
  const intersectionList = new Array<TimeSlot>();
  timeSlots.forEach(x => {
    const timeKey = CalendarTool.formatDateTime(x.startTime);
    if (!intersectionCount.has(timeKey)){
      intersectionCount.set(timeKey,1);
    }
    else {
      const count =  intersectionCount.get(timeKey)!;
      intersectionCount.set(timeKey, count + 1);
    }
    const count =  intersectionCount.get(timeKey)!;

    if (count >= intersectionCountTarget){
      intersectionList.push(x);
    }
});
  return intersectionList;
}

function permutation<T>(array: T[]) {
  function p(array: T[], temp: T[]) {
    var i, x;
    if (!array.length) {
      result.push(temp);
    }
    for (i = 0; i < array.length; i++) {
      x = array.splice(i, 1)[0];
      p(array, temp.concat(x));
      array.splice(i, 0, x);
    }
  }

  var result: T[][] = [];
  p(array, []);
  return result;
}

const SUB_ACTIVITY_DURATION = 30;
const MAX_NUMBER_OF_ADDITIONAL_TIMESLOTS = 3;

export const getMaxNumberOfActivitiesFor = (timeSlot: TimeSlot) => {
  return timeSlot?.splitOn(SUB_ACTIVITY_DURATION)?.length ?? 0;
}

export const getAvailableTimeSlotsFor = (mainActivityId: string, activities: Activity[], reservations: Reservation[], count: number, duration: number, date: Date, price: number): TimeSlotReservations[] => {
  const updateReservations = (res: Reservation[]) => activities
    .forEach(a => a.setReservationsToResources(moment(date), res));
  activities = [...activities];
  
  var maxGroupSize = activities.reduce((acc,a)=> Math.min(acc,a.capacity()),count);
  var numberOfGroups = Math.ceil(count/maxGroupSize);
  var groupSize = Math.ceil(count/numberOfGroups);
  if(numberOfGroups > MAX_NUMBER_OF_ADDITIONAL_TIMESLOTS) return [];
  const numberOfSlotsInTimeslot = Math.ceil(duration / SUB_ACTIVITY_DURATION);
  const existingReservations = reservations;
  updateReservations(existingReservations);
  var searchDate = moment(date);
  const mainActivity = activities.find(a => a.id === mainActivityId);
  const defaultActivity = mainActivity?.defaultSubActivity;
  const breakActivity = activities.find(x=>x.activityType === ActivityType.Break);
  while(defaultActivity !== undefined && activities.length < numberOfSlotsInTimeslot + 1){
    activities.push(defaultActivity);
  }  

  const mainActivityTimeSlots = mainActivity?.getVacantTimeSlots(searchDate, count, duration) ?? [];

  function getSlotNumber(startTime:moment.Moment, slotTime:moment.Moment){
    let minutesInTimeslot = moment.duration(slotTime.diff(startTime)).asMinutes();
    var slotNumber = minutesInTimeslot/SUB_ACTIVITY_DURATION;
    return slotNumber;
  }
  function getTimeSlotAvailability (allActivitiesVacanciesForDay: Array<any>, timeSlot: TimeSlot, breakSlotAlocatedNumber: number = -1) : Array<any> {
    return allActivitiesVacanciesForDay.reduce((acc: any, curent: any) => {
      
      var inTimeslot = [];
      for(var i=0;i < curent.timeSlots.length;i++){
        if(!curent.timeSlots[i].isInInterval(timeSlot)) continue;
        let startTime = curent.timeSlots[i].start() as moment.Moment;
        var slotNumber = getSlotNumber(timeSlot.start(),startTime);
        if(slotNumber%1 != 0) continue;
        if(breakActivity && curent.id === breakActivity.id){
          if(slotNumber === 0 || slotNumber === numberOfSlotsInTimeslot-1) continue;
          if(breakSlotAlocatedNumber >= 0) continue;
        }
        inTimeslot.push({timeSlot:curent.timeSlots[i],slotNumber});
      }
      acc.push({activity:curent.activity,slotRequirementId:Guid.newGuid(),availableInTimeslot:inTimeslot});
      return acc;
    },[]);
  }

  function allocateSlots(timeSlotAwailabillety:Array<any>,breakSlotAlocatedNumber:number=-1) : {slots:Array<any>,isTimeslotAvailable:boolean}{
    const numberOfSlotsToAllocate = timeSlotAwailabillety.length - (breakSlotAlocatedNumber<0?0:1);
    var combinationSlots = Array<any>(timeSlotAwailabillety.length);
    var isTimeslotAvailable = activities.length === 1;
    var usedIndex =[];
    if(breakSlotAlocatedNumber >= 0){
      timeSlotAwailabillety = timeSlotAwailabillety.map(x=>(
        {
          ...x,
          availableInTimeslot:x.availableInTimeslot.filter((s:any)=>s.slotNumber !== breakSlotAlocatedNumber)
        }));
    }
    for(var slotIndex = 0;slotIndex < combinationSlots.length; slotIndex++ ){
      
      var foundSlot = false;
      for(var activityIndex = 0;activityIndex < timeSlotAwailabillety.length;activityIndex++){
        if(usedIndex.indexOf(activityIndex)>=0) continue;
        var awailabillety = timeSlotAwailabillety[activityIndex];
        const slot = awailabillety.availableInTimeslot.find((q:any)=> q.slotNumber === slotIndex);
        
        if(slot){
          combinationSlots[slotIndex] = {timeSlot:slot.timeSlot,activity:awailabillety.activity};
          usedIndex.push(activityIndex);
          foundSlot = true;
          break;
        }
      }
      isTimeslotAvailable = usedIndex.length === numberOfSlotsToAllocate;
    }
    return {slots:combinationSlots,isTimeslotAvailable};
  }
  function getReservationCount(activity: any){
    let reservationCount =  groupSize;
    if(breakActivity && activity.id === breakActivity.id){
      reservationCount = count;
    }
    return reservationCount;
  }
  function getActivitiesVacancies() : Array<any>{
    return activities.filter(a => a.id !== mainActivityId).reduce((acc:Array<any>,curent)=>{
      let reservationCount = getReservationCount(curent);
      const timeSlots = curent.getVacantTimeSlots(searchDate,reservationCount  ,SUB_ACTIVITY_DURATION);
      acc.push({id:curent.id,activity:curent,timeSlots:timeSlots});
      return acc;
    },[]);;
  }
  function getAvailableTimeSlotReservationsFor(): TimeSlotReservations[] { 
    
    var allActivitiesVacanciesForDay = getActivitiesVacancies();
    const timeslotReservations =  mainActivityTimeSlots.map(timeSlot => {
      updateReservations(existingReservations);     
      if(numberOfSlotsInTimeslot <= 1){
        var reservedTimeSlots = [timeSlot];
        const reservationId = Guid.newGuids();
        return {
          timeSlot,
          reservations: reservedTimeSlots.map(r => ({ ...r, reservationId }) as Reservation),
        }
      }
      

      var participantsAllocated = 0;
      var subActivityReservations = new Array<Reservation>();
      let breakSlotnumber = -1;

      while(participantsAllocated < count){ 
        // Get awailabillety in timeslot
        var timeSlotAwailabillety =  getTimeSlotAvailability(allActivitiesVacanciesForDay,timeSlot,breakSlotnumber)
        const possibleCombination = filterSingles(timeSlotAwailabillety);
        // if(!possibleCombination) return null;
        timeSlotAwailabillety = sort(timeSlotAwailabillety);
        const {slots : slots, isTimeslotAvailable} = allocateSlots(timeSlotAwailabillety,breakSlotnumber);
        if(!isTimeslotAvailable) return null;
        participantsAllocated += groupSize;
        const groupReservations = slots.map(x=>{
          const activity = x.activity as Activity;
          let reservationCount = getReservationCount(activity);
          return activity.createReservations(x.timeSlot, activity.getPriceInfoForSubActivity(x.timeSlot.startTime)?.price ?? 0, reservationCount, "");
        }) as Reservation[][];
        subActivityReservations = subActivityReservations.concat([].concat(...groupReservations));
        
        if(participantsAllocated < count) {
          if(breakActivity && breakSlotnumber < 0){
            const breakTimeSlot = subActivityReservations.find(x=> x.referenceId === breakActivity.id);
            breakSlotnumber = getSlotNumber(timeSlot.startTime,breakTimeSlot!.startTime);
          }
          updateReservations([...existingReservations,...subActivityReservations]);
          allActivitiesVacanciesForDay = getActivitiesVacancies();
        }
      }
      
      const mainActivityReservations = mainActivity?.createReservations(timeSlot, price, count, "")??[];
      const mainActivityResources = mainActivityReservations.filter(x=>x.type === "Reservation").map(x=>x.referenceId);
      const subActivityFilterDuplicates = subActivityReservations.filter(x=>x && mainActivityResources.indexOf(x.referenceId) < 0);
      const allReservations = [...mainActivityReservations ,...subActivityFilterDuplicates];
      const reservationId = Guid.newGuids();
      

      return {
        timeSlot,
        reservations: allReservations.map(r => ({ ...r, reservationId }) as Reservation),
      }
    })
    .filter(r => r != null)
    .map(r =>{
      return r as TimeSlotReservations
    });
    return timeslotReservations;
  }

  function filterSingles(x:Array<any>): boolean{
    var hasFilterdSingles = false;
    var singleInstanceCount = 0;
    var sameRequirementSlotCount = 0;
    for(var i=0;i<x.length;i++){
      var activity = x[i];
      var singleInstanceIndexes = [];
      sameRequirementSlotCount = Math.max(x.filter((q:any) =>
      q.slotRequirementId != activity.slotRequirementId ).length, sameRequirementSlotCount);
      for(var j=0;activity.availableInTimeslot.length > 1 && j < activity.availableInTimeslot.length;j++){
        const slot = activity.availableInTimeslot[j];
        const sameTimeslot =  x.find((q:any) =>
          q.slotRequirementId != activity.slotRequirementId 
          && q.availableInTimeslot.find((s:any)=> s.slotNumber === slot.slotNumber));
        const isSingle = !sameTimeslot;
        if(isSingle){
          // remove single slot
          singleInstanceIndexes.push(j);
        }
      }
      
      if(singleInstanceIndexes.length === 1){
        activity.availableInTimeslot = activity.availableInTimeslot.splice(singleInstanceIndexes[0],1);
        hasFilterdSingles = true;
      }else if(singleInstanceIndexes.length > 1){
        singleInstanceCount++;
      }
    }
    if(sameRequirementSlotCount > 0 && singleInstanceCount >= sameRequirementSlotCount){
      singleInstanceCount = singleInstanceCount - sameRequirementSlotCount;
    }
    if((numberOfSlotsInTimeslot - singleInstanceCount)  < x.length ) {
      return false;
    }
    if(hasFilterdSingles){
      filterSingles(x);
    }
    return true;
  }

  function sort(x:Array<any>): Array<any> {
    return x.sort((a:any,b:any)=>{
      const slotSum = (acc:number,curr:any)=>acc+curr.slotNumber;
      var sumA = a.availableInTimeslot.reduce(slotSum,0)
      var sumB = b.availableInTimeslot.reduce(slotSum,0)
      return sumA - sumB;
    });
  }
  
  return getAvailableTimeSlotReservationsFor();
}