import { Component, OnInit, NgZone } from "@angular/core";
import { Observable } from "rxjs";
import { AdalService } from "adal-angular4";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../environments/environment";
import { ServiceBusService } from "../system/service-bus.service";
import { Chanels } from "../chanels";
import { updateCache } from "../system/update-cache";


function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}


@Component({
  selector: "app-unauth",
  templateUrl: "./unauthpage.component.html",
  styleUrls: ["./unauthpage.component.scss"],
})
export class UnauthpageComponent implements OnInit {
  user$: Observable<any>;
  // hasMultipleSites: boolean = false;
  public siteName: String;
  public version = environment.version;

  constructor(
    private bus: ServiceBusService,
    private adalService: AdalService
  ) {}

  ngOnInit() {
    this.user$ = this.bus.chanelObservable(Chanels.USER_CHANEL);
    this.user$.subscribe((user) => {
    });
  }
  logout() {
    this.adalService.logOut();
  }
  login() {
    this.adalService.login();
  }
  updateCache() {
    updateCache();
  }
  clear() { 
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();
    window.location.href = "/";
  }
}
