import { AdalService } from "adal-angular4";
import axios from "axios";
import { environment } from "../../environments/environment";
import { setAxiosInterceptors } from "../react-shared/axios-utils";
import { AddProduct, Product } from "./model";

export const initProductService = (adalService: AdalService) => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = environment.dependencies.products_api.url;
  setAxiosInterceptors(axiosInstance, adalService);

  const getProducts = async (): Promise<Product[]> => {
    return (await axiosInstance.get(`/api/products`)).data;
  }

  const getProduct = async (productId: string): Promise<Product> => {
    return (await axiosInstance.get(`/api/products/${productId}`)).data;
  }

  const addProduct = async (product: AddProduct): Promise<Product> => {
    return (await axiosInstance.post(`/api/products`, product)).data;
  }

  const updateProduct = async (product: Product): Promise<Product> => {
    return (await axiosInstance.put(`/api/products/${product.id}`, product)).data;
  }

  const deleteProduct = async (productId: string): Promise<Product> => {
    return (await axiosInstance.delete(`/api/products/${productId}`)).data;
  }

  return {
    getProducts,
    getProduct,
    addProduct,
    updateProduct,
    deleteProduct,
  };
}