import { Component, OnInit, EventEmitter, Inject } from "@angular/core";

import { Observable } from "rxjs/Observable";

import * as moment from "moment";
import { CalendarTool } from "../../system/calendartool";
import { GroupReservation } from "../../time-scheduling/group/GroupReservation";
import { TimeScheduleService } from "../../time-scheduling/time-schedule.service";
import { range } from "rxjs/observable/range";
import { BehaviorSubject, forkJoin, combineLatest } from "rxjs";
import { BookingFormService } from "../../time-scheduling/booking-form.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BookableResource } from "../../time-scheduling/bookable-resource";
import { BookableResourceService } from "../../time-scheduling/bookable-resource.service";
import { ActivatedRoute } from "@angular/router";
import { Chanels } from "../../chanels";
import { ServiceBusService } from "../../system/service-bus.service";
import {
  Reservation,
  ReservationsAgregate,
} from "../../time-scheduling/timeslot.model";

import { flatMap, map, mergeMap } from "rxjs/operators";
import { DayScheduleTypes } from "../../time-scheduling/day-schedule.model";
import {
  ActivityService,
  initActivityService,
} from "../../activities-react/activityService";
import { AdalService } from "adal-angular4";
import { Activity, ActivityType } from "../../activities-react/model";
import { OVERVIEW_TAG } from "../../activities-react/components/EditActivityForm";
import { LoaderService } from "../../loader/loader.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "limitTo" })
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: string): string {
    let limit = args ? parseInt(args, 10) : 10;
    let trail = "...";

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

@Component({
  selector: "app-birthdaybooking-overview",
  templateUrl: "./birthdaybooking-overview.component.html",
  styleUrls: ["./birthdaybooking-overview.component.scss"],
})
export class BirthdaybookingOverviewComponent implements OnInit {
  public weeknumber = Number(moment().format("ww"));
  public now = moment(); //.format("MMM Do YY");
  public activeDays = [];

  public groupReservation$: Observable<Array<GroupReservation>>;
  public daySchedule$: Observable<Array<any>>;
  public vrDaySchedule$: Observable<Array<any>>;
  public joinDaySchedule$: Observable<Array<any>>;

  public dayRange$: BehaviorSubject<Array<moment.Moment>> = new BehaviorSubject<
    Array<moment.Moment>
  >([]);
  private weekDiff = 0;

  public resource$: Observable<BookableResource>;
  public resource: BookableResource;
  constructor(
    private timeScheduleService: TimeScheduleService,
    private aroute: ActivatedRoute,
    private bus: ServiceBusService,
    public dialog: MatDialog,
    private adalService: AdalService,
    public loaderService: LoaderService
  ) {}
  private firstDay: moment.Moment = moment().weekday(0);
  private activityService: ActivityService;
  private activitiesRegistry: { [key: string]: Activity } = {};

  ngOnInit() {
    this.activityService = initActivityService(this.adalService);
    this.activityService.getActivities().then((activities) => {
      activities.forEach(
        (activity) => (this.activitiesRegistry[activity.id] = activity)
      );
      this.resource$ = this.bus.chanelObservable(Chanels.RESOURCE);
      this.resource$.subscribe((r) => {
        if (!r) return;
        this.resource = r;
        this.getDaySchedules();

        this.showWeek();
      });
    });
  }
  private getDaySchedules() {
    this.joinDaySchedule$ = combineLatest([this.getReservations()]).map(
      (data) => {
        const [/*group, */ reservations] = data;
        return reservations;
      }
    );
    this.joinDaySchedule$.subscribe((x) => {
      console.log(x);
    });
  }

  private getReservations(): Observable<Array<any>> {
    return this.timeScheduleService
      .getAllReservations(
        this.resource.id,
        CalendarTool.justDate(this.firstDay)
      )
      .pipe(
        flatMap((reservations: ReservationsAgregate[]) => {
          return this.dayRange$.map((days: Array<moment.Moment>) => {
            this.activeDays = days.map((day) => {
              return day.format("dddd DD MMM");
            });
            return days.reduce((acc, day) => {
              var dayDayte = CalendarTool.justDate(day).format("DD.MM.YYYY");
              var daySchedules = reservations
                .filter((res) => {
                  var isDay =
                    CalendarTool.justDate(res.startTime).format("DD.MM.YYYY") ==
                    dayDayte;
                  var hasActivitiesIds = res.activityIds.reduce<boolean>(
                    (acc, activityId) =>
                      acc ||
                      this.activitiesRegistry[activityId]?.tags?.includes(
                        OVERVIEW_TAG
                      ),
                    false
                  );
                  return res && isDay && hasActivitiesIds;
                })
                .sort(
                  (a, b) =>
                    a.startTime.milliseconds() - b.startTime.milliseconds()
                )
                .map((res: ReservationsAgregate) => {
                  var activity = this.activitiesRegistry[res.referenceId];
                  let reservation = {
                    id: res.reservationId,
                    time: `${res.startTime.format("HH:mm")} - ${res
                      .end()
                      .format("HH:mm")}`,
                    count: res.count,
                    contact: "",
                    tlf: res.mobile,
                    message: "",
                    activityName: res.activityIds
                      .map(
                        (activityId) =>
                          this.activitiesRegistry[activityId]?.name ?? ""
                      )
                      .join(","),
                    groupReservation: {notGroup: activity.activityType != ActivityType.Group},
                    dayScheduleType: res.dayScheduleType,
                    unitPrice: res.unitPrice,
                    occuredTime: res.occuredTime.format("DD.MM.YYYY HH:mm"),
                  };

                  this.timeScheduleService
                    .getGroupReservationById(res.reservationId)
                    .pipe(
                      map((item: GroupReservation) => ({
                        id: item?.id,
                        message: item?.message,
                      }))
                    )
                    .subscribe((mappedItems: any) => {
                      reservation.groupReservation = mappedItems;
                      return reservation;
                    });

                  return reservation;
                });
              const dayObject = {
                details: `/resource/${
                  this.resource.prittyUrl
                }/bursdagdetails/${day.format("DDMMYYYY")}`,
                day: day.format("dddd DD MMM"),
                dato: day.format("DD MMM"),
                daySchedules: daySchedules,
              };
              acc.push(dayObject);
              return acc;
            }, new Array<any>());
          });
        })
      );
  }

  // private getGroupReservations(): Observable<Array<any>> {
  //   this.groupReservation$ = this.timeScheduleService.getGroupReservations(
  //     CalendarTool.justDate(this.firstDay),
  //     this.resource.id
  //   );
  //   return this.groupReservation$.pipe(
  //     flatMap((reservations: Array<GroupReservation>) => {
  //       return this.dayRange$.map((days: Array<moment.Moment>) => {
  //         return days.reduce((acc, day) => {
  //           var dayDayte = CalendarTool.justDate(day).format("DD.MM.YYYY");
  //           var daySchedules = reservations
  //             .filter(
  //               (res) =>
  //                 res.reservation &&
  //                 CalendarTool.justDate(res.reservation.startTime).format(
  //                   "DD.MM.YYYY"
  //                 ) == dayDayte && this.activitiesRegistry[res.reservation.referenceId]?.tags?.includes(OVERVIEW_TAG)
  //             )
  //             .map((res) => {
  //               return {
  //                 id: res.id,
  //                 time: res.reservation.startTime.format("HH:mm"),
  //                 count: res.reservation.count,
  //                 contact:
  //                   res.details?.contactPerson?.firstname +
  //                   " " +
  //                   res.details?.contactPerson?.lastname,
  //                 tlf: res.details?.mobile,
  //                 message: res.message,
  //                 activityName: this.activitiesRegistry[res.reservation.referenceId]?.name ?? ""
  //               };
  //             });
  //           const dayObject = {
  //             details: `/resource/${
  //               this.resource.prittyUrl
  //             }/bursdagdetails/${day.format("DDMMYYYY")}`,
  //             day: day.format("dddd DD MMM"),
  //             dato: day.format("DD MMM"),
  //             daySchedules: daySchedules,
  //           };
  //           acc.push(dayObject);
  //           return acc;
  //         }, new Array<any>());
  //       });
  //     })
  //   );
  // }

  private showWeek() {
    var date = moment().weekday(0).add(this.weekDiff, "weeks");
    range(0, 7)
      .map((day) => date.clone().add(day, "days"))
      .reduce((acc, day) => {
        acc.push(day);
        return acc;
      }, [])
      .subscribe((days) => {
        this.activeDays = days.map((day) => {
          return day.format("dddd DD MMM");
        });
        this.dayRange$.next(days);
      });
  }

  onClickNavigate_before() {
    this.weekDiff--;
    var date = moment().weekday(0).add(this.weekDiff, "weeks");
    if (date.isBefore(this.firstDay) && date.isBefore(moment().weekday(0))) {
      this.firstDay = date;
      this.showWeek();
      this.getDaySchedules();
    } else {
      this.showWeek();
    }
    this.weeknumber -= 1;
  }

  onClickNavigate_next() {
    this.weekDiff++;
    this.showWeek();
    this.weeknumber += 1;
  }
  openDialog(reserv: any): void {
    console.log(reserv);
    let dialogRef = this.dialog.open(DialogOverviewDialog, {
      // width: '250px',
      data: reserv,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.animal = result;
    });
  }
}

@Component({
  selector: "dialog-overview-dialog",
  templateUrl: "dialog-overview-dialog.html",
  styleUrls: ["./dialog-overview-dialog.scss"],
})
export class DialogOverviewDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
