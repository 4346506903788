import { Component, OnInit, Input } from "@angular/core";
import { BookableResourceService } from "../time-scheduling/bookable-resource.service";
import { ServiceBusService } from "../system/service-bus.service";
import { Chanels } from "../chanels";
import { BookableResource } from "../time-scheduling/bookable-resource";
import { AdalService } from "adal-angular4";
import { Observable } from "rxjs";
import { BUser } from "../system/buser";
import { MatSidenav, MatDrawer } from "@angular/material/sidenav";
import { SidebarService } from "../services";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { updateCache } from "../system/update-cache";

@Component({
  selector: "app-toolbar-menu",
  templateUrl: "./toolbar-menu.component.html",
  styleUrls: ["./toolbar-menu.component.scss"],
})
export class ToolbarMenuComponent implements OnInit {
  @Input()
  public sidenav: MatSidenav;
  @Input()
  public drawer: MatDrawer;
  public selectedResource: BookableResource;
  public user: BUser;
  hasResources = false;
  version: string = environment.version;
  constructor(
    private adalService: AdalService,
    private bus: ServiceBusService,
    private sidebarService: SidebarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.bus.subscribe(Chanels.RESOURCE, (r) => {
      this.selectedResource = r as BookableResource;
    });
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (!user) {
        return;
      }
      this.user = user;
    });

    this.sidebarService.resourcesLoaded$.subscribe((isLoaded: boolean) => {
      this.hasResources = isLoaded;
      this.toggleMenu();
      this.toggleMenu();
    });
  }
  onOpenResourceMenu() {
    this.sidebarService.openResourceMenu();
  }

  logout(event) {
    this.adalService.logOut();
  }
  toggleMenu() {
    this.sidenav && this.sidenav.toggle();
    this.drawer && this.drawer.toggle();
  }
  updateCache() {
    updateCache();
  }
}
