<div
  class="item-content"
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="0"
>
  <div fxFlex="50%" class="item-top">
    <div fxFlex="20%" fxLayout="row" fxLayout.xs="row" class="btn-group">
      <button (click)="onClickNavigate_before()">
        <i class="material-icons"> navigate_before </i>
      </button>
      <button (click)="onClickNavigate_next()">
        <i class="material-icons"> navigate_next </i>
      </button>
    </div>
    <div fxFlex="60%" fxLayout="column" fxLayout.xs="column" fxLayoutGap="0.5%">
      <div>Uke {{ weeknumber }}</div>
      <div>
        <span *ngIf="activeDays?.length > 0">
          {{activeDays?.[0]}} / {{activeDays?.[activeDays?.length - 1]}}
        </span>
      </div>
    </div>
    <div fxFlex="20%" class="today">
      <i class="material-icons icon"> today </i>
      <span class="text">{{ now | date : "dd.MM" }}</span>
    </div>
  </div>
  <div fxFlex="50%" class="item-reservation">
    <h3>
      Oversikt over bursdags reservasjoner for
      <b *ngIf="resource">{{ resource.name }}</b>
    </h3>
    <div *ngIf="loaderService.loading$ | async" class="loader-container">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div
      class="item-matcard"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutAlign="center"
    >
      <div
        *ngFor="let weekday of joinDaySchedule$ | async"
        class="weekday-item"
        fxFlex
      >
        <a [href]="weekday.details">{{ weekday.day }}</a>
        <div
          class="item-matcard"
          fxLayout="column"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutAlign="center"
        >
          <mat-card
            class="card-picture"
            *ngFor="let best of weekday.daySchedules"
            [ngClass]="{ vrBooking: best.groupReservation.notGroup }"
          >
            <mat-card-header
              ><mat-card-title class="mat-card-title">
                <span *ngIf="best.groupReservation.notGroup">{{best.activityName}} booking</span>
                <span *ngIf="!best.groupReservation.notGroup">Booking</span>
              </mat-card-title>
              <a
                [routerLink]="[
                  '/resource/' + resource.prittyUrl,
                  'bursdag',
                  best.id
                ]"
                *ngIf="!best.groupReservation.notGroup"
                routerLinkActive="router-link-active"
              >
                <mat-icon type="button">edit</mat-icon>
              </a>
            </mat-card-header>

            <mat-card-content
              fxLayout.gt-xs="column"
              fxLayout.xs="column"
              class="mat-card-content"
            >
              <span fxFlex class="text-black text-md align-center"
                ><mat-icon
                  ><span class="material-icons"> query_builder </span></mat-icon
                >KL {{ best.time }}</span
              >
              <span fxFlex class="text-black text-md align-center"
                ><mat-icon><span class="material-icons"> group </span></mat-icon
                >{{ best.count }}</span
              >
              <span
                fxFlex
                *ngIf="best.groupReservation?.message"
                class="text-black text-md"
                ><mat-icon
                  ><span class="material-icons"> mail_outline </span></mat-icon
                >
                {{ best.groupReservation?.message | limitTo : 30 }}
              </span>
              
              <!-- <mat-icon fxFlex>mode_edit</mat-icon>
              <mat-icon fxFlex>delete</mat-icon> -->
              <!-- <button mat-raised-button (click)="openDialog(weekday)"> -->

              <!-- </button> -->
            </mat-card-content>
            <!-- <img mat-card-image [src]="" /> -->
            <mat-card-footer class="pb-2">
              <span class="occuredTime">Registrert: {{best.occuredTime}}</span>
              <mat-icon
                class="mat-card-footer-child"
                type="button"
                (click)="openDialog(best)"
                ><span class="material-icons"> more_horiz </span></mat-icon
              >
            </mat-card-footer>
            <!-- <img mat-card-image [src]="" /> -->
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
