import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { AdalService } from "adal-angular4";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../react-shared/react-theme";
import { Chanels } from "../../chanels";
import { BookableResource } from "../../time-scheduling/bookable-resource";
import { ServiceBusService } from "../../system/service-bus.service";
import { Guid } from "../../system/guid";
import { ReservationContext, useReservationReducer } from "./reservation.context";
import { DateTimeAtendees } from "./DateTimeAtendees";
import { Activity } from "./activity.model";
import { ActivityService } from "./activity.service";
import { observableAxios } from "../../system/rxjs-axios";
import { useEffect } from "react";
import { Reservation } from "./timeslot.model.new";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-booking-form-react",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class BookingFormReactComponent implements OnChanges, OnDestroy, AfterViewInit {

  @Input() activityIds: string[] = [];
  @Input() mainActivityId: string;
  @Input() cancelations: Reservation[];
  @Output() reservations: EventEmitter<Reservation[]> = new EventEmitter<Reservation[]>();
  @ViewChild(containerElementName, {static: true}) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private resource: BookableResource;

  constructor(
    private adalService: AdalService,
    private bus: ServiceBusService,
  ) {}

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
    this.bus.chanelObservable(Chanels.RESOURCE).subscribe((r) => {
      if (r) {
        this.resource = r;
        this.render();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <BookingFormContext.Provider value={{ adalService: this.adalService, siteId: this.resource?.id, siteIdPrittyUrl: this.resource?.prittyUrl }}>
          <ReservationContextWrapper activityIds={this.activityIds} mainActivityId={this.mainActivityId} onReservationsChange={r => this.reservations.emit(r)} cancelations={this.cancelations} />
        </BookingFormContext.Provider>
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}

export const useActivities = (activityIds: string[]) => {
  const [activities, setActivities] = React.useState<Activity[]>([]);
  React.useEffect(() => {
    if(!activityIds.length) return;
    const svc = new ActivityService(observableAxios);
    svc.getActivities(activityIds).subscribe((r: Activity[]) => {
      setActivities(r);
    });
  }, [activityIds.length]);
  return {activities};
}

type ReservationContextWrapperProps = {
  activityIds: string[];
  mainActivityId: string;
  cancelations: Reservation[];
  onReservationsChange: (r: Reservation[]) => any;
}

const ReservationContextWrapper: React.FC<ReservationContextWrapperProps> = ({ cancelations,activityIds, mainActivityId, onReservationsChange }) => {
  const [reservationState, dispatchReservation] = useReservationReducer();
  const { activities } = useActivities(activityIds);
  const mainActivity = activities?.find(x => x.id === mainActivityId);

  useEffect(() => {
    dispatchReservation({ type: "SET_ACTIVITY", data: mainActivity });
  }, [mainActivity]);

  useEffect(() => {
    onReservationsChange(reservationState.reservations);
  }, [reservationState.reservations]);

  return (
  <ReservationContext.Provider value={{ state: reservationState, dispatch: dispatchReservation }}>
    <DateTimeAtendees
      activities={activities ?? []}
      cancelations={cancelations}
    />
  </ReservationContext.Provider>
  )
}

type BookingFormContextType = {
  adalService: AdalService;
  siteId: string;
  siteIdPrittyUrl: string;
}

export const BookingFormContext = React.createContext<BookingFormContextType>({ adalService: undefined, siteId: '', siteIdPrittyUrl: '' });

