<div>
  <form class="box" #waiverForm="ngForm" >
      <h1>Create a waiver</h1>
      <p>{{message}}</p>
      <mat-form-field class="example-full-width" >
        <input matInput placeholder="Mobil" name="mobile" [focus]="focusPhone"
          [(ngModel)]="newWaiver.mobile" (keydown)="checkMobile($event)">
      </mat-form-field>
      <div *ngIf="isMobileOk;then mobileOk"></div>
      <ng-template #mobileOk>
        <div *ngIf="!showForm;then fileupload;else fileuploaded"></div>
        <ng-template #fileupload>
          <div *ngIf="!isUploading();else processing">
            <input #fileInput type="file" placeholder="WaverScan" name="waverFile" />
            <button (click)="addFile()" mat-button type="button">Add</button>
            <span md-error *ngIf="fileErrorMessage">{{fileErrorMessage}}</span>
          </div>
          <ng-template #processing>
            <mat-progress-bar class="inline_progress" mode="indeterminate" aria-label="Uploading image"></mat-progress-bar>
          </ng-template>
        </ng-template>
        <ng-template #fileuploaded>
          <div>{{fileInfo}}</div>

          <mat-form-field class="example-full-width">
            <input matInput placeholder="Navn" name="parrentname" [(ngModel)]="newWaiver.contactPerson.firstname">
            <!-- <br> -->
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Etternavn" name="parrentlastname" [(ngModel)]="newWaiver.contactPerson.lastname">

          </mat-form-field>
          <!-- <br> -->
          <!-- <mat-form-field class="example-full-width">
            <input matInput placeholder="Mobil" name="mobile" [(ngModel)]="newWaiver.mobile">
            <br>
          </mat-form-field> -->
          <!-- <br> -->

          <!-- <br> -->
          <mat-form-field class="example-full-width">
            <input matInput placeholder="E-mail" name="email" [(ngModel)]="newWaiver.email">

          </mat-form-field>

          <!-- <br> -->
          <!-- <button (click)="showaddChild()" mat-button type="button">{{btaddChild}}</button> -->
            <div>
              <ul>
                <li *ngFor="let child of newWaiver.children">
                  <span>{{child.firstname}} {{child.lastname}}</span>
                  <button (click)="deletefromlist(child)" mat-button type="button"> slett</button>
                </li>
              </ul>

            </div>

          <div class="childre">

            <mat-form-field class="example-full-width">
              <input matInput placeholder="Barnes Fornavn" name="bfn" [focus]="focusChildrenEventEmiter" [(ngModel)]="newChild.firstname">
            </mat-form-field>
            <br>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Barnes Etternavn" name="ben" [(ngModel)]="newChild.lastname">
            </mat-form-field>
            <br>
            <button (click)="addChildToList()" mat-button type="button">legg til Barn</button>
          </div>

          <mat-form-field class="example-full-width">
            <input [(ngModel)]="newWaiver.createdOn" name="datpicker" matInput [matDatepicker]="picker"
              placeholder="Signatur Dato">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <br>
          <button  mat-button type="button" (click)="SaveWaiver(waiverForm)"> save </button>
        </ng-template>
      </ng-template>
  </form>
  <div class="box" id="waiver_image" *ngIf="imageUrl">
    <img [src]="imageUrl" />
  </div>
</div>
