<form class="example-form" [formGroup]="searchForm">
    <h3>Søk for reservation</h3>
  <mat-form-field class="example-full-width">
    <input type="text" placeholder="Tast inn mobilnr" aria-label="Number" matInput formControlName="search">
  </mat-form-field>

    <mat-checkbox labelPosition="after" formControlName="history" >
      Ta med eldre
    </mat-checkbox>

</form>
<p>{{message}}</p>


<mat-list role="list" >
    <mat-list-item role="listitem" *ngFor="let reservation of reservations$|async" [ngClass]="reservation.cssClass">
        <p mat-line ><strong class="payment-info {{reservationsPaymentInfo.get(reservation.reservation?.id) | async}}"></strong></p>
        <p mat-line > <strong>Mobil</strong> {{reservation.mobile}} </p>
        <p mat-line ><strong>Ref nr</strong> {{reservation.referenceNumber}} </p>
        <p mat-line ><strong>Antall</strong> {{reservation.count}} </p>
        <p mat-line ><strong>Pris</strong> {{(reservation.count*reservation.unitPrice)}} </p>
        <p mat-line class="demo-2"><strong>Dato</strong> {{ reservation.start().local() | date:'dd.MM.yyyy' }} </p>
        <p mat-line class="demo-2"><strong>Klokken</strong> {{ reservation.start().local() | date:'HH:mm' }}- {{ reservation.end().local() | date:'HH:mm' }} </p>
        <p class="park_info">{{getParkName(reservation)}}</p>
       <mat-icon type="button" (click)="openDialog(reservation)" *ngIf="!isGroupActivity(reservation)">delete</mat-icon>
       <a [href]="'resource/' + getPrityUrl(reservation) + '/bursdag/' + reservation.reservationId"><mat-icon type="button" *ngIf="isGroupActivity(reservation)">edit</mat-icon></a>
       <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
     </mat-list-item>

</mat-list>
