import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import {
  IeInfoRx, ResponsiveSizeInfoRx, OrientationInfoRx, DeviceStandardInfoRx,
  DeviceInfoRx,
  UserAgentInfoRx, BrowserInfoRx,
} from 'ngx-responsive';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private _subscriptions: Subscription[] = [];
  private _isDebugMode = false;

  private responsiveSizeSubject = new BehaviorSubject(null);
  responsiveSize$ = this.responsiveSizeSubject.asObservable();

  private orientationSubject = new BehaviorSubject(null);
  orientation$ = this.orientationSubject.asObservable();

  constructor(
    private ieInfoRx: IeInfoRx,
    private browserInfoRx: BrowserInfoRx,
    private devicesInfoRx: DeviceInfoRx,
    private devicesStandardInfoRx: DeviceStandardInfoRx,
    private orientationInfoRx: OrientationInfoRx,
    private responsiveSizeInfoRx: ResponsiveSizeInfoRx,
    private userAgentInfoRx: UserAgentInfoRx
  ) {}

  public onInit(isDebug = false): void {
    this._isDebugMode = isDebug;
    this._subscribe();
    this.ieInfoRx.connect();
    this.browserInfoRx.connect();
    this.devicesInfoRx.connect();
    this.devicesStandardInfoRx.connect();
    this.orientationInfoRx.connect();
    this.responsiveSizeInfoRx.connect();
    this.userAgentInfoRx.connect();
  }
  public onDestroy(): void {
    this._unsubscribe();
    this.orientationSubject.complete();
    this.responsiveSizeSubject.complete();
    this.ieInfoRx.disconnect();
    this.browserInfoRx.disconnect();
    this.devicesInfoRx.disconnect();
    this.devicesStandardInfoRx.disconnect();
    this.orientationInfoRx.disconnect();
    this.responsiveSizeInfoRx.disconnect();
    this.userAgentInfoRx.disconnect();
  }

  private _subscribe(): void {
    this._subscriptions.push(
      this.ieInfoRx.getIE.subscribe((data) => {
        if (this._isDebugMode) {
          console.log('this.ieInfoRx.getIE ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
    this._subscriptions.push(
      this.browserInfoRx.getBrowser.subscribe((data) => {
        if (this._isDebugMode) {
          console.log('this.browserInfoRx.getBrowser ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
    this._subscriptions.push(
      this.devicesInfoRx.getDevice.subscribe((data) => {
        if (this._isDebugMode) {
          console.log('this.devicesInfoRx.getDevice ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
    this._subscriptions.push(
      this.devicesStandardInfoRx.getStandardDevice.subscribe((data) => {
        if (this._isDebugMode) {
          console.log('this.devicesStandardInfoRx.subject$ ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
    this._subscriptions.push(
      this.orientationInfoRx.getOrientation.subscribe((data) => {
        this.orientationSubject.next(data);
        if (this._isDebugMode) {
          console.log('this.orientationInfoRx.getOrientation ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
    this._subscriptions.push(
      this.responsiveSizeInfoRx.getResponsiveSize.subscribe((data) => {
        this.responsiveSizeSubject.next(data);
        if (this._isDebugMode) {
          console.log('this.responsiveSizeInfoRx.getResponsiveSize ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
    this._subscriptions.push(
      this.userAgentInfoRx.getUserAgent.subscribe((data) => {
        if (this._isDebugMode) {
          console.log('this.userAgentInfoRx.getUserAgent ===>', data);
        }
      }, (err) => {
        console.error('Error', err);
      })
    );
  }
  private _unsubscribe(): void {
      this._subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
