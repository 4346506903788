import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureLogService } from './signature-log.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [SignatureLogService],
  exports:[
    
  ]
})
export class SignatureLogModule { }
