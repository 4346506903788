import React, { FC, useEffect, useState } from "react";
import {
  TextField,
  Grid,
  IconButton,
  Button,
  FormControlLabel,
  Switch,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { PriceInfo } from "../../time-scheduling/day-schedule.model";
import DeleteIcon from "@mui/icons-material/Delete";
import { NumberField } from "../../react-shared/NumberField";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    item: {
      borderBottom:"1px solid black",
      marginBottom:"2rem"
    }
  });
});

type Props = {
  value: PriceInfo[];
  onChange: (value: PriceInfo[]) => any;
};


export const EditPriceInfosFieldsGroup: FC<Props> = ({ value, onChange }) => {
  const [priceInfos, setPriceInfos] = useState<PriceInfo[]>(value ?? []);
  
  useEffect(() => {
    onChange(priceInfos);
  }, [priceInfos]);

  return (
    <div>
      {priceInfos.map((priceInfo, i) => (
        <React.Fragment key={i}>
          <EditPriceInfoFieldGroup
            value={priceInfo}
            onChange={(priceInfoChanged) => {
              priceInfos[i] = { ...priceInfoChanged };
              setPriceInfos([...priceInfos]);
            }}
            onDelete={() =>
              setPriceInfos((prev) => [...prev.filter((_, j) => j !== i)])
            }
          />
        </React.Fragment>
      ))}
      <Button
        variant="contained"
        style={{marginTop: '1em'}}
        onClick={() =>
          setPriceInfos((prev) => [...prev, new PriceInfo(0, "", 0)])
        }
      >
        Add Price Info
      </Button>
    </div>
  );
};

type SingleProps = {
  value: PriceInfo;
  onChange: (value: PriceInfo) => any;
  onDelete: () => any;
};

const EditPriceInfoFieldGroup: FC<SingleProps> = ({
  value,
  onChange,
  onDelete,
}) => {
  const { duration, viewValue, price, campaign, subActivityPrice } = value;
  const classes = useStyles();
  const setDuration = (duration: number) => {
    onChange({
      ...value,
      duration,
    });
  };

  const setViewValue = (viewValue: string) => {
    onChange({
      ...value,
      viewValue,
    });
  };

  const setPrice = (price: number) => {
    onChange({
      ...value,
      price,
    });
  };

  const setCampaign = (campaign: string) => {
    onChange({
      ...value,
      campaign,
    });
  };

  const setSubActivityPrice = (subActivityPrice: boolean) => {
    onChange({
      ...value,
      subActivityPrice,
      duration: subActivityPrice?30:value.duration,
      viewValue: subActivityPrice?"":value.viewValue,
      campaign: subActivityPrice?"":value.campaign
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }} className={classes.item}>
      <Grid container >
        <Grid item sm={12} md={6} >
          <NumberField
            label="Duration"
            variant="standard"
            disabled={subActivityPrice}
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value as any);
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <NumberField
            label="Price"
            variant="standard"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value as any);
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextField
            label="View Value"
            disabled={subActivityPrice}
            variant="standard"
            value={viewValue ?? ""}
            onChange={(e) => setViewValue(e.target.value)}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextField
            label="Campaign"
            variant="standard"
            disabled={subActivityPrice}
            value={campaign ?? ""}
            onChange={(e) => setCampaign(e.target.value)}
          />
        </Grid>
        <Grid item sx={{ padding: "0.5em" }} sm={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={subActivityPrice}
                  onChange={(e) =>
                    setSubActivityPrice(e.target.checked)
                  }
                  color="primary"
                />
              }
              label={"Arangement pris"}
            />
          </Grid>
      </Grid>
      <IconButton onClick={() => onDelete()}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
