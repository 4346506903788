<div fxLayout="column" fxFlex>
  <app-toolbar-menu [sidenav]="sidenav"></app-toolbar-menu>

  <mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav
      #sidenav
      class="app-sidenav"
      fixedTopGap="64"
      [mode]="sidenavMode"
      [opened]="true"
      [fixedInViewport]="true"
      [class.sidenav-folded]="!sidenav.opened"
    >
      <app-side-menu [sidenav]="sidenav"></app-side-menu>
    </mat-sidenav>

    <mat-sidenav-content
      cdkScrollable
      class="content-container"
      [class.app-container-folded]="sidenavMode != 'side' || !sidenav.opened"
    >
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <div class="message"></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
