import React, { FC } from "react"
import { EditWaiverRulesForm } from "./components/EditWaiverRulesForm"

type Props = {

}

export const EditWaiverRulesPage: FC<Props> = () => {
  return (
    <>
      <EditWaiverRulesForm />
    </>
  )
}