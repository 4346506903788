import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GbookingComponent } from "./gbooking/gbooking.component";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  BirthdaybookingOverviewComponent,
  DialogOverviewDialog,
  TruncatePipe,
} from "./birthdaybooking-overview/birthdaybooking-overview.component";
import { BrowserModule } from "@angular/platform-browser";
import { BirthdaybookingdetailsComponent } from "./birthdaybookingdetails/birthdaybookingdetails.component";
import { MatButtonModule } from "@angular/material/button";

import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { GroupBookingDetailsComponent } from "./group-booking-details/group-booking-details.component";
import { MaterialModule } from "../material.module";
import { MatListModule } from "@angular/material/list";
import { TimeSchedulingModule } from "../time-scheduling/time-scheduling.module";
import { BookingDetailsDialogComponent } from "./group-booking-details/dialog-booking-details.dialog";
import { GroupBookingEditDialog } from "./group-booking-details/group-booking-edit.dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GroupBookingModule } from "../group-booking/group-booking.module";
import { RouterModule } from "@angular/router";
import { BirthdaybookingPastComponent } from "./birthdaybooking-past/birthdaybooking-past.component";
@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    BrowserModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatListModule,
    TimeSchedulingModule,
    GroupBookingModule,
  ],
  entryComponents: [
    BirthdaybookingOverviewComponent,
    DialogOverviewDialog,
    BookingDetailsDialogComponent,
    GroupBookingEditDialog,
  ],
  declarations: [
    GbookingComponent,
    BirthdaybookingOverviewComponent,
    DialogOverviewDialog,
    GroupBookingDetailsComponent,
    BookingDetailsDialogComponent,
    GroupBookingEditDialog,
    BirthdaybookingdetailsComponent,
    BirthdaybookingPastComponent,
    TruncatePipe,
  ],
})
export class BookingbirthdayModule {}
