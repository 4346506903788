import { Observable, Subscription } from 'rxjs/Rx';

export class SubscriptionsLifespanHandler {
	private subscriptions: Subscription[] = [];

	constructor() { }

	public add(subscription: Subscription) {
		this.subscriptions.push(subscription);
	}

	public unsubscribe() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	};
}
