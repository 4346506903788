import React, { FC } from "react"
import { EditResourceForm } from "./components/EditResourceForm"

type Props = {

}

export const EditResourcePage: FC<Props> = () => {
  return (
    <>
      <EditResourceForm />
    </>
  )
}