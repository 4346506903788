<div *ngIf="daySchedule">
   <span *ngIf="daySchedule">{{selectedScheduleType}}</span> {{daySchedule}}
  <ul>
    <li *ngFor="let day of daySchedule.getDays()">
      {{day}}
    </li>
  </ul>
  <!-- <ul>
    <li *ngFor="let property of getDayScheduleValues()" >
      {{property.name}} {{property.value}}
    </li> 
  </ul>-->
  <form [formGroup]="dayForm" (ngSubmit)="onSubmit()">
    <div>
      <mat-form-field>
        <input formControlName="validFrom" [min]="minDate" name="datpicker" matInput 
          [matDatepicker]="validFrom" placeholder="*Velg fra dato(dd.mm.yyyy)">
        <mat-datepicker-toggle matSuffix [for]="validFrom"></mat-datepicker-toggle>
        <mat-datepicker #validFrom ></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input formControlName="validTo" [min]="minDate" name="datpicker" matInput 
          [matDatepicker]="validTo" placeholder="*Velg til dato(dd.mm.yyyy)">
        <mat-datepicker-toggle matSuffix [for]="validTo"></mat-datepicker-toggle>
        <mat-datepicker #validTo ></mat-datepicker>
      </mat-form-field>
    </div>      
    <!-- <div>
      <mat-form-field>
        <mat-select placeholder="Timeplan type" formControlName="scheduleType">
          <mat-option *ngFor="let type of scheduleTypes" [value]="type.value">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    <div>
      <mat-form-field>
          <input formControlName="capacity" name="capacity" matInput placeholder="Total Kapasitet">
      </mat-form-field>
    </div>
    <div formGroupName="startTime">
      <mat-form-field >
        <mat-select placeholder="Åpningstid time" formControlName="hour"  >
          <mat-option *ngFor="let hour of hours" [value]="hour">
            {{hour}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="minut" formControlName="minute" >
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{minute}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div formGroupName="endTime">
      <mat-form-field >
        <mat-select placeholder="Stengetid time" formControlName="hour"  >
          <mat-option *ngFor="let hour of hours" [value]="hour">
            {{hour}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="minut" formControlName="minute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{minute}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>      
    <div>
      <mat-form-field>
        <mat-select formControlName="days" multiple>
          <mat-option *ngFor="let day of days" [value]="day.value">{{day.display}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div formArrayName="priceInfos">
      <div *ngFor="let info of priceInfos.controls; let i=index" [formGroupName]="i">
        <mat-form-field>
          <input formControlName="viewValue" name="viewValue" matInput placeholder="Visningstekst">
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Varighet i min" formControlName="duration"  >
              <mat-option *ngFor="let minute of durations" [value]="minute">
                {{minute}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input formControlName="price" name="price" matInput placeholder="Pris">
        </mat-form-field>
        <mat-form-field>
          <input formControlName="campaign" name="campaign" matInput placeholder="Kampanje">
        </mat-form-field>
        <button mat-icon-button fxFlex="5">
            <mat-icon type="button" (click)="deletePriceInfo(i)">delete</mat-icon>
          </button>
      </div>
      <button mat-raised-button color="accent" type="button" (click)="addPriceInfo()">Legg pris</button>
    </div>
    <button mat-raised-button color="accent" [disabled]="!dayForm.valid">Lagre</button> 

  </form>
  <button mat-icon-button >
      <mat-icon type="button" (click)="deleteDaySchedule(i)">delete</mat-icon>
    </button>
</div>