import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SignatureLog } from './signature-log';
import { Observable } from 'rxjs/Observable';
@Injectable({providedIn: 'root'})
export class SignatureLogService {
  // TODO Add separet config for LOG service
  private readonly apiUrl = environment.dependencies.signature_log_api.url + "/api/signaturelog";
 
  
  constructor(private http: HttpClient ) { }

  public requestConfirmation(entry: SignatureLog): any {
    return this.http
        .post(`${this.apiUrl}/confirmation_request`, entry);
  }
  get(): any {
    return this.http.get(this.apiUrl);
  }

  getSignatureLog(documentId:string): any {
    return this.http.get(`${this.apiUrl}/${documentId}`);
  }
}
