import React, { useContext, useEffect, useState } from 'react';
import { WaiverRules } from '../model';
import { WaiverRulesContext } from '../WaiverRulesReactWrapper';
import { initWaiverRulesService } from '../waiverRulesService';
import { WaiverRulesCard } from './WaiverRulesCard';
import { SearchableList } from '../../react-shared/SearchableList';

export const WaiverRulesList = () => {
  const { adalService } = useContext(WaiverRulesContext);

  const [waiverRulesService] = useState(initWaiverRulesService(adalService));

  const [waiverRules, setWaiverRules] = useState<WaiverRules[]>([]);

  const fetchWaiverRules = () => waiverRulesService.getAllWaiverRules().then(waiverRules => setWaiverRules(waiverRules));
  const deleteWaiverRules = (id: string) => {
    waiverRulesService.deleteWaiverRules(id).then(() => fetchWaiverRules());
  }

  useEffect(() => {
    fetchWaiverRules();
  }, []);

  return (
    <>
      <SearchableList
        list={waiverRules}
        renderElement={(u: WaiverRules) => <WaiverRulesCard key={u.id} waiverRules={u} onDelete={deleteWaiverRules} />}
      />
    </>
  );
}