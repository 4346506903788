import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Guid } from "../system/guid";
import { MaintenanceHandler } from '@bitmesh/maintenance';
import { AdalService } from "adal-angular4";
import { environment } from "../../environments/environment";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-maintenance-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class MaintenanceComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName, {static: true}) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private user: any;

  constructor(
    private adalService: AdalService
  ) {}

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
    this.user = this.adalService.userInfo;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    const userId = this.user.profile.oid;
    ReactDOM.render(
      <>
        {userId && <MaintenanceHandler
          userId={userId}
          fileApiUrl={environment.dependencies.file_api.url}
          colors={{
            primary: "#201c34",
            secondary: "#fafafa",
            text: "black",
            buttonText: "white",
            icon: "#201c34",
          }}
          appId="bitmesh.skyland.app"
          tokenProvider={(url: string) => {
            return new Promise((resolve, reject) => {
              this.adalService.userInfo.authenticated && this.adalService
                .acquireToken(this.adalService.getResourceForEndpoint(url))
                .subscribe(
                  (resToken: string) => {
                    if (resToken) {
                      resolve(resToken);
                    }
                  },
                  (error) => {
                    console.error(error)
                    reject(error);
                  }
                );
            });
          }}
        />}
      </>,
      this.containerRef.nativeElement
    );
  }
}

