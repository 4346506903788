import React, { useContext, useEffect, useState } from 'react';
import { Resource } from '../model';
import { ResourcesContext } from '../ResourcesReactWrapper';
import { initResourceService } from '../resourceService';
import { ResourceCard } from './ResourceCard';
import { SearchableList } from '../../react-shared/SearchableList';

export const ResourcesList = () => {
  const { adalService, siteId } = useContext(ResourcesContext);

  const [resourceService] = useState(initResourceService(adalService));

  const [resources, setResources] = useState<Resource[]>([]);

  const fetchResources = () => {
    if(!siteId) return;
    resourceService.getResources(siteId).then(products => setResources(products));
  }
  const deleteResource = (id: string) => {
    resourceService.deleteResource(id).then(() => fetchResources());
  }

  useEffect(() => {
    fetchResources();
  }, [siteId]);

  return (
    <>
      <SearchableList
        list={resources}
        renderElement={(u: Resource) => <ResourceCard key={u.id} resource={u} onDelete={deleteResource} />}
      />
    </>
  );
}