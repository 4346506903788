import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { MatSidenav, MatDrawer } from "@angular/material/sidenav";
import { MenuItem } from "../models";
import { BookableResource } from "../time-scheduling/bookable-resource";
import { BUser } from "../system/buser";
import { Chanels } from "../chanels";
import { ServiceBusService } from "../system/service-bus.service";
import { BookableResourceService } from "../time-scheduling/bookable-resource.service";
import { Observable } from "rxjs";
import { SidebarService } from "../services";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { Subject } from "@aspnet/signalr-client/dist/src/Observable";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Input()
  public sidenav: MatSidenav;
  @Input()
  public drawer: MatDrawer;
  public bookableResources: BookableResource[];
  public sidenavItems: MenuItem[];
  public showResourceMenu = false;
  public selectedResource: BookableResource;
  private user: BUser;
  private selectedMenuItem: MenuItem;

  private destroyed$ = new Subject();
  public mobile = false;

  constructor(
    private bus: ServiceBusService,
    private bookingResourceService: BookableResourceService,
    private sidebarService: SidebarService,
    private router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.bus.subscribe(Chanels.RESOURCE, (newResource) => {
      if (
        !this.selectedResource ||
        (newResource && this.selectedResource.name !== newResource.name)
      ) {
        this.onResourceChanges(newResource as BookableResource);
      }
      this.showResourceMenu = false;
    });

    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (!user) {
        return;
      }
      this.user = user;
      this.buildMenuItems(this.selectedResource);
      this.bookingResourceService
        .getResources()
        .map((x) =>
          x.filter(
            (q: BookableResource) => user.isAdmin() || user.hasSiteAccess(q.id)
          )
        )
        .subscribe((res) => {
          this.bookableResources = res;
          if (res && res.length) {
            if (!this.selectedResource) {
              var prittyUrl = localStorage.getItem("selectedResource");
              var resource = this.bookableResources[0];
              if (prittyUrl) {
                resource =
                  this.bookableResources.find(
                    (x) => x.prittyUrl == prittyUrl
                  ) || resource;
              }
              this.onResourceChanges(resource);
              if (this.router.url === "/") {
                this.router.navigate([
                  "/resource",
                  this.selectedResource.prittyUrl,
                ]);
              }
            }
            this.sidebarService.notifyResourcesLoaded();
          }
        });
    });

    this.sidebarService.toggleResourceMenu$.subscribe(() => {
      // console.log('selected resource', this.selectedResource);
      this.showResourceMenu = !this.showResourceMenu;
      if (this.showResourceMenu && !this.sidenav.opened) {
        this.sidenav.open();
        // this.sidenav.open();
      }
    });
    if (window.innerWidth < 992) {
      this.sidenav.close();
      this.mobile = true;
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (window.innerWidth < 992) {
      this.sidenav.close();
      this.mobile = true;
      // this.sidenav.close();
    } else {
      this.mobile = false;
    }
  }
  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  isSelectedResource(resource: BookableResource) {
    return (
      this.selectedResource != null && this.selectedResource.id === resource.id
    );
  }
  onResourceChanges(resource: BookableResource) {
    this.selectedResource = resource;
    this.showResourceMenu = false;
    this.buildMenuItems(resource);
    // this.bus.publish(Chanels.RESOURCE, resource);
  }
  storeSelection(resource: BookableResource) {
    localStorage.setItem("selectedResource", resource.prittyUrl);
    this.toggleMenu();
  }
  toggleMenu() {
    this.sidenav && this.sidenav.toggle();
    this.drawer && this.drawer.toggle();
  }
  onSelectMenuItem(event: any, item: MenuItem) {
    this.selectedMenuItem = item;
    if (this.mobile) this.sidenav.close();
  }

  private buildMenuItems(resource: BookableResource) {
    // console.log('build menu items');
    if (resource == null) {
      return;
    }
    const menuItems = [
      // {
      //   text: 'Dashboard',
      //   icon: 'dashboard',
      //   routerLink: `/resource/${resource.prittyUrl}/dashboard`
      // },

      {
        text: "Ukesoversikt",
        icon: "view_week",
        routerLink: `/resource/${resource.prittyUrl}/bursdagoversikt`,
      },
      {
        text: "Arangement og bursdags -liste",
        icon: "cake",
        routerLink: `/resource/${resource.prittyUrl}/bursdag`,
      },
      {
        text: "Historisk Bursdags",
        icon: "insert_chart_outlined",
        routerLink: `/resource/${resource.prittyUrl}/bursdagoversikt/past`,
      },

      {
        text: "Dagsoversikt",
        icon: "group",
        routerLink: `/resource/${resource.prittyUrl}/dagsoversikt`,
      },
      {
        text: "Sjekke Inn",
        icon: "account_box",
        routerLink: `/resource/${resource.prittyUrl}/sjekkinn`,
      },
      {
        text: "Reservasjoner",
        icon: "book",
        routerLink: `/reservations/${resource.prittyUrl}`,
      },
      {
        text: "Reservasjonsliste",
        icon: "person",
        routerLink: `/resource/${resource.prittyUrl}/booking`,
      },
      {
        text: "Egenerklæring",
        icon: "bookmark_border",
        routerLink: "/egenerklering",
      },

      // {
      //   text: 'Hjelp',
      //   icon: 'help',
      //   routerLink: '/other/help'
      // }
    ];
    const restrictedMenu = [
      {
        text: "Products",
        icon: "add_shopping_cart",
        routerLink: "/products",
      },
      {
        text: "Egenerklæringsregler",
        icon: "chrome_reader_mode",
        routerLink: "/waiver-rules",
      },
      {
        text: "Activities",
        icon: "local_activity",
        routerLink: `/resource/${resource.prittyUrl}/activities`,
      },
      {
        text: "Resources",
        icon: "business",
        routerLink: `/resource/${resource.prittyUrl}/resources`,
      },
      {
        text: "Åpningstider",
        icon: "event_note",
        routerLink: `/resource/${resource.prittyUrl}/schedules`,
        // TODO: hidden: !this.user.isAdmin()
      },
    ];

    this.sidenavItems = [
      ...menuItems,
      ...(this.user && this.user.isAdmin() ? restrictedMenu : []),
    ];
  }
}
