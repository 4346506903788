import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceBusService {
  
  private chanels:Map<string,BehaviorSubject<any>> = new Map<string,BehaviorSubject<any>>();
  private subscriptions: Array<Subscription> = [];
  constructor() { }
  public chanelObservable(chanel:string): Observable<any>{
    var subject:BehaviorSubject<any> = this.chanels.get(chanel);
    if(!subject){
      subject = new BehaviorSubject<any>(null);
      this.chanels.set(chanel,subject);
    }
    return subject.asObservable();
  }
  public subscribe(chanel:string,action:(arg:any)=>void){
    var subject:BehaviorSubject<any> = this.chanels.get(chanel);
    if(!subject){
      subject = new BehaviorSubject<any>(null);
      this.chanels.set(chanel,subject);
    }
    this.subscriptions.push(subject.subscribe(action)); 
  }
  public publish(chanel:string,obj: any) {
    var subject:BehaviorSubject<any> = this.chanels.get(chanel);
    if(!subject){
      subject = new BehaviorSubject<any>(obj);
      this.chanels.set(chanel,subject);
    }
    else{
      subject.next(obj);
    }
  }
}
