<mat-nav-list  class="pad-top-none" *ngIf="sidenav && !showResourceMenu">
  <ng-container *ngFor="let menuItem of sidenavItems">
    <a mat-list-item class="menu-item" (click)="onSelectMenuItem($event, menuItem)"
      routerLinkActive="{{menuItem.disabled ? '' : 'active-menu-item'}}"
      [disableRipple]="menuItem.disabled"
      [class.expanded]="sidenav?.opened"
      [class.disabled]="menuItem.disabled"
      [routerLink]="menuItem.routerLink"
      [routerLinkActiveOptions]="{ exact: true }">
      <div class="left-gap-item"></div>
      <mat-icon mat-list-icon matTooltip="{{menuItem.text}}" class="nav-icon" matTooltipPosition="right"
        [matTooltipDisabled]="sidenav.opened">{{menuItem.icon}}</mat-icon>
      <p *ngIf="sidenav.opened" matLine class="nav-text text-truncate">{{menuItem.text}}</p>
    </a>
  </ng-container>
</mat-nav-list>

<mat-nav-list class="pad-top-none" *ngIf="sidenav && showResourceMenu" color="primary">
  <ng-template ngFor let-resource [ngForOf]="bookableResources">
    <a mat-list-item class="menu-item" color="primary" [class.expanded]="sidenav?.opened"
    [routerLink]="['/resource/'+ resource.prittyUrl]"
    [class.active-menu-item]="isSelectedResource(resource)" (click)="storeSelection(resource)">
      <div class="left-gap-item"></div>
      <p matLine class="nav-text text-truncate">{{resource.name}}</p>
    </a>
  </ng-template>
</mat-nav-list>
