import { Injectable,Inject } from '@angular/core';
// import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Site} from './site';
import 'rxjs/add/operator/map'
@Injectable({
  providedIn: 'root'
})
export class SiteService {

  private readonly base_url;
  private readonly sites_url;
  constructor(private http: HttpClient,
    @Inject('environment') environment) { 
      this.base_url = environment.dependencies.entity_api.url;
      this.sites_url = this.base_url + '/api/sites';
  }
  public getSites(): Observable<Array<Site>> {
    return this.http.get(`${this.sites_url}`).map((data: Array<any>)=>{
      return data.map(data => {
        return Site.deSerialize(data);
      });
    });
  }
  public getPublicSites(type:string,resourceId:string): Observable<Array<Site>> {
    return this.http.get(`${this.sites_url}/public/${type}/${resourceId}`).map((data: Array<any>)=>{
      return data.map(data => {
        return Site.deSerialize(data);
      });
    });
  }
}
