import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/throw';
import { Product, ProductItem } from './catalog/product';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CatalogService {

  private readonly catalogApi = environment.dependencies.catalog_api.url + '/api/products';
  constructor(private http: HttpClient) {

  }
  public getCatalog(resourceUrl?: string): Observable<Array<Product>> {
    return this.fetchCatalog();
    
  }

  private fetchCatalog(): Observable<Array<Product>>{
    return this.http.get(this.catalogApi).pipe(map((data:Array<any>) => {
        return data.map(Product.deSerialize);
      }
    ));
  }

  // public getCatalog(): Observable<Array<Product>>{
  //   return this.http.get(this.catalogApi).map((data:Array<any>)=>{
  //     return data.map((curr)=>{
  //       const prod = new Product();
  //       prod.name = curr.name;
  //       prod.formGroup = curr.formGroup;
  //       prod.multiselect = curr.multiselect;
  //       prod.items = curr.items.map(itcurr=>{
  //         var pi = new ProductItem();
  //         pi.name = itcurr.name;
  //         pi.price = itcurr.price;
  //         pi.selected = itcurr.defaultCheckedItem;
  //         pi.prOrder = itcurr.prBestilling;
  //         return pi;
  //       });
  //       return prod;
  //     });
  //   });
  // }
}
