import { Component, OnInit } from '@angular/core';
import { TimeScheduleService } from '../time-schedule.service';
import { ServiceBusService } from '../../system/service-bus.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DaySchedule, DayScheduleTypes, PriceInfo } from '../day-schedule.model';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { BookableResource } from '../bookable-resource';
import { Observable } from 'rxjs/Observable';
import { BookableResourceService } from '../bookable-resource.service';
import * as moment from 'moment';
import { Guid } from '../../system/guid';

@Component({
  selector: 'app-day-schedul',
  templateUrl: './day-schedul.component.html',
  styleUrls: ['./day-schedul.component.scss']
})
export class DaySchedulComponent implements OnInit {
  schedule = 'Regular';
  scheduleType: DayScheduleTypes = DayScheduleTypes.Regular;
  scheduleId: string;
  daySchedule: DaySchedule;
  dayForm: FormGroup;
  minDate: moment.Moment = moment();
  hours: Array<number> = Array(24).fill(0).map((x, y) => x + y);
  minutes: Array<number> = Array(4).fill(0).map((x, y) => y * 15);
  durations: Array<number> = Array(20).fill(1).map((x, y) => y * 30);
  public resource: BookableResource;
  days: Array<any> = Array(7).fill(0).map((x, y) => {
    const day = x + y;
    return { value: day, display: moment().weekday(day).format('dddd') };
  });
  scheduleTypes: Array<any> = [
    { name: DaySchedule.DayScheduleTypesText[DayScheduleTypes.Regular], value: DayScheduleTypes.Regular },
    { name: DaySchedule.DayScheduleTypesText[DayScheduleTypes.Birthday], value: DayScheduleTypes.Birthday },
  ];
  get selectedScheduleType(): string {
    if (!this.daySchedule) {
      return '';
    }
    return DaySchedule.DayScheduleTypesText[this.daySchedule.scheduleType];
  }
  constructor(private timeScheduleService: TimeScheduleService,
    private aroute: ActivatedRoute,
    private bus: ServiceBusService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {

    const self = this;
    this.aroute.params.subscribe(params => {
      this.scheduleId = params['scheduleId'];
      const type = params['type'];
      this.bus.subscribe('RESOURCE', (r) => {
        if (!r || type == null) { return; }
        this.resource = r as BookableResource;
        this.newDaySchedule(this.resource);
      });
      if (type) {
        this.scheduleType = this.scheduleTypes[type].value;
      } else if (this.router.url.indexOf('copy') > 0) {
        this.timeScheduleService.getDaySchedule(this.scheduleId).subscribe(d => {
          this.daySchedule = DaySchedule.deSerialize({ ...d, id: Guid.newGuids() });
          this.dayForm = this.daySchedule.getForm(this.fb);
        });
      } else if (this.scheduleId) {
        this.timeScheduleService.getDaySchedule(this.scheduleId).subscribe(d => {
          this.daySchedule = d;
          this.dayForm = this.daySchedule.getForm(this.fb);
        });
      }

    });
  }
  newDaySchedule(resource: BookableResource) {
    this.daySchedule = new DaySchedule(Guid.newGuids(), [], { hour: 0, minute: 0 }, { hour: 0, minute: 0 },
      100, resource.id, resource.tenantId, 15, [], this.scheduleType,
      this.scheduleType === DayScheduleTypes.Regular ? 0 : 1, null, null);
    this.dayForm = this.daySchedule.getForm(this.fb);
  }
  get priceInfos(): FormArray {
    return this.dayForm.get('priceInfos') as FormArray;
  }
  addPriceInfo() {
    this.priceInfos.push(this.fb.group(new PriceInfo(30, '', 0)));
  }
  deletePriceInfo(index) {
    this.priceInfos.removeAt(index);
    this.priceInfos.markAsDirty();
  }
  getLevelUp() {
    const route = this.router.url.split('/');
    return [route[1]];
  }
  deleteDaySchedule() {
    this.timeScheduleService.deleteDaySchedule(this.daySchedule)
      .subscribe(x => {
        this.router.navigate([...this.getLevelUp(), 'schedules']);
      });
  }
  getDayScheduleValues() {
    return Object.keys(this.daySchedule).map(x => {
      return { name: x, value: this.daySchedule[x] };
    });
  }
  onSubmit() {
    if (this.dayForm.invalid) {
      return;
    }
    const daySchedule = DaySchedule.deSerialize(this.dayForm.value);
    this.timeScheduleService.putDaySchedule(daySchedule).subscribe(x => {
      this.router.navigate([...this.getLevelUp(), 'schedules']);
    });
  }
}
