import { Component, OnInit,ViewChild } from '@angular/core';
import { UplaodService } from '../uplaod.service';
import { Guid } from '../system/guid';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private uploadService: UplaodService) { }

  ngOnInit() {
  }
  @ViewChild("fileInput", { static: false }) fileInput;

  addFile(): void {
      let fi = this.fileInput.nativeElement;
      if (fi.files && fi.files[0]) {
          let fileName = Guid.newGuid().toString();
          let fileToUpload = fi.files[0];
          this.uploadService
              .upload(fileName,fileToUpload)
              .subscribe(res => {
                  // console.log(res);
              });
      }
  }

}
