import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingoverviewComponent } from './bookingoverview/bookingoverview.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { RegisterbokkingComponent } from './registerbokking/registerbokking.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatBadgeModule} from '@angular/material/badge';
import { BookingOverviewTableComponent } from './booking-overview-table/booking-overview-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    BrowserModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTabsModule,
    MatBadgeModule
  ],
  declarations: [
    BookingoverviewComponent,
    RegisterbokkingComponent,
    BookingOverviewTableComponent
  ]

})
export class BookingModule { }