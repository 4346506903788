import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public loading$ = this.isLoading.asObservable();

  constructor() {}

  public onLoading() {
    return this.isLoading.next(true);
  }

  public onLoaded() {
    return this.isLoading.next(false);
  }
}
