import * as moment from "moment";
import { DaySchedule, DayScheduleTypes } from "./day-schedule.model";
import { Guid } from "../system/guid";

export class TimeSlot {
  constructor(
    public startTime: moment.Moment,
    public duration: number,
    public readonly referenceId: string,
    public id: string = Guid.newGuid().toString()
  ) {
    this.startTime = this.startTime
      .clone()
      .set({ seconds: 0, milliseconds: 0 });
  }
  public start(): moment.Moment {
    return this.startTime.clone();
  }

  public end(): moment.Moment {
    return this.startTime.clone().add(this.duration, "minutes");
  }

  isIn(timeslot: TimeSlot): boolean {
    return (
      this.isInTimeslot(timeslot, this) || this.isInTimeslot(this, timeslot)
    );
  }

  private isInTimeslot(timeslot1: TimeSlot, timeslot2: TimeSlot): boolean {
    var t =
      timeslot1.start().isSameOrAfter(timeslot2.start()) &&
      timeslot1.start().isBefore(timeslot2.end());
    return t;
  }
}

export type ReservationType = "Reservation" | "ActivityReserved";
export class ReservationsAgregate{
  get reservationId(): string{
    return this.reservation?.reservationId;
  }
  get startTime(): moment.Moment {
    return this.reservation?.startTime;
  }
  get occuredTime(): moment.Moment {
    return this.reservation?.occuredTime;
  }
  start(): moment.Moment {
    return this.reservation?.start();
  }
  end(): moment.Moment {
    return this.reservation?.end();
  }
  public mobile:string ="";
  getMobile(): string {
    return this.reservation?.mobile;
  }
  public get dayScheduleType():  DayScheduleTypes{
    return this.reservation?.dayScheduleType;
  }
  get unitPrice(): number{
    return this.reservation?.unitPrice;
  }
  get referenceId(): string{
    return this.reservation?.referenceId;
  }

  public referenceNumber: string = "";
  
  public get count(){
    return this.reservation?.count;
  }
  public get duration(){
    return this.reservation?.duration;
  }
  public activityIds: string[] = [];
  public reservations: Reservation[] = []
  public checkedIn: boolean = false;
  isCanceled: boolean = false;
  private setReservationFields(){
    this.mobile = this.reservation?.mobile;
    this.referenceNumber = this.reservation?.referenceNumber;
  }
  constructor(public reservation:Reservation){
    this.setReservationFields();    
    this.addReservation(reservation);
  }
  public get durationHours(): number {
    return this.reservation?.duration / 60;
  }
  setActivityReservation(r: Reservation) {
    this.reservation = r;
    this.setReservationFields();
  }
  addReservation(r:Reservation){
    this.reservations.push(r);
    if (r.count < 0) {
      this.isCanceled = true;
    } else if (r.count == 0) {
      this.checkedIn = true;
    }
    else if(this.reservation.type !== "ActivityReserved" &&  r.type === "ActivityReserved"){
      this.setActivityReservation(r);
    }
    else if(r.type === "ActivityReserved" && (this.count < r.count || this.duration < r.duration))
      this.setActivityReservation(r);
    if(r.type === "ActivityReserved") this.addActivityId(r.referenceId);
  }

  public getTotalPrice(): number {
    return this.reservation?.count > Reservation.minParticipants
      ? this.reservation?.count * this.reservation?.unitPrice
      : Reservation.minParticipants * this.reservation?.unitPrice;
  }
  public isRegular(): boolean {
    return this.reservation?.dayScheduleType == DayScheduleTypes.Regular;
  }
  public isBirthday(): boolean {
    return this.reservation?.dayScheduleType == DayScheduleTypes.Birthday;
  }
  public addActivityId(activityId: string): void {
    this.activityIds.push(activityId);
  }
  public hasActivityId(activityId: string): boolean {
    return this.activityIds.includes(activityId);
  }
}

export class Reservation extends TimeSlot {
  public referenceNumber: string;
  public reservationId: string;
  public type: ReservationType;
  public checkedIn: boolean = false;
  public static minParticipants = 5;
  public get cssClass(): string {
    return this.checkedIn ? "booking checked_in" : "booking";
  }
  public activityIds: string[] = [];
  constructor(
    startTime: moment.Moment,
    duration: number,
    public count: number,
    public mobile: string,
    referenceId: string,
    public tenantId: string,
    public unitPrice: number = 0,
    public dayScheduleType: DayScheduleTypes = DayScheduleTypes.Regular,
    public occuredTime: moment.Moment = moment(),
    id: string = Guid.newGuid().toString(),
    type: ReservationType = 'Reservation'
  ) {
    super(startTime, duration, referenceId, id);
    this.reservationId = this.id;
    this.type = type;
  }
  public get durationHours(): number {
    return this.duration / 60;
  }

  public getTotalPrice(): number {
    return this.count > Reservation.minParticipants
      ? this.count * this.unitPrice
      : Reservation.minParticipants * this.unitPrice;
  }
  public isRegular(): boolean {
    return this.dayScheduleType == DayScheduleTypes.Regular;
  }
  public isBirthday(): boolean {
    return this.dayScheduleType == DayScheduleTypes.Birthday;
  }
  public addActivityId(activityId: string): void {
    this.activityIds.push(activityId);
  }
  public hasActivityId(activityId: string): boolean {
    return this.activityIds.includes(activityId);
  }
  public static mapReservation(x: any): Reservation {
    if(!x) return;
    const ClassConstructor = x.type === "Reservation" ? Reservation : ActivityReserved;
    var m = new ClassConstructor(
      moment(x.startTime).local(),
      x.duration,
      x.count,
      x.mobile,
      x.referenceId,
      x.tenantId,
      x.unitPrice,
      x.dayScheduleType,
      moment(x.occuredTime).local(),
      x.id,
      x.typeName
    );
    m.referenceNumber = x.referenceNumber;
    m.reservationId = x.reservationId;
    return m;
  }
}

export class ActivityReserved extends Reservation {
  public type: ReservationType = "ActivityReserved";
}

export class ReservationInfo {
  private checkedin: boolean = false;  
  constructor(public reservation: ReservationsAgregate) {
    this.checkedin = reservation.checkedIn;
  }
  
  public isCheckedin(): boolean {
    return this.reservation.checkedIn;
  }
}

export class CapacityTimeSlot extends TimeSlot {
  public referenceNumber: string;
  constructor(
    startTime: moment.Moment,
    duration: number,
    public count: number,
    public totalCapacity: number,
    referenceId: string,
    id: string = Guid.newGuid().toString()
  ) {
    super(startTime, duration, referenceId, id);
  }
  public get durationHours(): number {
    return this.duration / 60;
  }

  public getTotalPrice(): number {
    return;
  }
}
