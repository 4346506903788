import { Directive, Input, EventEmitter, ElementRef, Renderer2, Inject } from '@angular/core';

@Directive({
    selector: '[focus]'
})
export class FocusDirective {
    @Input('focus') focusEvent: EventEmitter<boolean>;

    constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit() {
        this.focusEvent.subscribe(event => {
            this.renderer.selectRootElement(this.element.nativeElement).scrollIntoView();
        });
    }
}