import React, { FC } from "react"
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ProductsList } from "./components/ProductsList"

type Props = {

}

export const ProductsPage: FC<Props> = () => {
  return (
    <>
      <Link style={{ textDecoration: 'none', marginBottom: '1em' }} to="/edit-product">
        <Button variant="contained">New Product</Button>
      </Link>
      <ProductsList />
    </>
  )
}
