import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class UplaodService {
  constructor(private http: HttpClient) { }

  public upload(id: string, fileToUpload: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http
        .put(environment.dependencies.waiver_api.url+"/api/wavercontracts/upload/"+id, input);
  }
}
