import React, { FC, useContext, useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { EditProductItemForm } from "./EditProductItemForm";
import { ProductItem } from "../model";
import { ProductsContext } from "../ProductsReactWrapper";
import { initProductService } from "../productsService";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

export const EditProductForm: FC<Props> = () => {
  const { adalService } = useContext(ProductsContext);
  const [productService] = useState(initProductService(adalService));

  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [multiselect, setMultiselect] = useState<boolean>(false);

  const [items, setItems] = useState<ProductItem[]>([]);

  const { id } = useParams();

  const isAdd = !id;

  useEffect(() => {
    if(id) {
      productService.getProduct(id)
        .then(product => {
          setName(product.name);
          setMultiselect(product.multiselect);
          setItems(product.items);
        })
        .catch(console.error);
    }
  }, [id]);


  const [alert, setAlert] = useState<{ open: boolean, success: boolean }>({open: false, success: false});
  const setAlertOpen = (open: boolean) => {
    setAlert({
      ...alert,
      open
    });
  }

  const handleItemChange =
    (i: number) => (item: ProductItem) => {
      items[i] = item;
      setItems([...items]);
    };

  const addNewItem = () => {
    setItems((items) => [
      ...items,
      {
        name: "",
        description: "",
        price: 0,
        defaultCheckedItem: false,
        prOrder: false,
        imageUrl: "",
        enabled: true
      },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = isAdd ? productService.addProduct({
      name,
      multiselect,
      items,
    }) : productService.updateProduct({
      id,
      name,
      multiselect,
      items,
    });

    action.then(() => {
      setAlert({
        open: true,
        success: true,
      });
      navigate('/products');
    })
    .catch(err => {
      setAlert({
        open: true,
        success: false,
      });
      console.error(err);
    });
  };

  return (
    <>
    <form onSubmit={handleSubmit} style={{padding: '1em'}}>
      <Typography variant="h5">{isAdd ? 'Add New' : 'Edit'} Product</Typography>
      <Grid container>
        <Grid item sm={12} md={4}>
          <TextField
            label="Name"
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={multiselect}
                value={name}
                onChange={() => setMultiselect(!multiselect)}
              />
            }
            label="Multiselect"
          />
        </Grid>
        {items.map((item, i) => (
          <Grid item key={i} sm={12} sx={{ marginTop: "2em" }}>
            <EditProductItemForm
              item={item}
              onChange={handleItemChange(i)}
              onDelete={() => setItems((prev) => [...prev.filter((_, j) => j !== i)])}
            />
          </Grid>
        ))}
        <Grid item sm={12} sx={{ marginTop: "2em", paddingRight: '2em' }}>
          <Button
            variant="contained"
            onClick={addNewItem}
            sx={{ float: "right" }}
          >
            Add Item
          </Button>
        </Grid>
        <Grid item sm={12} sx={{ marginTop: "2em" }}>
          <Button
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
    <Snackbar open={alert.open} autoHideDuration={3000} onClose={() => setAlertOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={() => setAlertOpen(false)} severity={alert.success ? "success" : "error"} sx={{ width: '100%' }}>
        {alert.success ? 'Product saved.' : 'Something went wrong.'}
      </Alert>
    </Snackbar>
    </>
  );
};
