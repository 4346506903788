<mat-card class="booking-card">
  <mat-card-header>
  <mat-card-title>Booking bestillinger i {{data.date.format('MMMM Y')}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="card-content">
      <div fxLayout="row" fxLayout.lt-md="column">
        <div class="subject" fxLayout="row">
          <div class="avatar">
            <mat-icon>cake</mat-icon>
          </div>
          
          <div class="overview" fxLayout="column">
          <div class="count">{{data.total}}</div>
            <div class="description">bursdag booking</div>
          </div>
        </div>
        <div class="subject" fxLayout="row">
          <div class="avatar">
            <mat-icon>group_outline</mat-icon>
          </div>
          <div class="overview" fxLayout="column">
            <div class="count">{{data.regularTotal}}</div>
            <div class="description">vanlig booking</div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>


  