import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from './user.model';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user: User = new User();
  return: string = '';
  constructor(private service:LoginService,private router: Router,
    private route: ActivatedRoute) {

   }

  ngOnInit() {
    
  }
  login(empForm: NgForm){
    this.service.login(this.user);
  }
}
